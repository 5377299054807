import React from 'react';

import { MapMarkerIcon } from 'src/../__generated__/globalTypes';

import * as C from 'src/services/client';

const airplaneIcon = require('!svg-inline-loader?classPrefix!../resources/icons/airplanemode_active-24px.svg');
const atvIcon = require('!svg-inline-loader?classPrefix!../resources/icons/atv.svg');
const boatIcon = require('!svg-inline-loader?classPrefix!../resources/icons/directions_boat-24px.svg');
const boxTruckIcon = require('!svg-inline-loader?classPrefix!../resources/icons/local_shipping-24px.svg');
const bulldozerIcon = require('!svg-inline-loader?classPrefix!../resources/icons/bulldozer.svg');
const busIcon = require('!svg-inline-loader?classPrefix!../resources/icons/directions_bus-24px.svg');
const cameraIcon = require('!svg-inline-loader?classPrefix!../resources/icons/camera.svg');
const caravanIcon = require('!svg-inline-loader?classPrefix!../resources/icons/caravan.svg');
const carIcon = require('!svg-inline-loader?classPrefix!../resources/icons/directions_car-24px.svg');
const dumpTruckIcon = require('!svg-inline-loader?classPrefix!../resources/icons/dumptruck.svg');
const electricCarIcon = require('!svg-inline-loader?classPrefix!../resources/icons/electric_car-24px.svg');
const enforementIcon = require('!svg-inline-loader?classPrefix!../resources/icons/local_police-24px.svg');
const errorIcon = require('!svg-inline-loader?classPrefix!../resources/icons/error.svg');
const excavatorIcon = require('!svg-inline-loader?classPrefix!../resources/icons/excavator.svg');
const forkliftIcon = require('!svg-inline-loader?classPrefix!../resources/icons/forklift.svg');
const generatorIcon = require('!svg-inline-loader?classPrefix!../resources/icons/generator.svg');
const gpsTrackerIcon = require('!svg-inline-loader?classPrefix!../resources/icons/my_location-24px.svg');
const graderIcon = require('!svg-inline-loader?classPrefix!../resources/icons/grader.svg');
const harvesterIcon = require('!svg-inline-loader?classPrefix!../resources/icons/harvester.svg');
const helicopterIcon = require('!svg-inline-loader?classPrefix!../resources/icons/helicopter.svg');
const houseIcon = require('!svg-inline-loader?classPrefix!../resources/icons/house.svg');
const largeTrailerIcon = require('!svg-inline-loader?classPrefix!../resources/icons/largetrailer.svg');
const loaderIcon = require('!svg-inline-loader?classPrefix!../resources/icons/loader.svg');
const logTruckIcon = require('!svg-inline-loader?classPrefix!../resources/icons/logtruck.svg');
const mobileRadioIcon = require('!svg-inline-loader?classPrefix!../resources/icons/mobile-radio-24px.svg');
const mopedIcon = require('!svg-inline-loader?classPrefix!../resources/icons/moped-24px.svg');
const motorcycleIcon = require('!svg-inline-loader?classPrefix!../resources/icons/two_wheeler-24px.svg');
const personIcon = require('!svg-inline-loader?classPrefix!../resources/icons/person-24px.svg');
const personWalkingIcon = require('!svg-inline-loader?classPrefix!../resources/icons/directions_walk-24px.svg');
const phoneIcon = require('!svg-inline-loader?classPrefix!../resources/icons/phone_android-24px.svg');
const pickupTruckIcon = require('!svg-inline-loader?classPrefix!../resources/icons/pickup_truck.svg');
const portableRadioIcon = require('!svg-inline-loader?classPrefix!../resources/icons/portable-radio-24px.svg');
const pumpIcon = require('!svg-inline-loader?classPrefix!../resources/icons/pump.svg');
const pushBikeIcon = require('!svg-inline-loader?classPrefix!../resources/icons/pedal_bike-24px.svg');
const questionMarkIcon = require('!svg-inline-loader?classPrefix!../resources/icons/question_mark.svg');
const shuttleIcon = require('!svg-inline-loader?classPrefix!../resources/icons/airport_shuttle-24px.svg');
const skidsteerIcon = require('!svg-inline-loader?classPrefix!../resources/icons/skidsteer.svg');
const taxiIcon = require('!svg-inline-loader?classPrefix!../resources/icons/local_taxi-24px.svg');
const teleliftIcon = require('!svg-inline-loader?classPrefix!../resources/icons/telelift.svg');
const tractorIcon = require('!svg-inline-loader?classPrefix!../resources/icons/agriculture-24px.svg');
const trailerIcon = require('!svg-inline-loader?classPrefix!../resources/icons/trailer.svg');
const trainIcon = require('!svg-inline-loader?classPrefix!../resources/icons/train-24px.svg');
const truckIcon = require('!svg-inline-loader?classPrefix!../resources/icons/truck.svg');
const warningIcon = require('!svg-inline-loader?classPrefix!../resources/icons/warning.svg');

export interface IMapMarkerIconOption {
	label: string;
	MapMarkerIcon: C.MapMarkerIcon;
	mapMarkerIcon: MapMarkerIcon | null;
	svg: any | null;
	asset?: boolean;
	customMarker?: boolean;
}

export const mapMarkerIconOptions: IMapMarkerIconOption[] = [
	{
		MapMarkerIcon: C.MapMarkerIcon.None,
		mapMarkerIcon: null,
		label: 'None',
		svg: null,
		asset: true,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Airplane,
		mapMarkerIcon: MapMarkerIcon.AIRPLANE,
		label: 'Airplane',
		svg: airplaneIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Atv,
		mapMarkerIcon: MapMarkerIcon.ATV,
		label: 'ATV',
		svg: atvIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Boat,
		mapMarkerIcon: MapMarkerIcon.BOAT,
		label: 'Boat',
		svg: boatIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.BoxTruck,
		mapMarkerIcon: MapMarkerIcon.BOX_TRUCK,
		label: 'Box Truck',
		svg: boxTruckIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Bulldozer,
		mapMarkerIcon: MapMarkerIcon.BULLDOZER,
		label: 'Bulldozer',
		svg: bulldozerIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Bus,
		mapMarkerIcon: MapMarkerIcon.BUS,
		label: 'Bus',
		svg: busIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Camera,
		mapMarkerIcon: MapMarkerIcon.CAMERA,
		label: 'Camera',
		svg: cameraIcon,
		asset: false,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Car,
		mapMarkerIcon: MapMarkerIcon.CAR,
		label: 'Car',
		svg: carIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Caravan,
		mapMarkerIcon: MapMarkerIcon.CARAVAN,
		label: 'Caravan',
		svg: caravanIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.DumpTruck,
		mapMarkerIcon: MapMarkerIcon.DUMP_TRUCK,
		label: 'Dump Truck',
		svg: dumpTruckIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.ElectricCar,
		mapMarkerIcon: MapMarkerIcon.ELECTRIC_CAR,
		label: 'Electric Car',
		svg: electricCarIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Enforcement,
		mapMarkerIcon: MapMarkerIcon.ENFORCEMENT,
		label: 'Enforcement',
		svg: enforementIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Error,
		mapMarkerIcon: MapMarkerIcon.ERROR,
		label: 'Error',
		svg: errorIcon,
		asset: false,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Excavator,
		mapMarkerIcon: MapMarkerIcon.EXCAVATOR,
		label: 'Excavator',
		svg: excavatorIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Forklift,
		mapMarkerIcon: MapMarkerIcon.FORKLIFT,
		label: 'Forklift',
		svg: forkliftIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Generator,
		mapMarkerIcon: MapMarkerIcon.GENERATOR,
		label: 'Generator',
		svg: generatorIcon,
		asset: true,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.GpsTracker,
		mapMarkerIcon: MapMarkerIcon.GPS_TRACKER,
		label: 'GPS Tracker',
		svg: gpsTrackerIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Grader,
		mapMarkerIcon: MapMarkerIcon.GRADER,
		label: 'Grader',
		svg: graderIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Harvester,
		mapMarkerIcon: MapMarkerIcon.HARVESTER,
		label: 'Harvester',
		svg: harvesterIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Helicopter,
		mapMarkerIcon: MapMarkerIcon.HELICOPTER,
		label: 'Helicopter',
		svg: helicopterIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.House,
		mapMarkerIcon: MapMarkerIcon.HOUSE,
		label: 'House',
		svg: houseIcon,
		asset: false,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.LargeTrailer,
		mapMarkerIcon: MapMarkerIcon.LARGE_TRAILER,
		label: 'Large Trailer',
		svg: largeTrailerIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Loader,
		mapMarkerIcon: MapMarkerIcon.LOADER,
		label: 'Loader',
		svg: loaderIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.LogTruck,
		mapMarkerIcon: MapMarkerIcon.LOG_TRUCK,
		label: 'Log Truck',
		svg: logTruckIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.MobileRadio,
		mapMarkerIcon: MapMarkerIcon.MOBILE_RADIO,
		label: 'Mobile Radio',
		svg: mobileRadioIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Moped,
		mapMarkerIcon: MapMarkerIcon.MOPED,
		label: 'Moped',
		svg: mopedIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Motorcycle,
		mapMarkerIcon: MapMarkerIcon.MOTORCYCLE,
		label: 'Motorcycle',
		svg: motorcycleIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Person,
		mapMarkerIcon: MapMarkerIcon.PERSON,
		label: 'Person',
		svg: personIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.PersonWalking,
		mapMarkerIcon: MapMarkerIcon.PERSON_WALKING,
		label: 'Person Walking',
		svg: personWalkingIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Phone,
		mapMarkerIcon: MapMarkerIcon.PHONE,
		label: 'Phone',
		svg: phoneIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.PickupTruck,
		mapMarkerIcon: MapMarkerIcon.PICKUP_TRUCK,
		label: 'Pickup Truck/Ute',
		svg: pickupTruckIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.PortableRadio,
		mapMarkerIcon: MapMarkerIcon.PORTABLE_RADIO,
		label: 'Portable Radio',
		svg: portableRadioIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Pump,
		mapMarkerIcon: MapMarkerIcon.PUMP,
		label: 'Pump',
		svg: pumpIcon,
		asset: true,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.PushBike,
		mapMarkerIcon: MapMarkerIcon.PUSH_BIKE,
		label: 'Push Bike',
		svg: pushBikeIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.QuestionMark,
		mapMarkerIcon: MapMarkerIcon.QUESTION_MARK,
		label: 'Question Mark',
		svg: questionMarkIcon,
		asset: false,
		customMarker: true,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Shuttle,
		mapMarkerIcon: MapMarkerIcon.SHUTTLE,
		label: 'Shuttle',
		svg: shuttleIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.SkidSteer,
		mapMarkerIcon: MapMarkerIcon.SKID_STEER,
		label: 'Skid Steer',
		svg: skidsteerIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Taxi,
		mapMarkerIcon: MapMarkerIcon.TAXI,
		label: 'Taxi',
		svg: taxiIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Telelift,
		mapMarkerIcon: MapMarkerIcon.TELELIFT,
		label: 'Telelift',
		svg: teleliftIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Tractor,
		mapMarkerIcon: MapMarkerIcon.TRACTOR,
		label: 'Tractor',
		svg: tractorIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Trailer,
		mapMarkerIcon: MapMarkerIcon.TRAILER,
		label: 'Trailer',
		svg: trailerIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Train,
		mapMarkerIcon: MapMarkerIcon.TRAIN,
		label: 'Train',
		svg: trainIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Truck,
		mapMarkerIcon: MapMarkerIcon.TRUCK,
		label: 'Truck',
		svg: truckIcon,
		asset: true,
		customMarker: false,
	}, {
		MapMarkerIcon: C.MapMarkerIcon.Warning,
		mapMarkerIcon: MapMarkerIcon.WARNING,
		label: 'Warning',
		svg: warningIcon,
		asset: false,
		customMarker: true,
	},
];

export function renderMapMarkerIcon(parentElement: HTMLDivElement, iconShape: C.MapMarkerIcon | undefined, iconSize?: number | null, iconColor?: string | null) {
	const svg = mapMarkerIconOptions.find(x => x.MapMarkerIcon === iconShape)?.svg;
	if (!svg)
		return;

	parentElement.innerHTML = svg;

	const svgIcon = parentElement.getElementsByTagName('svg');
	if (svgIcon.length > 0 && iconSize && iconColor) {
		svgIcon[0].style.width = `${iconSize}px`;
		svgIcon[0].style.height = `${iconSize}px`;
		svgIcon[0].style.fill = iconColor;
		svgIcon[0].style.zIndex = '5';
		svgIcon[0].style.position = 'absolute';
	}
}

function renderMapMarkerIconOptionSvg(parentElement: HTMLDivElement | null, svg: any) {
	if (!parentElement)
		return;

	const oldIcons = parentElement.getElementsByClassName('asset-type-selector-icon');
	if (oldIcons.length > 0) {
		for (let i = 0; i < oldIcons.length; i++) {
			parentElement.removeChild(oldIcons[i]);
		}
	}

	const iconHtmlDiv = document.createElement('div');
	iconHtmlDiv.innerHTML = svg;

	iconHtmlDiv.className = 'asset-type-selector-icon';
	iconHtmlDiv.style.width = '20px';
	iconHtmlDiv.style.height = '20px';
	iconHtmlDiv.style.marginRight = '10px';

	parentElement.appendChild(iconHtmlDiv);
}

export function renderMapMarkerIconOption(option: IMapMarkerIconOption) : React.ReactNode {
	return <>
		<div ref={(divElement: HTMLDivElement) => renderMapMarkerIconOptionSvg(divElement, option.svg)} />
		<span>{option.label}</span>
	</>;
}
