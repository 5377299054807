import React, { useState } from 'react';
import classNames from 'classnames';
import HomeIcon from '@material-ui/icons/Home';

import { MapViewMode } from './mapViewMode';
import { MapboxMapStyle } from './mapboxManager';

import './mapActionButtons.scss';

interface IMapActionButtonsProps {
	mapViewMode: MapViewMode;
	mapStyle: MapboxMapStyle;
	toggleMapStyle: (selectedStyle?: MapboxMapStyle) => void;
	hasSidebar?: boolean;
	moveMapToHome?: () => void;
}

function getPrettyMapStyle(style: MapboxMapStyle)
{
	switch (style)
	{
		case MapboxMapStyle.Streets:
			return "Map";

		case MapboxMapStyle.Satellite:
			return "Satellite";

		case MapboxMapStyle.Topographic:
			return "Topo";
	}
}

export const MapActionButtons = (props: IMapActionButtonsProps) => {
	if (props.mapViewMode !== MapViewMode.World)
		return null;

	const [mapMenuOpen, setMapMenuOpen] = useState(false);

	return  <div className={classNames('map-actions', { 'has-sidebar': props.hasSidebar })}>
		<div className={classNames('current-map-layer__menu__options', { 'current-map-layer__menu__options--open': mapMenuOpen })}>
			<button
				className={classNames('current-map-layer__menu__button', `current-map-layer__menu__button--${getPrettyMapStyle(props.mapStyle).toLowerCase()}`)}
				type="button"
				onClick={() => setMapMenuOpen(!mapMenuOpen)}
			>
				<span className="current-map-layer__menu__button__label">{`${getPrettyMapStyle(props.mapStyle)}`}</span>
			</button>

			{props.mapStyle !== MapboxMapStyle.Streets && <button
				className="current-map-layer__menu__button current-map-layer__menu__button--map"
				type="button"
				title="Map"
				onClick={() => {
					setMapMenuOpen(!mapMenuOpen)
					props.toggleMapStyle(MapboxMapStyle.Streets)
				}}
			>
				<span className="current-map-layer__menu__button__label">Map</span>
			</button>}

			{props.mapStyle !== MapboxMapStyle.Satellite && <button
				className="current-map-layer__menu__button current-map-layer__menu__button--satellite"
				type="button"
				title="Satellite"
				onClick={() => {
					setMapMenuOpen(!mapMenuOpen)
					props.toggleMapStyle(MapboxMapStyle.Satellite)
				}}
			>
				<span className="current-map-layer__menu__button__label">Satellite</span>
			</button>}

			{props.mapStyle !== MapboxMapStyle.Topographic && <button
				className="current-map-layer__menu__button current-map-layer__menu__button--topo"
				type="button"
				title="Topographic"
				onClick={() => {
					setMapMenuOpen(!mapMenuOpen)
					props.toggleMapStyle(MapboxMapStyle.Topographic)
				}}
			>
				<span className="current-map-layer__menu__button__label">Topo</span>
			</button>}
		</div>

		{props.moveMapToHome && <button
			className='home-button'
			type="button"
			onClick={props.moveMapToHome}
		>
			<HomeIcon />
		</button>}
	</div>
};
