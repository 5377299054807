import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, Form, Field, FormikErrors } from 'formik';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikTextField, FormikSelect } from 'src/components';
import { runFormValidation } from 'src/util';

import {
	Client as C,
	AssetGroupService,
	AuthenticationService,
	HistoryService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

interface ManageAssetGroupFormValues {
	name: string;
	clientId: string | null;
}

const validateForm = (values: ManageAssetGroupFormValues, errors: FormikErrors<ManageAssetGroupFormValues>) => {
	if (!values.name)
		errors.name = 'Asset group name is required.';

	if (!values.clientId)
		errors.clientId = 'Client is required';
};

interface Props {
	assetGroup?: C.IAssetGroupDto | null;
	clients?: C.IClientDto[];
}

export const ManageAssetGroupComponent = observer((props: Props) => {
	const _assetGroupService = useInjection<AssetGroupService>(Service.AssetGroup);
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const defaultClientId = _authService.currentAuth.user.identity.clientId || null;

	const initialFormValues: ManageAssetGroupFormValues = {
		name: props.assetGroup ? props.assetGroup.name : '',
		clientId: props.assetGroup ? props.assetGroup.client.clientId : defaultClientId,
	};

	const addAssetGroup = async (values: ManageAssetGroupFormValues): Promise<boolean> => {
		const request: C.IAddAssetGroupRequest = {
			name: values.name,
			clientId: values.clientId!!,
		};

		try {
			const assetGroup = await _assetGroupService.addAssetGroup(request);
			_historyService.history.push(`/app/asset-groups/${assetGroup.assetGroupId}/manage`);
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err);
			return false;
		}
	};

	const updateAssetGroup = async (values: ManageAssetGroupFormValues): Promise<boolean> => {
		const request: C.IUpdateAssetGroupRequest = {
			name: values.name,
		};

		try {
			await _assetGroupService.updateAssetGroup(props.assetGroup!.assetGroupId, request);
			_historyService.history.push('/app/asset-groups/list');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err);
			return false;
		}
	};

	const onSubmit = async (values: ManageAssetGroupFormValues, { setSubmitting }: FormikHelpers<ManageAssetGroupFormValues>) => {
		let success;
		if (props.assetGroup)
			success = await updateAssetGroup(values);
		else
			success = await addAssetGroup(values);

		if (!success)
			setSubmitting(false);
	};

	const addingNew = !props.assetGroup;

	return <FixedWidthPage
		className="form-page"
		headingText={addingNew ? 'Add Asset Group' : 'Edit Asset Group'}
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ManageAssetGroupFormValues>) => <Form className="formik-form">
				<Field
					name="name"
					label="Asset Group Name"
					component={FormikTextField}
				/>

				{_authService.currentAuth.user.identity.type !== C.IdentityType.Client && addingNew && <FormikSelect
					name="clientId"
					label="Client"
					placeholder="Select a client..."
					options={props.clients!!}
					form={formikProps}
					getOptionLabel={option => option.name}
					getOptionValue={option => option.clientId}
				/>}

				<Button
					type="submit" variant="contained" color="primary"
					loading={formikProps.isSubmitting}
					startIcon={addingNew ? null : <SaveIcon />}
					text={addingNew ? 'Add Asset Group' : 'Save Changes'}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
