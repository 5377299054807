import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import useAsyncEffect from 'use-async-effect';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import SaveIcon from '@material-ui/icons/Save';

import { FixedWidthPage, MessagePage, Button, FormikTextField, FormikSelect, FormikCheckbox } from 'src/components';
import { getDeviceConfigurationTypeFormatted, getDeviceConfigurationTypeFileType } from './deviceConfigurationHelpers';
import { ReadFileToBase64String } from 'src/util/fileToBytesStringHelper';
import { runFormValidation } from 'src/util';

import { executeQueryDealerList, QueryDealerList_dealers } from 'src/graphql/__generated__/queries/queryDealerList';

import {
	Client as C,
	DeviceConfigurationService,
	AuthenticationService,
	HistoryService,
	ToasterService,
	useInjection,
	Service,
} from 'src/services';

export interface AddDeviceConfigurationValues {
	name: string;
	configurationType: C.DeviceConfigurationType;
	file: string | null;
	canBeUsedByAllDealers: boolean;
	dealerId: string;
}

const fileExtensionIsValidForConfigurationType = (fileName: string | null, configurationType: C.DeviceConfigurationType) => {
	const extension = getDeviceConfigurationTypeFileType(configurationType);
	if (!fileName || !extension)
		return false;

	return fileName.endsWith(extension);
};

const validateForm = (values: AddDeviceConfigurationValues, errors: FormikErrors<AddDeviceConfigurationValues>) => {
	if (!values.name)
		errors.name = 'Name is required.';

	if (!values.file)
		errors.file = 'File is required';
	else if (!fileExtensionIsValidForConfigurationType(values.file, values.configurationType))
		errors.file = 'The selected file type is not valid for this configuration type.';
};

const initialValues: AddDeviceConfigurationValues = {
	name: '',
	configurationType: C.DeviceConfigurationType.ATrack_AK1,
	file: null,
	canBeUsedByAllDealers: false,
	dealerId: '',
};

const configurationDeviceTypes = [
	{
		value: C.DeviceConfigurationType.ATrack_AK1,
		label: getDeviceConfigurationTypeFormatted(C.DeviceConfigurationType.ATrack_AK1),
	},
	{
		value: C.DeviceConfigurationType.ATrack_AK7,
		label: getDeviceConfigurationTypeFormatted(C.DeviceConfigurationType.ATrack_AK7),
	},
	{
		value: C.DeviceConfigurationType.ATrack_AK7V,
		label: getDeviceConfigurationTypeFormatted(C.DeviceConfigurationType.ATrack_AK7V),
	},
	{
		value: C.DeviceConfigurationType.ATrack_AL7,
		label: getDeviceConfigurationTypeFormatted(C.DeviceConfigurationType.ATrack_AL7),
	},
];

export const AddDeviceConfiguration = observer(() => {
	const apolloClient = useInjection<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	const deviceConfigurationService = useInjection<DeviceConfigurationService>(Service.DeviceConfiguration);
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const historyService = useInjection<HistoryService>(Service.History);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const [currentFile, setFile] = React.useState<File | null>(null);
	const [allDealers, setAllDealers] = React.useState<QueryDealerList_dealers[] | null>(null);
	const [loading, setLoading] = React.useState(true);
	const [fileType, setFileType] = React.useState<string | null>('.txt'); // Default to .txt as initial values config type defaults to Atrack_AL7.

	useAsyncEffect(async () => {
		if (allDealers === null) {
			setLoading(true);
			const dealersListQuery = await executeQueryDealerList(apolloClient);

			if (dealersListQuery.error || !dealersListQuery.data)
				throw dealersListQuery.error || 'Failed to load dealers.';

			setAllDealers(dealersListQuery.data.dealers);
			setLoading(false);
		}
	}, []);

	function handleFileSelected(evt: React.ChangeEvent<HTMLInputElement>, formikProps: FormikProps<AddDeviceConfigurationValues>) {
		const files = evt.target.files;

		if (files) {
			formikProps.setFieldValue('file', evt.target.value);
			formikProps.setFieldTouched('file');

			const file = files[0];
			if (file)
				setFile(file);
		}
	}

	// Changes the allowed file type, when the configuration type changes.
	function handleConfigurationTypeChange(type: {value: C.DeviceConfigurationType, label: string} | {value: C.DeviceConfigurationType, label: string}[] | null, formikProps: FormikProps<AddDeviceConfigurationValues>) {
		let newConfigurationType: C.DeviceConfigurationType | null = null;
		if (type) {
			if (Array.isArray(type))
				newConfigurationType = type[0].value;
			else
				newConfigurationType = type.value;
		}

		if (!newConfigurationType) {
			setFileType(null);
			return;
		}

		if (newConfigurationType !== formikProps.values.configurationType) {
			setFileType(getDeviceConfigurationTypeFileType(newConfigurationType as C.DeviceConfigurationType));
		}
	}

	async function onSubmit(values: AddDeviceConfigurationValues, { setSubmitting }: FormikHelpers<AddDeviceConfigurationValues>) {
		if (!currentFile)
			toasterService.showDanger('Please select a file.');

		setSubmitting(true);

		try {
			let failed = false;
			const failedMessage = 'Failed to read file, please try again.';
			const base64FileString = await ReadFileToBase64String(currentFile as File, failedMessage, () => {
				toasterService.showDanger(failedMessage);
				failed = true;
			});

			if (failed)
				return;

			const addDeviceConfigRequest: C.IAddDeviceConfigurationRequest = {
				name: values.name,
				type: values.configurationType,
				deviceConfigurationFileBytes: base64FileString,
				allDealersCanAccess: values.canBeUsedByAllDealers,
				dealerId: values.canBeUsedByAllDealers ? null : values.dealerId,
			};

			await deviceConfigurationService.addDeviceConfiguration(new C.AddDeviceConfigurationRequest(addDeviceConfigRequest));
			toasterService.showSuccess('Device configuration created.');
			historyService.history.push('/app/device-configurations/list');
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to create device configuration.');
		}

		setSubmitting(false);
	}

	function renderDealerSelector(formikProps: FormikProps<AddDeviceConfigurationValues>): JSX.Element | null {
		if (!allDealers || allDealers.length == 0 || authenticationService.currentAuth.user.identity.type !== C.IdentityType.SuperUser)
			return null;

		return <FormikSelect
			name="dealerId"
			label="Dealer"
			placeholder="Select a dealer..."
			options={allDealers}
			clearable={false}
			isLoading={loading}
			form={formikProps}
			getOptionLabel={option => option.name}
			getOptionValue={option => option.id}
		/>;
	}

	if (allDealers && allDealers.length > 0)
		initialValues.dealerId = allDealers[0].id;

	if (loading)
		return <MessagePage loading />;

	return <FixedWidthPage
		className="form-page"
		headingText="Add Device Configuration"
	>
		<Formik
			initialValues={initialValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<AddDeviceConfigurationValues>) => <Form className="formik-form">
				<Field
					name="name"
					label="Name"
					type="text"
					component={FormikTextField}
					required
				/>

				<FormikSelect
					label="Configuration Type"
					name="configurationType"
					onChange={e => handleConfigurationTypeChange(e, formikProps)}
					options={configurationDeviceTypes}
					clearable={false}
					form={formikProps}
					getOptionLabel={option => option.label}
					getOptionValue={option => option.value}
					required
				/>

				<Field
					name="file"
					label="Configuration File"
					type="file"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFileSelected(e, formikProps)}
					onBlur={() => formikProps.setFieldTouched('file', true)}
					inputProps={fileType ? { accept: fileType } : undefined}
					variant="filled"
					InputLabelProps={{
						shrink: true,
					}}
					component={FormikTextField}
					required
				/>

				<Field
					name="canBeUsedByAllDealers"
					label="Can be used by all dealers"
					component={FormikCheckbox}
				/>

				{allDealers && !formikProps.values.canBeUsedByAllDealers && renderDealerSelector(formikProps)}

				<Button
					type="submit"
					variant="contained"
					color="primary"
					startIcon={<SaveIcon />}
					text={'Save Configuration'}
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
