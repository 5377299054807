import { SvgIcon } from "@material-ui/core";
import React from "react";

export function CustomTrailerIcon(props: any) {
	return (
		<SvgIcon {...props} version="1.0" xmlns="http://www.w3.org/2000/svg"
			width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
			preserveAspectRatio="xMidYMid meet">

			<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
				<path
					d="M115 3423 c-35 -9 -87 -54 -101 -90 -12 -28 -14 -108 -12 -461 3
					-413 4 -429 24 -473 28 -60 87 -119 150 -150 46 -22 62 -24 235 -27 l186 -4
					12 88 c23 166 89 292 211 407 132 125 273 181 455 181 127 0 203 -17 311 -70
					l71 -35 84 40 c246 117 518 82 734 -94 127 -105 234 -322 235 -478 l0 -38 338
					3 337 3 57 28 c95 47 168 151 168 239 l0 28 415 0 415 0 0 -122 c0 -100 4
					-131 20 -165 48 -106 198 -112 260 -11 17 29 20 51 20 165 l0 131 139 4 c122
					3 143 6 167 24 91 68 96 185 11 248 -28 21 -40 21 -735 26 l-707 5 -5 255 c-6
					281 -6 283 -70 323 l-35 22 -1685 1 c-927 1 -1694 0 -1705 -3z" />
				<path
					d="M1175 2739 c-180 -36 -343 -186 -397 -364 -58 -189 -7 -389 137 -530
					102 -101 220 -147 370 -147 130 0 227 35 332 119 l41 33 43 -36 c169 -141 429
					-156 615 -36 287 186 325 585 79 821 -192 185 -483 197 -694 31 -48 -38 -49
					-38 -66 -20 -30 33 -113 82 -183 107 -76 28 -200 38 -277 22z m172 -304 c192
					-57 212 -323 31 -416 -47 -24 -146 -26 -191 -3 -159 80 -178 292 -34 390 64
					43 118 51 194 29z m801 -21 c50 -30 98 -98 107 -155 16 -94 -30 -187 -116
					-233 -44 -25 -59 -28 -118 -24 -53 3 -77 9 -110 31 -60 40 -94 97 -99 167 -5
					70 10 120 51 166 74 85 189 104 285 48z" />
			</g>
		</SvgIcon>
	);
}
