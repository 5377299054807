import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneIcon from '@material-ui/icons/Done';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PersonIcon from '@material-ui/icons/Person';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import VideocamIcon from '@material-ui/icons/Videocam';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { Button, IsolatedRefreshingDisplay } from 'src/components';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';
import { getLocationParts } from 'src/util/locationFormats';
import { calculateFuelLevel } from 'src/util/fuelLevel';
import { PollState } from './liveMapAssetState';
import { InfoBoxSafetyTimer } from './infoBoxSafetyTimer';
import { InformationBox } from './informationBox';
import { IMapAsset } from './mapAsset';

import {
	AuthenticationService,
	CefSharpService,
	Client as C,
	DistanceService,
	DistanceUnit,
	FloorPlansService,
	Service,
	SpeedUnit,
	useInjection,
	useCurrentUser,
} from 'src/services';

import './informationBox.scss';

export enum InfoBoxDisplayMode {
	Full = 0,
	LocationOnly = 1,
}

export interface Props {
	asset: IMapAsset;
	displayMode: InfoBoxDisplayMode;
	timestamp: moment.Moment | undefined;
	assetLocation?: C.IAssetLocationDto;
	eventProperties?: C.IAssetEventPropertiesDto;
	emergency?: boolean;
	emergencyType?: C.EmergencyType;
	emergencyDeviceIoConfigurationName?: string;
	eventType?: C.AssetEventType;
	floorPlanVisible: boolean;
	pollState?: PollState;
	safetyTimer?: C.ISafetyTimerDto;
	isAgedOff?: boolean;
	userName?: string;
	attachmentName?: string;
	fuelLevelPoC?: number;
	subtitleBannerText?: string;
	clientName?: string;
	onChangeFloorPlanVisible?: (visible: boolean) => void;
	onCloseButtonClick?: (() => void) | null;
	goToFloorPlan?: (floorPlanId: string) => void;
	pollAssetLocation?: (assetId: string) => void;
	safetyTimerUpdate?: (safetyTimer: C.ISafetyTimerDto) => void;
}

export const SelectedAssetInfoBox = observer((props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _cefSharpService = useInjection<CefSharpService>(Service.CefSharp);
	const _distanceService = useInjection<DistanceService>(Service.DistanceService);
	const _floorPlanService = useInjection<FloorPlansService>(Service.FloorPlans);

	const [safetyTimerDialogOpen, setSafetyTimerDialogOpen] = useState<boolean>(false);

	const currentUser = useCurrentUser();

	const renderCurrentFloorPlan = (floorPlanId: string): JSX.Element | null => {
		const floorPlan = _floorPlanService.floorPlans.get(floorPlanId);

		if (!floorPlan)
			return null;

		return <div className="floor-plan">
			Floor Plan: {floorPlan.name}

			{props.onChangeFloorPlanVisible && <IconButton
				onClick={() => props.onChangeFloorPlanVisible && props.onChangeFloorPlanVisible(!props.floorPlanVisible)}
				className="icon-button-small button-toggle-floor-plan"
			>
				{props.floorPlanVisible && <VisibilityIcon />}
				{!props.floorPlanVisible && <VisibilityOffIcon />}
			</IconButton>}

			{props.goToFloorPlan && <IconButton
				onClick={() => props.goToFloorPlan!(floorPlanId)}
				className="icon-button-small button-toggle-floor-plan"
			>
				<ArrowForwardIcon />
			</IconButton>}
		</div>;
	};

	const onClickAssetPollLocation = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (props.pollAssetLocation)
			props.pollAssetLocation(props.asset.assetId);
	};

	const renderLocation = (assetLocation: C.IAssetLocationDto): JSX.Element => {
		const locationDetails = assetLocation.locationDetails && getLocationParts(assetLocation.locationDetails);

		const roundedLat = Math.round(assetLocation.location.latitude * 1000000) / 1000000;
		const roundedLng = Math.round(assetLocation.location.longitude * 1000000) / 1000000;

		const formattedLatLng = `${roundedLat}, ${roundedLng}`;
		const formattedAccuracy = assetLocation.accuracy == null ? null : _distanceService.formatDistance(assetLocation.accuracy, DistanceUnit.Metres);
		const formattedSpeed = assetLocation.speed == null ? null : _distanceService.formatSpeed(assetLocation.speed, _authService.currentAuth.user.usesMetric ? SpeedUnit.KilometresPerHour : SpeedUnit.MilesPerHour);

		return <div className="location-details">
			<div className="location">
				<div className="title">Location:&nbsp;</div>
				<div className="location-content">
					{locationDetails && <div className="address-parts">{locationDetails.map((x, i) => <span key={i}>{x}</span>)}</div>}
					<span>{formattedLatLng}{formattedAccuracy && ` (± ${formattedAccuracy})`}</span>
				</div>
			</div>

			{formattedSpeed && <span>
				Speed: {formattedSpeed}
			</span>}

			{assetLocation.heading != null && <span>
				Heading: {assetLocation.heading}°
			</span>}

			{assetLocation.floorPlanLocation && renderCurrentFloorPlan(assetLocation.floorPlanLocation.floorPlanId)}
		</div>;
	};

	const renderEmergencyNotes = (emergencyNotes?: string | null): JSX.Element | null => {
		if (!emergencyNotes)
			return null;

		return <div className="emergency-details">
			{emergencyNotes && <div>
				<div>Notes:&nbsp;</div>
				<span style={{ whiteSpace: 'pre-line', wordBreak: 'break-all', wordWrap: 'break-word' }}>
					{emergencyNotes}
				</span>
			</div>}
		</div>;
	};

	const renderFuelLevelPoc = (asset: IMapAsset, fuelLevelValue: number): JSX.Element | null => {
		const calculatedFuelValue = calculateFuelLevel(asset.assetId, fuelLevelValue);
		if (calculatedFuelValue === null)
			return null;

		return <Box>
			Fuel level: {calculatedFuelValue} L
		</Box>;
	};

	const renderProperties = (eventProperties: C.IAssetEventPropertiesDto): JSX.Element | null => {
		const vanProperties = eventProperties.camperVan;

		if (!vanProperties) {
			return null;
		}

		return <span className="camper-properties">
			CamperVan Properties:
				<div className="camper-level">
					Battery Level: {vanProperties.batteryLevel}%
				</div>
			<div className="camper-level">
				Water Tank Level: {vanProperties.waterTankLevel}%
			</div>
			<div className="camper-level">
				Waste Tank Level: {vanProperties.wasteTankLevel}%
			</div>
			<div className="camper-state-header">
				{vanProperties.outputStates?.map((item) =>
					<div className="camper-state">
						{item.outputName}: {item.outputOn ? 'On' : 'Off'}{item.hasFault && ' - Fault Detected'}
					</div>
				)}
			</div>
		</span>;
	};

	const renderCameraControls = (timestamp: moment.Moment) => {
		return <div className="cameras">
			<div className="video-icon" style={{ gridColumn: '1', gridRow: '1' }}>
				<VideocamIcon fontSize="small" />
			</div>

			<div className="camera-left" style={{ gridColumn: '1', gridRow: '2 / span 2' }}>
				<Button variant="outlined" size="small" color="primary" onClick={() => _cefSharpService.playHistory(0, timestamp)}>
					<KeyboardArrowLeftIcon fontSize="small" />
				</Button>
			</div>

			<div className="camera-front" style={{ gridColumn: '2', gridRow: '1' }}>
				<Button variant="outlined" size="small" color="primary" onClick={() => _cefSharpService.playHistory(1, timestamp)}>
					<KeyboardArrowUpIcon fontSize="small" />
				</Button>
			</div>

			<div className="camera-driver" style={{ gridColumn: '2', gridRow: '2' }}>
				<Button variant="outlined" size="small" color="primary" onClick={() => _cefSharpService.playHistory(2, timestamp)}>
					<PersonIcon fontSize="small" />
				</Button>
			</div>

			<div className="camera-inside" style={{ gridColumn: '2', gridRow: '3' }}>
				<Button variant="outlined" size="small" color="primary" onClick={() => _cefSharpService.playHistory(3, timestamp)}>
					Inside
				</Button>
			</div>

			<div className="camera-rear" style={{ gridColumn: '2', gridRow: '4' }}>
				<Button variant="outlined" size="small" color="primary" onClick={() => _cefSharpService.playHistory(4, timestamp)}>
					<KeyboardArrowDownIcon fontSize="small" />
				</Button>
			</div>

			<div className="camera-rear" style={{ gridColumn: '3', gridRow: '2 / span 2' }}>
				<Button variant="outlined" size="small" color="primary" onClick={() => _cefSharpService.playHistory(5, timestamp)}>
					<KeyboardArrowRightIcon fontSize="small" />
				</Button>
			</div>
		</div>;
	};

	const renderHumanTime = (timeStamp: moment.Moment | undefined): JSX.Element => {
		if (!timeStamp) {
			return <div>No recent events</div>;
		}

		const diff = moment().diff(timeStamp);

		if (Math.abs(diff) < 10000)
			return <div className="human-time">Just now</div>;

		// The time is too far in the future, don't display a human time.
		if (diff < 0)
			return <div>No recent events</div>;

		return <div className="human-time">{timeStamp.fromNow()}</div>;
	};

	const getSubtitleContent = (): JSX.Element => {
		const { timestamp } = props;

		return <>
			{timestamp && timestamp.tz(_authService.currentAuth.user.timeZone).format(longDateTimeFormat)}
			{timestamp && <IsolatedRefreshingDisplay
				time={timestamp}
				render={() => renderHumanTime(timestamp)}
			/>}
		</>;
	};

	const getContent = () => {
		const { asset, assetLocation, timestamp, eventType, eventProperties } = props;

		return <>
			{assetLocation && renderLocation(assetLocation)}

			{props.fuelLevelPoC != null && renderFuelLevelPoc(asset, props.fuelLevelPoC)}

			{eventProperties && renderProperties(eventProperties)}

			{props.displayMode == InfoBoxDisplayMode.Full && <>
				{asset.devices?.some(x => x.manualPollingEnabled) &&
					<div
						className="asset-poll-area"
					>
						<span>Poll Asset Location</span>
						<Button
							text={props.pollState == undefined || props.pollState.pollResult == undefined
								? <MyLocationIcon fontSize="small" />
								: props.pollState.pollResult == true
									? <DoneIcon fontSize="small" />
									: <SmsFailedIcon fontSize="small" />}
							variant="outlined"
							color="primary"
							className="poll-button"
							title="Poll Asset"
							onClick={onClickAssetPollLocation}
							disabled={false}
							loading={!!props.pollState && !!props.pollState.pollTimeout}
						/>
					</div>}

				<Button
					className="history-button"
					text="View History"
					variant="outlined"
					href={`/app/map/history?assetId=${asset.assetId}`}
					endIcon={<ArrowForwardIcon fontSize="small" />}
				/>

				<InfoBoxSafetyTimer
					asset={props.asset}
					safetyTimer={props.safetyTimer}
					safetyTimerUpdate={props.safetyTimerUpdate}
				/>

				{props.emergency && renderEmergencyNotes(props.asset.emergencyNotes)}
			</>}

			{props.emergencyDeviceIoConfigurationName && <div>
				<span style={{ whiteSpace: 'pre-line', wordBreak: 'break-all', wordWrap: 'break-word' }}>
					IO configuration: {props.emergencyDeviceIoConfigurationName}
				</span>
			</div>}

			{_cefSharpService.canPlayHistory && eventType === C.AssetEventType.Emergency && renderCameraControls(timestamp || moment())}
		</>;
	};

	const { asset, onCloseButtonClick } = props;

	return <InformationBox
		clientName={currentUser?.identity.client?.clientId !== asset.client?.id	? asset.client?.name : undefined}
		onCloseButtonClick={onCloseButtonClick}
		inEmergency={props.emergency}
		emergencyType={props.emergencyType}
		showTitleEmergencyIcon={true}
		title={asset.name}
		subtitleContent={getSubtitleContent()}
		subtitleBannerText={props.subtitleBannerText ?? (props.isAgedOff && !props.emergency ? 'Marker hidden due to last update threshold setting.' : undefined)}
		contentClassName="single-asset-content"
		userName={props.userName}
		attachmentName={props.attachmentName}
	>
		{getContent()}
	</InformationBox>;
});
