import moment from 'moment-timezone';
import { sortBy } from 'lodash';

interface TimeZone {
	name: string;
	tz: string;
}

const list: Array<TimeZone> = [
	{
		name: 'Adelaide',
		tz: 'Australia/Adelaide',
	},
	{
		name: 'Brisbane',
		tz: 'Australia/Brisbane',
	},
	{
		name: 'Darwin',
		tz: 'Australia/Darwin',
	},
	{
		name: 'Eucla',
		tz: 'Australia/Eucla',
	},
	{
		name: 'Hobart',
		tz: 'Australia/Hobart',
	},
	{
		name: 'Lord Howe Island',
		tz: 'Australia/Lord_Howe',
	},
	{
		name: 'Sydney',
		tz: 'Australia/Sydney',
	},
	{
		name: 'Perth',
		tz: 'Australia/Perth',
	},
	{
		name: 'Coordinated Universal Time',
		tz: 'Etc/UTC'
	},
	{
		name: 'Auckland, Wellington',
		tz: 'Pacific/Auckland',
	},
	{
		name: 'Chatham Islands',
		tz: 'Pacific/Chatham',
	},
	{
		name: 'Fiji',
		tz: 'Pacific/Fiji',
	},
	{
		name: 'Apia',
		tz: 'Pacific/Apia',
	},
	{
		name: 'London',
		tz: 'Europe/London',
	},
	{
		name: 'Dublin',
		tz: 'Europe/Dublin',
	},
];

const selectOptions: Array<{ value:string, label: string }> = [];

interface TimeZoneOffset {
	timeZone: TimeZone;
	offset: number;
	offsetLabel: string;
}

// Calculate the current offsets for each time zone
// to be displayed in select boxes
const now = moment();
let offsets: Array<TimeZoneOffset> = [];
for (const zone of list) {
	const inZone = now.clone().tz(zone.tz);
	const offsetFormatted = zone.tz === 'Etc/UTC' ? '' : inZone.format('Z');

	offsets.push({
		timeZone: zone,
		offset:	inZone.utcOffset(),
		offsetLabel: `(UTC${offsetFormatted})`,
	});
}

// Add all the labeled time zone options
offsets = sortBy(offsets, x => x.offset);
for (const offset of offsets) {
	selectOptions.push({
		label: `${offset.offsetLabel} ${offset.timeZone.name}`,
		value: offset.timeZone.tz,
	});
}

export {
	list,
	selectOptions,
};
