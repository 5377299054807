import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, Form, Field, FormikErrors } from 'formik';
import useAsyncEffect from 'use-async-effect';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikTextField, FormikSelect, LoadingMessagePage } from 'src/components';
import { runFormValidation } from 'src/util';

import {
	Client as C,
	BeaconsService,
	HistoryService,
	PermissionsService,
	Service,
	ToasterService,
	useCurrentUser,
	useInjection,
} from 'src/services';

interface ManageBleBeaconSetFormValues {
	name: string;
	dealerId: string;
}

const validateForm = (values: ManageBleBeaconSetFormValues, errors: FormikErrors<ManageBleBeaconSetFormValues>) => {
	if (!values.name)
		errors.name = 'Beacon set name is required.';

	if (!values.dealerId)
		errors.dealerId = 'Dealer is required';
};

interface Props {
	beaconSet?: C.IBleBeaconSetDto | null;
}

export const ManageBleBeaconSetComponent = observer((props: Props) => {
	const currentUser = useCurrentUser()!;
	const _beaconService = useInjection<BeaconsService>(Service.Beacons);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _permissionsService = useInjection<PermissionsService>(Service.Permissions);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [loading, setLoading] = useState<boolean>();
	const [dealers, setDealers] = useState<C.IDealerDto[] | null>();

	useAsyncEffect(async () => {
		if (currentUser.identity.type !== C.IdentityType.Dealer && currentUser.identity.type !== C.IdentityType.SuperUser)
			_historyService.history.replace('/app');

		const addPermissions = await _permissionsService.fetchUserAddPermissions(currentUser.identityId);

		if (addPermissions.dealers) {
			setDealers(addPermissions.dealers);
		}

		setLoading(false);
	}, []);

	const addBeaconSet = async (values: ManageBleBeaconSetFormValues): Promise<boolean> => {
		const request: C.IAddBleBeaconSetRequest = {
			name: values.name,
			dealerId: currentUser.identity.type === C.IdentityType.Dealer ? currentUser.identity.dealerId! : values.dealerId,
		};

		try {
			await _beaconService.addBleBeaconSet(request);
			_historyService.history.push(`/app/ble-beacon-sets/list`);
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err);
			return false;
		}
	};

	const updateBeaconSet = async (values: ManageBleBeaconSetFormValues): Promise<boolean> => {
		const request: C.IUpdateBleBeaconSetRequest = {
			name: values.name !== props.beaconSet!.name ? values.name : null,
		};

		try {
			await _beaconService.updateBleBeaconSet(props.beaconSet!.bleBeaconSetId, request);
			_historyService.history.push(`/app/ble-beacon-sets/list`);
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err);
			return false;
		}
	};

	const onSubmit = async (values: ManageBleBeaconSetFormValues, { setSubmitting }: FormikHelpers<ManageBleBeaconSetFormValues>) => {
		let success: boolean;
		if (props.beaconSet)
			success = await updateBeaconSet(values);
		else
			success = await addBeaconSet(values);

		if (!success)
			setSubmitting(false);
	};

	if (loading)
		return <LoadingMessagePage />;

	const addingNewBeaconSet = !props.beaconSet;

	const initialFormValues: ManageBleBeaconSetFormValues = {
		name: props.beaconSet?.name ?? '',
		dealerId: props.beaconSet?.dealer?.dealerId ?? currentUser.identity.dealerId ?? '',
	};

	return <FixedWidthPage
		className="form-page"
		headingText={addingNewBeaconSet ? 'Add Beacon Set' : 'Edit Beacon Set'}
		pageItemId={props.beaconSet?.bleBeaconSetId}
	>
		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ManageBleBeaconSetFormValues>) => <Form className="formik-form">
				<Field
					name="name"
					label="Beacon Set Name"
					component={FormikTextField}
				/>

				{currentUser.identity.type === C.IdentityType.SuperUser && addingNewBeaconSet &&
					<FormikSelect
						name="dealerId"
						label="Dealer"
						placeholder="Select a dealer..."
						options={dealers || []}
						form={formikProps}
						getOptionLabel={option => option.name}
						getOptionValue={option => option.dealerId}
					/>
				}

				<Button
					type="submit" variant="contained" color="primary"
					loading={formikProps.isSubmitting}
					startIcon={addingNewBeaconSet ? null : <SaveIcon />}
					text={addingNewBeaconSet ? 'Add Beacon Set' : 'Save Changes'}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
