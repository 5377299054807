import React from 'react';
import Card from '@material-ui/core/Card';
import WarningIcon from '@material-ui/icons/Warning';

import CloseIcon from '@material-ui/icons/Close';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutline';

import { Button } from 'src/components';
import { CustomTrailerIcon } from '../customIcons'

import './informationBox.scss';

import {
	Client as C,
} from 'src/services';

interface InformationBoxProps {
	onCloseButtonClick?: (() => void) | null;
	inEmergency?: boolean;
	emergencyType?: C.EmergencyType;
	showTitleEmergencyIcon?: boolean;
	title: string;
	userName?: string;
	attachmentName?: string;
	subtitleContent?: JSX.Element;
	clientName?: string;
	subtitleBannerText?: string;
	className?: string;
	contentClassName?: string;
	children?: React.ReactNode;
}

export const InformationBox = (props: InformationBoxProps) => {
	let headerClassNames = 'header';

	if (props.inEmergency) {
		if (props.emergencyType === C.EmergencyType.PriorityAlert)
			headerClassNames += ' priority-alert';
		else
			headerClassNames += ' emergency';
	}

	return <Card className={`information-box ${props.className}`}>
		<div className={headerClassNames}>
			<div className="title">
				<div className="title-label">
					{props.inEmergency && props.showTitleEmergencyIcon && <WarningIcon className="icon"/>}
					<span>
						{props.title}
					</span>
				</div>

				{props.onCloseButtonClick && <Button
					text={<CloseIcon />}
					onClick={props.onCloseButtonClick}
					variant="text"
				/>}
			</div>

			{props.userName && <div className="current-username">
				<PersonOutlinedIcon /><span>&nbsp;{props.userName}</span>
			</div>}

			{props.attachmentName && <div className="current-username">
				<CustomTrailerIcon /><span>&nbsp;{props.attachmentName}</span>
			</div>}

			{props.subtitleContent && <div className="subtitle">
				{props.subtitleContent}
			</div>}
		</div>

		{props.clientName && <div className="subtitle-banner">{`Client: ${props.clientName}`}</div>}

		{props.subtitleBannerText && <div className="subtitle-banner">{props.subtitleBannerText}</div>}

		{props.children && <div className={`content ${props.contentClassName}`}>
			{props.children}
		</div>}

	</Card>;
};
