import React from 'react';
import { Redirect } from 'react-router-dom';

import {
	BrandingService,
	LogicWirelessBranding,
	Service,
	TorutekBranding,
	VitalBranding,
	useInjection,
} from 'src/services';

import { VitalPrivacyPolicy } from 'src/services/branding/vital/vitalPrivacyPolicy';

export const PrivacyPolicy = () => {
	const brandingService = useInjection<BrandingService>(Service.Branding);

	const branding = brandingService.getBranding();
	let redirectUrl: string | undefined = undefined;

	if (branding.company === VitalBranding.company)
		return VitalPrivacyPolicy;

	if (branding.company === LogicWirelessBranding.company)
		redirectUrl = 'https://www.logic-connect.com/privacy-policy';

	if (branding.company === TorutekBranding.company)
		redirectUrl = 'https://torutek.com/privacy-policy';

	if (redirectUrl) {
		React.useEffect(() => {
			window.location.replace(redirectUrl!);
		}, []);

		return <p>
			Redirecting you to the privacy policy...<br />
			Please <a href={redirectUrl} target="_blank" rel="noopener noreferrer">click here</a> if you are not automatically redirected.
		</p>;
	} else {
		return <Redirect to="/auth/sign-in" />;
	}
};
