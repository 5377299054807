import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import useAsyncEffect from 'use-async-effect';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, MessagePage, FormikTextField, FormikCheckbox, FormikSelect, LoadingMessagePage } from 'src/components';
import { runFormValidation } from 'src/util';

import {
	Client as C,
	EnableFleetConfigurationService,
	Service,
	HistoryService,
	ToasterService,
	useInjection,
} from 'src/services';

interface Props {
	match: match<{ id: string }>;
}

type FormValues = { [key: string]: string | number | boolean | undefined };

const isValidIpAddress = (value: string) => {
	const splitAddress = value.split('.');
	if (splitAddress.length !== 4)
		return false;

	for (let i = 0; i < splitAddress.length; i++) {
		const number = +splitAddress[i];
		if (isNaN(number) || number < 0 || number > 255)
			return false;
	}

	return true;
};

export const ConfigureEnableFleetAsset = observer((props: Props) => {
	const _enableFleetConfigurationService = useInjection<EnableFleetConfigurationService>(Service.EnableFleetConfiguration);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [initialFormValues, setInitialFormValues] = useState<FormValues>({});
	const [fields, setFields] = useState<C.IEnableFleetConfigurationFieldDto[]>([]);
	const [dropdownOptions, setDropdownOptions] = useState<{ [key: string]: string[] }>({});

	useAsyncEffect(async () => {
		try {
			const configurations = await _enableFleetConfigurationService.getEnableFleetDeviceConfigurationByAssetId(props.match.params.id);

			const initialFormValues: { [key: string]: string | number | boolean } = {};
			const dropdownOptions: { [key: string]: string[] } = {};

			for (const configuration of configurations) {
				const field = configuration.field;

				switch (configuration.field.fieldType) {
					case C.EnableFleetFieldType.Text:
					case C.EnableFleetFieldType.IpAddress:
						initialFormValues[field.enableFleetConfigurationFieldId] = configuration.value;
						break;

					case C.EnableFleetFieldType.NumberInteger:
					case C.EnableFleetFieldType.NumberDecimal:
						initialFormValues[field.enableFleetConfigurationFieldId] = +(configuration.value);
						break;

					case C.EnableFleetFieldType.Checkbox:
						initialFormValues[field.enableFleetConfigurationFieldId] = configuration.value === '1';
						break;

					case C.EnableFleetFieldType.Dropdown:
						initialFormValues[field.enableFleetConfigurationFieldId] = configuration.value;
						dropdownOptions[field.enableFleetConfigurationFieldId] = configuration.field.dropdownOptions || [];
						break;
				}
			}

			setFields(configurations.map(x => x.field));
			setDropdownOptions(dropdownOptions);
			setInitialFormValues(initialFormValues);
			setLoading(false);
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to load the EnableFleet configuration for this asset.');
			setError(true);
		}
	}, []);

	const onSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
		const updateFields: C.IUpdateEnableFleetDeviceField[] = [];
		for (const field of fields) {
			const fieldValue = values[field.enableFleetConfigurationFieldId];
			let value: string = `${fieldValue}`;
			if (field.fieldType === C.EnableFleetFieldType.Checkbox)
				value = !!fieldValue ? '1' : '0';

			updateFields.push({
				enableFleetConfigurationFieldId: field.enableFleetConfigurationFieldId,
				value: value,
			});
		}

		const request: C.IUpdateEnableFleetDeviceConfigurationRequest = {
			fieldValues: updateFields,
		};

		try {
			await _enableFleetConfigurationService.updateEnableFleetDeviceConfigurationByAssetId(props.match.params.id, request);
			_historyService.history.push('/app/devices/list');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to configure radio.');
		} finally {
			setSubmitting(false);
		}
	};

	const validateForm = (values: FormValues, errors: FormikErrors<FormValues>) => {
		for (const field of fields) {
			const fieldId = field.enableFleetConfigurationFieldId;
			const fieldValue = values[fieldId];

			switch (field.fieldType) {
				case C.EnableFleetFieldType.Text:
					const fieldTextValue = fieldValue as string;

					if (!fieldTextValue && !field.canBeBlank)
						errors[fieldId] = 'This field is required.';
					else if (field.stringMinChars != null && fieldTextValue.length < field.stringMinChars)
						errors[fieldId] = `This field requires a minimum of ${field.stringMinChars} characters.`;
					else if (field.stringMaxChars != null && fieldTextValue.length > field.stringMaxChars)
						errors[fieldId] = `This field cannot contain more than ${field.stringMaxChars} characters.`;

					break;

				case C.EnableFleetFieldType.NumberInteger:
				case C.EnableFleetFieldType.NumberDecimal:
					if (fieldValue == null && !field.canBeBlank) {
						errors[fieldId] = 'This field is required.';
					} else {
						const fieldNumberValue = Number.parseFloat(fieldValue as string);

						if (!Number.isInteger(fieldNumberValue) && field.fieldType === C.EnableFleetFieldType.NumberInteger)
							errors[fieldId] = 'Value must be an integer.';
						else if (field.numberMinimum != null && fieldNumberValue < field.numberMinimum)
							errors[fieldId] = `Value must be greater than or equal to ${field.numberMinimum}.`;
						else if (field.numberMaximum != null && fieldNumberValue > field.numberMaximum)
							errors[fieldId] = `Value must be less than or equal to ${field.numberMaximum}.`;
						else if (field.numberStep) {
							const zeroBasedValue = fieldNumberValue - field.numberMinimum!;
							const steps = +(zeroBasedValue / field.numberStep!).toFixed(10);

							if (!Number.isInteger(steps))
								errors[fieldId] = `Value must be an increment of ${field.numberStep}.`;
						}
					}

					break;

				case C.EnableFleetFieldType.IpAddress:
					const fieldIpAddressValue = fieldValue as string;

					if (!fieldIpAddressValue && !field.canBeBlank)
						errors[fieldId] = 'This field is required.';
					else (!isValidIpAddress(fieldIpAddressValue))
						errors[fieldId] = 'Value must be a valid IP address.';

					break;

				case C.EnableFleetFieldType.Dropdown:
					const fieldDropdownValue = fieldValue as (string | null);

					const allowedOptions: (string | null)[] = [
						...dropdownOptions[field.enableFleetConfigurationFieldId],
					];

					if (field.canBeBlank)
						allowedOptions.push(null);

					if (!allowedOptions.includes(fieldDropdownValue))
						errors[fieldId] = 'A value must be selected from the available options.';

					break;

				case C.EnableFleetFieldType.Checkbox:
					// No special handling required.
					break;

				default:
					console.error(`Validation missing for field type: ${field.fieldType}`);
			}
		}
	};

	const renderField = (field: C.IEnableFleetConfigurationFieldDto, formikProps: FormikProps<FormValues>) => {
		switch (field.fieldType) {
			case C.EnableFleetFieldType.Text:
			case C.EnableFleetFieldType.IpAddress:
				return <Field
					key={field.enableFleetConfigurationFieldId}
					margin="normal"
					name={field.enableFleetConfigurationFieldId}
					label={field.label}
					type="text"
					component={FormikTextField}
					disabled={field.readOnly}
				/>;

			case C.EnableFleetFieldType.NumberInteger:
			case C.EnableFleetFieldType.NumberDecimal:
				return <Field
					key={field.enableFleetConfigurationFieldId}
					margin="normal"
					name={field.enableFleetConfigurationFieldId}
					label={field.label}
					type="number"
					component={FormikTextField}
					disabled={field.readOnly}
					inputProps={{
						step: field.numberStep,
						min: field.numberMinimum,
						max: field.numberMaximum,
					}}
				/>;

			case C.EnableFleetFieldType.Checkbox:
				return <Field
					key={field.enableFleetConfigurationFieldId}
					name={field.enableFleetConfigurationFieldId}
					label={field.label}
					type="checkbox"
					component={FormikCheckbox}
				/>;

			case C.EnableFleetFieldType.Dropdown:
				return <FormikSelect
					key={field.enableFleetConfigurationFieldId}
					name={field.enableFleetConfigurationFieldId}
					label={field.label}
					options={dropdownOptions[field.enableFleetConfigurationFieldId] || []}
					form={formikProps}
					getOptionLabel={option => option}
					getOptionValue={option => option}
				/>;

			default:
				console.error(`Failed to render form field type: ${field.fieldType}`);
				return null;
		}
	};

	if (error) {
		return <MessagePage
			title="There was an error while trying to load the EnableFleet configuration for this asset."
			backButton
		/>;
	} else if (loading) {
		return <LoadingMessagePage />;
	} else if (fields.length === 0) {
		return <MessagePage
			title="This asset does not have any fields available for you to configure."
			backButton
		/>;
	}

	return <FixedWidthPage
		className="form-page"
		headingText="Edit EnableFleet Settings"
	>
		<Formik
			initialValues={initialFormValues!}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<FormValues>) => <Form
				className="formik-form"
			>
				{fields.map(x => renderField(x, formikProps))}

				<Button
					type="submit"
					variant="contained"
					color="primary"
					startIcon={<SaveIcon />}
					text="Save Changes"
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
