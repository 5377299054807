import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { ListItem } from '@material-ui/core';
import { MaterialTableProps } from '@material-table/core';
import Divider from '@material-ui/core/Divider';

import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CommuteIcon from '@material-ui/icons/Commute';

import { featureToggleAssetTypes } from 'src/config';

import { ActivateDevicesDialog, DeactivateDevicesDialog, Button, FixedWidthPage, MaterialTable, PopoverMenu, PopoverMenuItem, LoadingMessagePage, ErrorMessagePage, DeleteAssetsDialog, UnassignDeviceFromAssetDialog, UnassignAssetDevice, AssignDeviceToAssetDialog } from 'src/components';
import { DeviceActivationState, DeviceType } from 'src/../__generated__/globalTypes';
import { QueryAssetsList_assets, useQueryAssetsList } from 'src/graphql/__generated__/queries/queryAssetsList';

import {
	Client as C,
	useCurrentUser,
	usePermissions,
} from 'src/services';

interface IAssetRow {
	id: string;
	name: string;
	assetType?: string;
	dealerName: string;
	clientName: string;
	asset: QueryAssetsList_assets;
}

import './assetsList.scss';

export const AssetsList = observer(() => {
	const currentUser = useCurrentUser()!;
	const permissions = usePermissions()!;
	const identityType = currentUser.identity.type;

	const [activateDeviceIds, setActivateDeviceIds] = useState<string[] | null>(null);
	const [deactivateDeviceIds, setDeactivateDeviceIds] = useState<string[] | null>(null);
	const [deleteAssetIds, setDeleteAssetIds] = useState<string[] | null>(null);
	const [assignDevice, setAssignDevice] = useState<QueryAssetsList_assets | null>(null);
	const [unassignAssetDevice, setUnassignAssetDevice] = useState<UnassignAssetDevice | null>(null);

	const query = useQueryAssetsList({
		variables: {
			includeDealer: identityType === C.IdentityType.SuperUser,
			includeClient: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
			includeNetwork: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		},
	});

	if (query.loading)
		return <LoadingMessagePage />;

	if (query.error || !query.data?.assets)
		return <ErrorMessagePage />;

	const canActivateDevices = permissions.activateDevices || false;
	const canDeactivateDevices = permissions.deactivateDevices || false;
	const canDeleteAssets = permissions.deleteAssets || false;

	const assetRows = query.data.assets
		.map(asset => ({
			id: asset.id,
			name: asset.name,
			assetType: asset.assetType?.name || '',
			dealerName: asset.dealer?.name,
			clientName: asset.client?.name,
			asset,
		} as IAssetRow))
		.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

	const uniqueAssetTypes = new Set(assetRows.filter(x => x.assetType).map(x => x.assetType));
	const assetTypes: { [key: string]: string } = {};
	for (const type of uniqueAssetTypes) {
		if (type)
			assetTypes[type] = type;
	}

	const renderAssetRowDropdown = (assetRow: IAssetRow) => {
		const options: JSX.Element[] = [
			<ListItem style={{ fontWeight: 500 }}>Asset: {assetRow.name}</ListItem>,

			<PopoverMenuItem
				key="edit"
				text="Edit"
				href={`/app/assets/${assetRow.id}/edit`}
				title="Edit asset"
			/>,
		];

		if (permissions.manageDevices && assetRow.asset.devices?.find(x => x.deviceType === DeviceType.TELTONIKA || x.deviceType === DeviceType.DIGITAL_MATTER)) {
			options.push(<PopoverMenuItem
				key="configure-io"
				href={`/app/assets/${assetRow.id}/io-configurations/list`}
				text="Configure IO"
			/>);
		}

		if (canDeleteAssets) {
			options.push(<PopoverMenuItem
				key="delete"
				text="Delete"
				title="Delete asset"
				onClick={() => setDeleteAssetIds([assetRow.id])}
			/>);
		}

		options.push(<PopoverMenuItem
			key="vehicle-information"
			text="Vehicle Information"
			title="Vehicle Information"
			href={`/app/vehicle-information/${assetRow.id}`}
		/>);

		if (permissions.manageDevices) {
			if (assetRow.asset.devices && assetRow.asset.devices.length > 0) {
				for (const device of assetRow.asset.devices) {
					options.push(<Divider style={{ margin: '10px 0' }} />);
					options.push(<ListItem style={{ fontWeight: 500 }}>Device: {device.name}</ListItem>);

					options.push(<PopoverMenuItem
						key={`edit-device-${device.id}`}
						text="Edit"
						href={`/app/devices/${device.id}/edit`}
						title={`Edit device: ${device.name}`}
					/>);

					if (canActivateDevices && device.activationState !== DeviceActivationState.ACTIVATED) {
						options.push(<PopoverMenuItem
							key={`activate-device-${device.id}`}
							text="Activate"
							title="Activate"
							onClick={() => setActivateDeviceIds([device.id])}
						/>);
					}

					if (canDeactivateDevices && device.activationState === DeviceActivationState.ACTIVATED) {
						options.push(<PopoverMenuItem
							key={`deactivate-device-${device.id}`}
							text="Deactivate"
							title="Deactivate"
							onClick={() => setDeactivateDeviceIds([device.id])}
						/>);
					}

					options.push(<PopoverMenuItem
						key={`unassign-device-${device.id}`}
						text="Unassign"
						title={`Unassign device: ${device.name}`}
						onClick={() => setUnassignAssetDevice({ asset: assetRow.asset, device: device })}
					/>);
				}
			} else {
				options.push(<Divider style={{ margin: '10px 0' }} />);

				options.push(<PopoverMenuItem
					key="assign-device"
					text="Assign device"
					onClick={() => setAssignDevice(assetRow.asset)}
					title="Assign device"
				/>);
			}
		}

		return options;
	};

	const renderEditMultipleAssetsMenu = (rows: IAssetRow[]) => {
		const options: JSX.Element[] = [];

		if (canDeleteAssets) {
			options.push(<PopoverMenuItem
				key="delete"
				text="Delete"
				title="Delete selected assets"
				onClick={() => setDeleteAssetIds(rows.map(x => x.id))}
			/>);
		}

		return options;
	};

	const renderAddActionMenu = () => {
		const options: JSX.Element[] = [];

		options.push(<PopoverMenuItem
			key="add-asset"
			text="Add Asset"
			href={`/app/assets/add-with-device`}
		/>);

		if (permissions.importFromFile) {
			options.push(<PopoverMenuItem
				key="import-from-file"
				text="Import From File"
				href={`/app/assets/import-from-file`}
			/>);
		}

		return options;
	};

	const renderHeadingActions = () => {
		const addAssetOptions = renderAddActionMenu();
		const canAccessAssetGroups = permissions.manageAssetGroups;

		return <>
			{featureToggleAssetTypes === 'true' && <Button href="/app/asset-types/list" text="Asset Types" startIcon={<CommuteIcon />} variant="outlined" color="primary"/>}

			{canAccessAssetGroups && <Button className="asset-groups-button" href="/app/asset-groups/list" text="Asset Groups" variant="outlined" color="primary"/>}

			{permissions.addAssets && addAssetOptions.length > 0 && <PopoverMenu
				renderOptions={() => addAssetOptions}
			>
				<Button
					startIcon={<AddIcon />}
					text="Add"
					color="primary"
					variant="outlined"
				/>
			</PopoverMenu>}
		</>;
	};

	const renderTableActions = (props: MaterialTableProps<IAssetRow>) => {
		if (!props.data || props.data.length === 0)
			return null;

		return <PopoverMenu
			renderOptions={() => renderEditMultipleAssetsMenu(props.data as IAssetRow[])}
		>
			<Button
				text="Edit Assets"
				endIcon={<ArrowDropDownIcon />}
				color="primary"
				variant="contained"
			/>
		</PopoverMenu>;
	};

	return <FixedWidthPage
		headingText="Assets"
		headingActions={renderHeadingActions()}
		noContentBackground
	>
		<MaterialTable
			tableName="assets-list"
			columns={[
				{
					title: 'Name',
					field: 'name',
					grouping: false,
				},
				{
					title: 'Asset Type',
					field: 'assetType',
					lookup: assetTypes,
					hidden: featureToggleAssetTypes !== 'true' || Object.keys(assetTypes).length === 0,
					hiddenByColumnsButton: featureToggleAssetTypes !== 'true' || Object.keys(assetTypes).length === 0,
				},
				{
					title: 'Dealer',
					field: 'dealerName',
					hidden: identityType !== C.IdentityType.SuperUser,
					hiddenByColumnsButton: identityType !== C.IdentityType.SuperUser,
				},
				{
					title: 'Client',
					field: 'clientName',
					hiddenByColumnsButton: identityType !== C.IdentityType.SuperUser && identityType !== C.IdentityType.Dealer,
					hidden: identityType !== C.IdentityType.SuperUser && identityType !== C.IdentityType.Dealer,
				},
				{
					title: 'Options',
					field: 'options',
					grouping: false,
					filtering: false,
					sorting: false,
					headerStyle: {
						textAlign: 'right',
					},
					cellStyle: {
						textAlign: 'right',
					},
					render: rowData => <PopoverMenu
						renderOptions={() => renderAssetRowDropdown(rowData)}
					/>
				},
			]}
			data={assetRows}
			options={{
				selection: canDeleteAssets,
				rowStyle: (row: IAssetRow) => {
					const activated = row.asset.devices && row.asset.devices.length > 0 && row.asset.devices.some(x => x.activationState === DeviceActivationState.ACTIVATED);
					return {
						backgroundColor: activated ? undefined : '#f9f9f9',
						color: activated ? undefined : '#757373',
					};
				},
			}}
			components={{
				Actions: renderTableActions,
			}}
			localization={{
				toolbar: {
					nRowsSelected: '{0} asset(s) selected',
				},
			}}
		/>

		{activateDeviceIds && <ActivateDevicesDialog
			deviceIds={activateDeviceIds}
			close={() => setActivateDeviceIds(null)}
			onSuccess={query.refetch}
		/>}

		{deactivateDeviceIds && <DeactivateDevicesDialog
			deviceIds={deactivateDeviceIds}
			close={() => setDeactivateDeviceIds(null)}
			onSuccess={query.refetch}
		/>}

		{deleteAssetIds && <DeleteAssetsDialog
			assetIds={deleteAssetIds}
			close={() => setDeleteAssetIds(null)}
			onSuccess={query.refetch}
		/>}

		{assignDevice && <AssignDeviceToAssetDialog
			asset={assignDevice}
			close={() => setAssignDevice(null)}
			onSuccess={query.refetch}
		/>}

		{unassignAssetDevice && <UnassignDeviceFromAssetDialog
			assetDevice={unassignAssetDevice}
			close={() => setUnassignAssetDevice(null)}
			onSuccess={query.refetch}
		/>}
	</FixedWidthPage>;
});
