import { ApolloClient, InMemoryCache } from '@apollo/client';
import { autorun } from 'mobx';
import queryString from 'query-string';

import {
	Container,
	AuthenticationService,
	TableDataService,
	EmergencyService,
	FloorPlansService,
	HistoryService,
	Service,
	WebSocketService,
} from 'src/services';

const apolloClient = Container.get<ApolloClient<InMemoryCache>>(Service.ApolloClient);
const authService = Container.get<AuthenticationService>(Service.Authentication);
const emergencyService = Container.get<EmergencyService>(Service.Emergency);
const floorPlanService = Container.get<FloorPlansService>(Service.FloorPlans);
const historyService = Container.get<HistoryService>(Service.History);
const tableDataService = Container.get<TableDataService>(Service.TableDataService);
const webSocketService = Container.get<WebSocketService>(Service.WebSocket);

let hadUser: boolean = false;

// Stop app access when not authenticated, allow after signing in
autorun(() => {
	const history = historyService.history;
	if (!history.location)
		return;

	let queryReturnUrl: string | null = null;
	const queryParams = queryString.parse(history.location.search);
	if (queryParams?.returnUrl)
		queryReturnUrl = queryParams.returnUrl as string;

	if (history.location.pathname.startsWith('/emergency') || history.location.pathname.startsWith('/privacy-policy'))
		return;

	const onAppPage = history.location.pathname.startsWith('/app');
	const onAuthPage = history.location.pathname.startsWith('/auth');
	const onSignInPage = history.location.pathname === '/auth/sign-in';

	// If the user is signed in, send them to the app.
	if (authService.isAuthenticated) {
		if (!onAppPage) {
			const redirectUrl = queryReturnUrl && queryReturnUrl.startsWith('/app') ? queryReturnUrl : '/app';
			history.replace(redirectUrl);
		}

		return;
	}

	// The user is not signed in.
	// If they're not on an auth page, or they're attempting re-auth, send them to sign in.
	// We do this after the authenticated check, because then you can re-auth without being redirected to the sign in screen.
	if (!onAuthPage || (authService.isAttemptingReauthentication && !onSignInPage)) {
		const returnUrl = history.location.pathname + history.location.search;
		history.replace(`/auth/sign-in?returnUrl=${encodeURIComponent(returnUrl)}`);
		return;
	}

	if (authService.isAttemptingReauthentication)
		return;

	// The user is not signed in and we are not attempting re-auth.
	// If they were just trying to get to an auth page, send them back to that auth page.
	if (queryReturnUrl && queryReturnUrl.startsWith('/auth')) {
		history.replace(queryReturnUrl);
	}
}, { name: 'stop app access when not authenticated, allow after signing in' });

let currentUserId: string | null = null;

// Notify stores that user has changed/signed out
autorun(async () => {
	if (authService.currentAuth) {
		if (currentUserId === authService.currentAuth.user.userId)
			return;
	} else {
		if (!currentUserId)
			return;
	}

	if (hadUser)
		await webSocketService.resetState();

	emergencyService.resetState();
	floorPlanService.resetState();
	tableDataService.resetState();

	if (hadUser) {
		apolloClient.stop();
		await apolloClient.resetStore();
	}

	currentUserId = authService.currentAuth ? authService.currentAuth.user.userId : null;

	if (currentUserId) {
		await webSocketService.start();
		hadUser = true;
	}
}, { name: 'notify stores that user has changed/signed out' });
