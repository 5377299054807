import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import moment from 'moment-timezone';

import { AssetSelector, Button, FixedWidthPage, FormikTextField, MessagePage, AssetGroupSelector } from 'src/components';
import { runFormValidation } from 'src/util';
import { maxReportDuration } from 'src/app/reports/reportConfiguration';

import {
	Client as C,
	AuthenticationService,
	ToasterService,
	HistoryService,
	ReportService,
	Service,
	useInjection,
	useAssetSelectorService,
	useAssetGroupSelectorService,
} from 'src/services';

interface CreateAssetLogHistoryReportFormValues {
	name: string;
	startDate: string;
	endDate: string;
	assets: string[];
	assetGroups: string[];
}

const validateForm = (values: CreateAssetLogHistoryReportFormValues, errors: FormikErrors<CreateAssetLogHistoryReportFormValues>) => {
	if (!values.name)
		errors.name = 'Report name is required.';

	if (values.assets.length === 0 && values.assetGroups.length === 0) {
		errors.assets = 'At least one asset or asset group is required.';
		errors.assetGroups = 'At least one asset or asset group is required.';
	}

	if (!values.startDate)
		errors.startDate = 'A start date is required.';

	if (!values.endDate)
		errors.endDate = 'An end date is required.';
};

export const CreateAssetLogHistoryReport = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _reportService = useInjection<ReportService>(Service.Report);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const _assetSelectorService = useAssetSelectorService();
	const _assetGroupSelectorService = useAssetGroupSelectorService(C.ListAssetGroupsType.Report);

	const onSubmit = async (values: CreateAssetLogHistoryReportFormValues, { setSubmitting, setFieldError }: FormikHelpers<CreateAssetLogHistoryReportFormValues>) => {
		const startDate = moment(values.startDate);
		const endDate = moment(values.endDate);

		if (endDate.isBefore(startDate)) {
			setFieldError('endDate', 'Report end date cannot be before report start date.');
			setSubmitting(false);
			return;
		}

		if (startDate < endDate.clone().subtract(maxReportDuration)) {
			setFieldError('endDate', `Duration may not exceed ${maxReportDuration.humanize()}.`);
			setSubmitting(false);
			return;
		}

		try {
			const request: C.INewAssetLogHistoryReportRequest = {
				name: values.name,
				assetIds: values.assets,
				assetGroupIds: values.assetGroups,
				startDate: startDate.format('YYYY-MM-DD'),
				endDate: endDate.format('YYYY-MM-DD'),
			};

			await _reportService.createAssetLogHistoryReport(request);
			_historyService.history.push('/app/reports');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to create report.');
			setSubmitting(false);
		}
	};

	const today = moment.tz(_authService.currentAuth.user.timeZone);

	return <FixedWidthPage
		className="form-page"
		headingText="Create Asset Log History Report"
		contentMessageReplace={_assetSelectorService.loading && <MessagePage loading />}
	>
		<Formik
			initialValues={{
				name: '',
				endDate: today.format('YYYY-MM-DD'),
				startDate: today.subtract(1, 'day').format('YYYY-MM-DD'),
				assets: [],
				assetGroups: [],
			}}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<CreateAssetLogHistoryReportFormValues>) => <Form className="formik-form material">
				<Field
					name="name"
					label="Report Name"
					component={FormikTextField}
					required
				/>

				<AssetSelector
					options={_assetSelectorService.assetOptions}
					formikProps={formikProps}
				/>

				<AssetGroupSelector
					options={_assetGroupSelectorService.assetGroupOptions}
					formikProps={formikProps}
				/>

				<Field
					name="startDate"
					label="Report Start Date"
					type="date"
					component={FormikTextField}
					required
				/>

				<Field
					name="endDate"
					label="Report End Date"
					type="date"
					component={FormikTextField}
					required
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					text="Create"
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
