import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import moment from 'moment-timezone';

import { Button, FixedWidthPage, FormikDateTimeRangePicker, DateTimeRange, FormikTextField, MessagePage, UserSelector, UserGroupSelector } from 'src/components';
import { runFormValidation } from 'src/util';
import { maxReportDuration } from 'src/app/reports/reportConfiguration';

import {
	Client as C,
	AuthenticationService,
	HistoryService,
	ReportService,
	Service,
	ToasterService,
	useInjection,
	useUserSelectorService,
	useUserGroupSelectorService,
} from 'src/services';

interface CreateUserAuthAuditReportFormValues {
	name: string;
	identityIds: string[];
	userGroupIds: string[];
	dateTimeRange: DateTimeRange;
}

const validateForm = (values: CreateUserAuthAuditReportFormValues, errors: FormikErrors<CreateUserAuthAuditReportFormValues>) => {
	if (!values.name)
		errors.name = 'Report name is required.';

	if (values.identityIds.length === 0 && values.userGroupIds.length === 0) {
		errors.identityIds = 'At least one user or user group is required.';
		errors.userGroupIds = 'At least one user or user group is required.';
	}
};

export const CreateUserAuthAuditReport = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _reportService = useInjection<ReportService>(Service.Report);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const _userSelectorService = useUserSelectorService();
	const _userGroupSelectorService = useUserGroupSelectorService();

	const onSubmit = async (values: CreateUserAuthAuditReportFormValues, { setSubmitting }: FormikHelpers<CreateUserAuthAuditReportFormValues>) => {
		try {
			const request: C.INewUserAuthAuditReportRequest = {
				name: values.name,
				identityIds: values.identityIds,
				userGroupIds: values.userGroupIds,
				startTimestamp: values.dateTimeRange.start,
				endTimestamp: values.dateTimeRange.end,
			};

			await _reportService.createUserAuthAuditReport(request);
			_historyService.history.push('/app/reports');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to create report.');
			setSubmitting(false);
		}
	};

	const now = moment.tz(_authService.currentAuth.user.timeZone);
	const loading = _userSelectorService.loading || _userGroupSelectorService.loading;

	return <FixedWidthPage
		className="form-page"
		headingText="Create User Auth Audit Report"
		contentMessageReplace={loading && <MessagePage loading />}
	>
		<Formik
			initialValues={{
				name: '',
				identityIds: [],
				userGroupIds: [],
				dateTimeRange: { start: now.clone().subtract(1, 'day'), end: now },
			}}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<CreateUserAuthAuditReportFormValues>) => <Form className="formik-form material">
				<Field
					name="name"
					label="Report Name"
					component={FormikTextField}
					variant="filled"
				/>

				<UserSelector
					options={_userSelectorService.identityOptions}
					formikProps={formikProps}
				/>

				<UserGroupSelector
					options={_userGroupSelectorService.userGroupOptions}
					formikProps={formikProps}
				/>

				<FormikDateTimeRangePicker
					name="dateTimeRange"
					label="Report Period"
					form={formikProps}
					fullWidth
					maxDuration={maxReportDuration}
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					text="Create"
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
