import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';

import AddIcon from '@material-ui/icons/Add';

import { Button, FixedWidthPage, ListCopyIdButton, MessagePage, PopoverMenu, PopoverMenuItem, ThingLoader } from 'src/components';

import {
	AuthenticationService,
	Client as C,
	Service,
	SiteService,
	useInjection,
} from 'src/services';

export const SiteList = observer(() => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _siteService = useInjection<SiteService>(Service.Site);

	const load = async () => {
		const sites = await _siteService.getAllSites();
		if (sites == null)
			return null;

		return sites.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
	};

	const renderSiteInformation = (site: C.ISiteDto) => {
		const canEditSite = site.networkType !== C.NetworkType.TaitTier3 || _authenticationService.currentAuth.user.identity.type === C.IdentityType.SuperUser;

		return <tr key={site.siteId} className="content-box">
			<td>
				<ListCopyIdButton id={site.siteId} />
				{site.name}
			</td>
			<td>{getNetworkTypeFormatted(site.networkType)}</td>

			<td className="actions">
				{canEditSite && <PopoverMenu
					renderOptions={() => renderActionMenu(site)}
				/>}
			</td>
		</tr>;
	};

	const renderActionMenu = (site: C.ISiteDto) => {
		const options = [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				href={`/app/sites/${site.siteId}/edit`}
			/>
		];

		if ((site.networkType === C.NetworkType.HyteraTier2 || site.networkType === C.NetworkType.TaitTier2 ||
			site.networkType === C.NetworkType.TaitTier3 || site.networkType === C.NetworkType.ChatterPtt) &&
			_authenticationService.currentAuth.permissions.general.accessCalls &&
			_authenticationService.currentAuth.permissions.general.manageCallGroups) {
			options.push(<PopoverMenuItem
				key="manageCallGroups"
				text="Manage Call Groups"
				href={`/app/sites/${site.siteId}/call-groups`}
			/>);
		}

		if (site.networkType === C.NetworkType.TaitTier3 && site.enableFleetConfigurationId) {
			options.push(<PopoverMenuItem
				key="manageEnableFleetPermissions"
				text="Manage EnableFleet Permissions"
				href={`/app/enablefleet/${site.enableFleetConfigurationId}/permissions/${site.siteId}`}
			/>);
		}

		return options;
	};

	const getNetworkTypeFormatted = (type: C.NetworkType): string => {
		switch (type) {
			case C.NetworkType.ChatterPtt:
				return 'ChatterPTT';
			case C.NetworkType.HyteraTier2:
				return 'Hytera Tier 2';

			case C.NetworkType.TaitTier2:
				return 'Tait Tier 2';

			case C.NetworkType.TaitTier3:
				return 'Tait Tier 3';

			case C.NetworkType.Teltonika:
				return 'Teltonika';

			default:
				return 'Unknown';
		}
	};

	const canAddSites = _authenticationService.currentAuth.permissions.general.addSites;
	const canManageEnableFleetConfigurations = _authenticationService.currentAuth.permissions.general.manageEnableFleetConfigurations;

	return <ThingLoader
		load={load}
		render={(sites: C.ISiteDto[]) => <FixedWidthPage
			headingText="Sites"
			headingActions={[
				canManageEnableFleetConfigurations ? <Button href="/app/enablefleet/list" text="EnableFleet Configurations" variant="outlined" color="primary" /> : null,
				canAddSites ? <Button href="/app/sites/add" text="Add" startIcon={<AddIcon />} variant="outlined" color="primary" /> : null,
			]}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': sites.length === 0 })}
		>
			{sites.length > 0 && <table className="card-table site-list">
				<thead>
					<tr>
						<th>Name</th>
						<th>Network Type</th>
						<th></th>
					</tr>
				</thead>

				<tbody>
					{sites.map(renderSiteInformation)}
				</tbody>
			</table>}

			{sites.length === 0 && <MessagePage
				title="No sites."
				action={canAddSites ? <Button href="/app/sites/add" text="Add a site?" variant="outlined" /> : null}
			/>}
		</FixedWidthPage>}
	/>;
});
