import { ApolloClient, InMemoryCache } from '@apollo/client';

import { executeMutationUpdateATrackDevice } from 'src/graphql/__generated__/mutations/mutationUpdateATrackDevice';
import { executeMutationUpdateCamperVanDevice } from 'src/graphql/__generated__/mutations/mutationUpdateCamperVanDevice';
import { executeMutationUpdateDigitalMatterDevice } from 'src/graphql/__generated__/mutations/mutationUpdateDigitalMatterDevice';
import { executeMutationUpdateEroadDevice } from 'src/graphql/__generated__/mutations/mutationUpdateEroadDevice';
import { executeMutationUpdateHyteraRadioDevice } from 'src/graphql/__generated__/mutations/mutationUpdateHyteraRadioDevice';
import { executeMutationUpdateMobilePhoneDevice } from 'src/graphql/__generated__/mutations/mutationUpdateMobilePhoneDevice';
import { executeMutationUpdateTaitRadioDevice } from 'src/graphql/__generated__/mutations/mutationUpdateTaitRadioDevice';
import { executeMutationUpdateTeltonikaDevice } from 'src/graphql/__generated__/mutations/mutationUpdateTeltonikaDevice';
import { QueryEditDevice_device, QueryEditDevice_device_ATrackDevice, QueryEditDevice_device_CamperVanDevice, QueryEditDevice_device_DigitalMatterDevice, QueryEditDevice_device_EroadDevice, QueryEditDevice_device_HyteraRadioDevice, QueryEditDevice_device_MobilePhoneDevice, QueryEditDevice_device_TaitRadioDevice, QueryEditDevice_device_TeltonikaDevice } from 'src/graphql/__generated__/queries/queryEditDevice';

import { ManageDeviceFormValues } from './manageDeviceComponent';
import { undefinedIfUnchanged, undefinedIfUnchangedArray, undefinedIfUnchangedInstant } from 'src/util';
import { isATrackDevice, isCamperVanDevice, isDigitalMatterDevice, isEroadDevice, isHyteraRadioDevice, isMobilePhoneDevice, isTaitRadioDevice, isTeltonikaDevice } from './initialFormValues';

interface BaseDeviceInput {
	deviceId: string;
	name?: string;
	serialNumber?: string | null;
	dealerId?: string | null;
	clientId?: string | null;
}

export const submitUpdateDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, device: QueryEditDevice_device) => {
	const base = {
		deviceId: device.id,
		name: undefinedIfUnchanged(device.name, values.deviceName),
		dealerId: undefinedIfUnchanged(device.dealer?.id, values.dealerId),
		clientId: undefinedIfUnchanged(device.client?.id, values.clientId),
		serialNumber: undefinedIfUnchanged(device.serialNumber, values.serialNumber || undefined),
	};

	if (isATrackDevice((device)))
		return updateATrackDevice(apolloClient, values, device, base);
	else if (isCamperVanDevice((device)))
		return updateCamperVanDevice(apolloClient, values, device, base);
	else if (isDigitalMatterDevice((device)))
		return updateDigitalMatterDevice(apolloClient, values, device, base);
	else if (isEroadDevice((device)))
		return updateEroadDevice(apolloClient, values, device, base);
	else if (isHyteraRadioDevice((device)))
		return updateHyteraRadioDevice(apolloClient, values, device, base);
	else if (isMobilePhoneDevice((device)))
		return updateMobilePhoneDevice(apolloClient, values, device, base);
	else if (isTaitRadioDevice((device)))
		return updateTaitRadioDevice(apolloClient, values, device, base);
	else if (isTeltonikaDevice((device)))
		return updateTeltonikaDevice(apolloClient, values, device, base);

	throw `Unhandled device type: ${values.deviceType}`;
};

const updateATrackDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, device: QueryEditDevice_device_ATrackDevice, base: BaseDeviceInput) => {
	const result = await executeMutationUpdateATrackDevice(apolloClient, {
		input: {
			...base,
			imei: undefinedIfUnchanged(device.imei, values.imei),
			phoneNumber: undefinedIfUnchanged(device.phoneNumber, values.phoneNumber || undefined),
			modelType: undefinedIfUnchanged(device.atrackModelType, values.atrackModelType),
		},
	});

	return result.data!.updateATrackDevice!.atrackDevice;
};

const updateCamperVanDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, device: QueryEditDevice_device_CamperVanDevice, base: BaseDeviceInput) => {
	const result = await executeMutationUpdateCamperVanDevice(apolloClient, {
		input: {
			...base,
			imei: undefinedIfUnchanged(device.imei, values.imei),
		},
	});

	return result.data!.updateCamperVanDevice!.camperVanDevice;
};

const updateDigitalMatterDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, device: QueryEditDevice_device_DigitalMatterDevice, base: BaseDeviceInput) => {
	const result = await executeMutationUpdateDigitalMatterDevice(apolloClient, {
		input: {
			...base,
			imei: undefinedIfUnchanged(device.imei, values.imei),
			phoneNumber: undefinedIfUnchanged(device.phoneNumber, values.phoneNumber || undefined),
			modelType: undefinedIfUnchanged(device.digitalMatterModelType, values.digitalMatterModelType),
		},
	});

	return result.data!.updateDigitalMatterDevice!.digitalMatterDevice;
};

const updateEroadDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, device: QueryEditDevice_device_EroadDevice, base: BaseDeviceInput) => {
	const result = await executeMutationUpdateEroadDevice(apolloClient, {
		input: {
			...base,
			vehiclePlate: undefinedIfUnchanged(device.vehiclePlate, values.vehiclePlate),
		},
	});

	return result.data!.updateEroadDevice!.eroadDevice;
};

const updateHyteraRadioDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, device: QueryEditDevice_device_HyteraRadioDevice, base: BaseDeviceInput) => {
	const result = await executeMutationUpdateHyteraRadioDevice(apolloClient, {
		input: {
			...base,
			networkId: undefinedIfUnchanged(device.network?.id, values.networkId || undefined),
			radioId: undefinedIfUnchanged(device.radioId, values.radioId || undefined),
			bleEnabled: undefinedIfUnchanged(device.bleEnabled, values.bleEnabled),
			gpsEnabled: undefinedIfUnchanged(device.gpsEnabled, values.gpsEnabled),
			bluetoothBeaconSetIds: undefinedIfUnchangedArray(device.bluetoothBeaconSets?.map(x => x?.id), values.bluetoothBeaconSetIds),
		},
	});

	return result.data!.updateHyteraRadioDevice!.hyteraRadioDevice;
};

const updateMobilePhoneDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, device: QueryEditDevice_device_MobilePhoneDevice, base: BaseDeviceInput) => {
	const result = await executeMutationUpdateMobilePhoneDevice(apolloClient, {
		input: {
			...base,
			networkId: undefinedIfUnchanged(device.network?.id, values.networkId || undefined),
			chatterPttUserId: undefinedIfUnchanged(device.chatterPttUserId, values.chatterPttUserId || undefined),
			callRecordingEnabled: undefinedIfUnchanged(device.callRecordingEnabled, values.callRecordingEnabled),
			bluetoothBeaconSetIds: undefinedIfUnchangedArray(device.bluetoothBeaconSets?.map(x => x?.id), values.bluetoothBeaconSetIds),
		},
	});

	return result.data!.updateMobilePhoneDevice!.mobilePhoneDevice;
};

const updateTaitRadioDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, device: QueryEditDevice_device_TaitRadioDevice, base: BaseDeviceInput) => {
	const result = await executeMutationUpdateTaitRadioDevice(apolloClient, {
		input: {
			...base,
			networkId: undefinedIfUnchanged(device.network?.id, values.networkId || undefined),
			radioId: undefinedIfUnchanged(device.radioId, values.radioId || undefined),
			selcallId: undefinedIfUnchanged(device.selcallId, values.selCallId || undefined),
			bleEnabled: undefinedIfUnchanged(device.bleEnabled, values.bleEnabled),
			gpsEnabled: undefinedIfUnchanged(device.gpsEnabled, values.gpsEnabled),
			callRecordingEnabled: undefinedIfUnchanged(device.callRecordingEnabled, values.callRecordingEnabled),
			manualPollingEnabled: undefinedIfUnchanged(device.manualPollingEnabled, values.manualPollingEnabled),
			bluetoothBeaconSetIds: undefinedIfUnchangedArray(device.bluetoothBeaconSets?.map(x => x?.id), values.bluetoothBeaconSetIds),
		},
	});

	return result.data!.updateTaitRadioDevice!.taitRadioDevice;
};

const updateTeltonikaDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, device: QueryEditDevice_device_TeltonikaDevice, base: BaseDeviceInput) => {
	const result = await executeMutationUpdateTeltonikaDevice(apolloClient, {
		input: {
			...base,
			imei: undefinedIfUnchanged(device.imei, values.imei),
			phoneNumber: undefinedIfUnchanged(device.phoneNumber, values.phoneNumber || undefined),
			modelType: undefinedIfUnchanged(device.teltonikaModelType, values.teltonikaModelType),
			networkId: undefinedIfUnchanged(device.network?.id, values.networkId || undefined),
		},
	});

	return result.data!.updateTeltonikaDevice!.teltonikaDevice;
};
