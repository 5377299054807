import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryDealer($id: UUID!) {
		dealerById(id: $id) {
			id
			name
			type
			maxTrialMonths
			billingCode
			assetTrialEndNotificationEnabled
			assetTrialEndNotificationPeriodDays
			navbarBrandingImageUrl
		}
	}
`;
