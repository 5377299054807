import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryEditAsset($id: UUID!, $includeDealers: Boolean!, $includeClients: Boolean!) {
		assetTypes {
			id
			name
			client @include(if: $includeClients) {
				id
			}
		}

		dealers @include(if: $includeDealers) {
			id
			name
		}

		clients @include(if: $includeClients) {
			id
			name
			dealer @include(if: $includeDealers) {
				id
			}
		}

		asset: assetById(id: $id) {
			id
			name
			speedLimit
			emergencyNotes
			billingType
			trialPeriodEndTimestamp
			assetType {
				id
			}
			dealer @include(if: $includeDealers) {
				id
			}
			client @include(if: $includeClients) {
				id
			}
		}
	}
`;
