import React from 'react';
import { observer } from 'mobx-react';
import { Field, FormikProps } from 'formik';

import { FormikSelect, FormikTextField } from 'src/components';
import { DigitalMatterDeviceModelType } from 'src/../__generated__/globalTypes';

const digitalMatterModelTypeOptions = [
	{ value: DigitalMatterDeviceModelType.Bolt2, label: 'Bolt2' },
	{ value: DigitalMatterDeviceModelType.Dart3, label: 'Dart3' },
	{ value: DigitalMatterDeviceModelType.G120, label: 'G120' },
	{ value: DigitalMatterDeviceModelType.G70, label: 'G70' },
	{ value: DigitalMatterDeviceModelType.Oyster3, label: 'Oyster3' },
	{ value: DigitalMatterDeviceModelType.Remora3, label: 'Remora3' },
	{ value: DigitalMatterDeviceModelType.Yabby3, label: 'Yabby3' },
];

interface IDigitalMatterFormValues {
	digitalMatterModelType: DigitalMatterDeviceModelType | null;
	imei: string;
	phoneNumber: string;
}

interface IProps<T> {
	form: FormikProps<T>;
}

export const ManageDigitalMatterDeviceComponent = observer(<T extends IDigitalMatterFormValues>(props: IProps<T>) => {
	return <>
		<FormikSelect
			name="digitalMatterModelType"
			label="Model Type"
			form={props.form}
			options={digitalMatterModelTypeOptions}
			getOptionValue={x => x.value}
			getOptionLabel={x => x.label}
			required
		/>

		<Field
			name="imei"
			label="IMEI"
			type="text"
			component={FormikTextField}
			required
		/>

		<Field
			name="phoneNumber"
			label="Phone Number"
			type="text"
			component={FormikTextField}
		/>
	</>;
});
