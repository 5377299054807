import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import moment from 'moment-timezone';

import { AssetSelector, Button, FixedWidthPage, FormikTextField, MessagePage, AssetGroupSelector, FormikTimePicker } from 'src/components';
import { runFormValidation } from 'src/util';

import {
	Client as C,
	AuthenticationService,
	HistoryService,
	ReportService,
	Service,
	ToasterService,
	useInjection,
	useAssetSelectorService,
	useAssetGroupSelectorService,
} from 'src/services';

interface CreateLocationReportFormValues {
	name: string;
	assets: string[];
	assetGroups: string[];
	date: string;
	time: string;
}

const validateForm = (values: CreateLocationReportFormValues, errors: FormikErrors<CreateLocationReportFormValues>) => {
	if (!values.name)
		errors.name = 'Report name is required.';

	if (values.assets.length === 0 && values.assetGroups.length === 0) {
		errors.assets = 'At least one asset or asset group is required.';
		errors.assetGroups = 'At least one asset or asset group is required.';
	}

	if (!values.date)
		errors.date = 'Report date is required.';

	if (!values.time)
		errors.time = 'Report time is required.';
};

export const CreateLocationReport = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _reportService = useInjection<ReportService>(Service.Report);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const _assetSelectorService = useAssetSelectorService();
	const _assetGroupSelectorService = useAssetGroupSelectorService(C.ListAssetGroupsType.Report);

	const onSubmit = async (values: CreateLocationReportFormValues, { setSubmitting }: FormikHelpers<CreateLocationReportFormValues>) => {
		const reportTimestamp = moment.tz(`${values.date} ${values.time}`, _authService.currentAuth.user.timeZone);

		try {
			const request: C.INewLocationReportRequest = {
				name: values.name,
				assetIds: values.assets,
				assetGroupIds: values.assetGroups,
				timestamp: reportTimestamp,
			};

			await _reportService.createLocationReport(request);
			_historyService.history.push('/app/reports');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to create report.');
			setSubmitting(false);
		}
	};

	const now = moment.tz(_authService.currentAuth.user.timeZone);

	return <FixedWidthPage
		className="form-page"
		headingText="Create Location Report"
		contentMessageReplace={_assetSelectorService.loading && <MessagePage loading />}
	>
		<Formik
			initialValues={{
				name: '',
				assets: [],
				assetGroups: [],
				date: now.format('YYYY-MM-DD'),
				time: now.format('HH:mm'),
			}}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<CreateLocationReportFormValues>) => <Form className="formik-form material">
				<Field
					name="name"
					label="Report Name"
					component={FormikTextField}
					required
				/>

				<AssetSelector
					options={_assetSelectorService.assetOptions}
					formikProps={formikProps}
				/>

				<AssetGroupSelector
					options={_assetGroupSelectorService.assetGroupOptions}
					formikProps={formikProps}
				/>

				<Field
					name="date"
					label="Report Date"
					type="date"
					component={FormikTextField}
					required
				/>

				<FormikTimePicker
					name="time"
					label="Report Time"
					form={formikProps}
					variant="filled"
					fullWidth={true}
					required
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					text="Create"
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
