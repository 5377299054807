import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import { FormikHelpers } from 'formik';
import moment from 'moment-timezone';

import { ErrorMessagePage, LoadingMessagePage  } from 'src/components';
import { IResolveEmergencyFormValues, EmergencyViewer, IEmergency } from 'src/components/emergency';

import { useQueryEmergencyForEmergencyDetailsById } from 'src/graphql/__generated__/queries/queryEmergencyForEmergencyDetailsById';

import {
	AuthenticationService,
	Client as C,
	EmergencyService,
	HistoryService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';
import { getPrettyName } from 'src/util';

export interface Props {
	match: match<{ id: string }>;
}

export const EmergencyDetails = observer((props: Props) => {
	const _authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const _emergencyService = useInjection<EmergencyService>(Service.Emergency);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const emergencyQuery = useQueryEmergencyForEmergencyDetailsById({
		variables: {
			id: props.match.params.id,
		}
	});

	if (emergencyQuery.loading)
		return <LoadingMessagePage />;

	if (emergencyQuery.error || !emergencyQuery.data?.emergencyById)
		return <ErrorMessagePage />;

	const emergency = emergencyQuery.data.emergencyById;

	const parsedEmergency: IEmergency = {
		status: emergency.status,
		type: emergency.type,
		generatedAt: emergency.generatedAt ? moment(emergency.generatedAt) : null,
		recordedAt: moment(emergency.recordedAt),
		safetyTimer: emergency.safetyTimer ? {startTimestamp: moment(emergency.safetyTimer.startTimestamp), details: emergency.safetyTimer.details} : null,
		actions: emergency.actions ? emergency.actions.map(x => ({performedAt: moment(x.performedAt), performedByUserName: x.user?.name})) : null,
		asset: {name: emergency.asset.name, emergencyNotes: emergency.asset.emergencyNotes},
		ioConfiguration: emergency.deviceIoConfiguration ? {name: emergency.deviceIoConfiguration.name, deviceName: emergency.deviceIoConfiguration.device.name} : null
	};

	const onSubmit = async (values: IResolveEmergencyFormValues, { setSubmitting }: FormikHelpers<IResolveEmergencyFormValues>) => {
		const request: C.IResolveEmergencyRequest = {
			notes: values.notes,
		};

		try {
			await _emergencyService.resolveEmergency(props.match.params.id, request);
			_historyService.history.push('/app/emergencies/list');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to resolve alert.');
			setSubmitting(false);
		}
	};

	return <EmergencyViewer
		headingText={getPrettyName(emergency.type)}
		emergency={parsedEmergency}
		timeZone={_authenticationService.currentAuth.user.timeZone}
		onSubmit={onSubmit}
	/>;
});
