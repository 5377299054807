import { Client as C } from 'src/services';
import { AssetBillingType } from 'src/../__generated__/globalTypes';
import { getPrettyName } from 'src/util';

const possibleBillingTypeOptions = [
	AssetBillingType.BASIC,
	AssetBillingType.CONFIGURATION_ONLY,
	AssetBillingType.DEVELOPMENT,
	AssetBillingType.ENTERPRISE_1,
	AssetBillingType.ENTERPRISE_2,
	AssetBillingType.ENTERPRISE_3,
	AssetBillingType.PREMIUM,
	AssetBillingType.STANDARD,
	AssetBillingType.TRIAL,
];

const shouldAddBillingTypeOption = (identity: C.IIdentityDto, billingType: AssetBillingType, assetCurrentBillingType: AssetBillingType | null) => {
	// Always give an option for the device's current billing type.
	if (billingType === assetCurrentBillingType)
		return true;

	switch (billingType) {
		case AssetBillingType.BASIC:
		case AssetBillingType.DEVELOPMENT:
		case AssetBillingType.ENTERPRISE_1:
		case AssetBillingType.ENTERPRISE_2:
		case AssetBillingType.ENTERPRISE_3:
			// Only superusers can set devices to demo/development.
			return identity.type === C.IdentityType.SuperUser;

		case AssetBillingType.TRIAL:
			if (identity.type === C.IdentityType.Dealer) {
				// Configuration only dealers can't set billing type to trial.
				if (identity.dealer!.type === C.DealerType.ConfigurationOnly)
					return false;

				if (identity.dealer!.maxTrialMonths === 0)
					return false;
			}

			// Can only set billing type to trial when creating a new device.
			return assetCurrentBillingType === null;

		case AssetBillingType.STANDARD:
			// Configuration only dealers can't use standard billing type.
			return identity.dealer?.type !== C.DealerType.ConfigurationOnly;

		case AssetBillingType.CONFIGURATION_ONLY:
			return identity.type === C.IdentityType.SuperUser || identity.dealer?.type === C.DealerType.ConfigurationOnly;
	}

	return false;
};

export const getBillingTypeOptions = (identity: C.IIdentityDto, assetCurrentBillingType: AssetBillingType | null) => {
	return possibleBillingTypeOptions
		.filter(option => shouldAddBillingTypeOption(identity, option, assetCurrentBillingType))
		.map(x => ({ value: x, label: getPrettyName(x) }));
};
