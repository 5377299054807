import React from 'react';
import { observer } from 'mobx-react';
import { FormikProps } from 'formik';

import { FormikSelect } from 'src/components';
import { Client as C, useIdentityType } from 'src/services';
import { IDeviceNetwork } from 'src/app/devices/manageDeviceComponent';
import { DeviceType } from 'src/../__generated__/globalTypes';

interface INetworkFormValues {
	dealerId: string | null;
}

interface IProps<T> {
	form: FormikProps<T>;
	deviceType: DeviceType;
	dealers?: {
		id: string;
		name: string;
	}[];
	networks?: IDeviceNetwork[];
}

export const NetworkSelector = observer(<T extends INetworkFormValues>(props: IProps<T>) => {
	const identityType = useIdentityType()!;

	const hasDealerSelection = identityType === C.IdentityType.SuperUser;

	let networkOptions = props.networks?.filter(x => {
		if (props.deviceType === DeviceType.HYTERA_RADIO && x.networkType === C.NetworkType.HyteraTier2)
			return true;

		if (props.deviceType === DeviceType.MOBILE_PHONE && x.networkType === C.NetworkType.ChatterPtt)
			return true;

		if (props.deviceType === DeviceType.TAIT_RADIO && (x.networkType === C.NetworkType.TaitTier2 || x.networkType === C.NetworkType.TaitTier3))
			return true;

		if (props.deviceType === DeviceType.TELTONIKA && x.networkType === C.NetworkType.Teltonika)
			return true;

		return false;
	});

	if (hasDealerSelection && networkOptions) {
		if (props.form.values.dealerId)
			networkOptions = networkOptions.filter(network => network.dealers && network.dealers.some( dealer => dealer.id === props.form.values.dealerId));
		else
			networkOptions = [];
	}

	return <>
		{networkOptions && <FormikSelect
			name="networkId"
			label="Site"
			form={props.form}
			options={networkOptions}
			getOptionValue={x => x.id}
			getOptionLabel={x => x.name}
			disabled={hasDealerSelection && !props.form.values.dealerId}
			clearable
		/>}
	</>;
});
