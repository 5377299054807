import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, Form, Field, FieldArray, ArrayHelpers, FormikErrors } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import useAsyncEffect from 'use-async-effect';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import Alert from '@material-ui/lab/Alert';

import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';

import { Button, FixedWidthPage, FormikTextField, FormikSelect, FormikCheckbox } from 'src/components';
import { runFormValidation, addFormArrayError, getPrettyName, IOption } from 'src/util';

import { executeQueryDealerList, QueryDealerList_dealers } from 'src/graphql/__generated__/queries/queryDealerList';

import {
	AddressBookService,
	AuthenticationService,
	Client as C,
	HistoryService,
	useInjection,
	Service,
	SiteService,
	ToasterService,
	ClientService,
} from 'src/services';

const addressBookNameStyles = css({
	marginBottom: '10px',
	maxWidth: '300px'
});

const logOffCheckBoxStyles = css({
	'label': {
		width: '400px',
		maxWidth: '400px'
	}
});

const AddressBookEntriesHeading = styled.h2({
	marginTop: '10px',
});

const StyledAddressBookDetailsDiv = styled.div({
	'div:not(:first-of-type)': {
		marginTop: '5px',
	},

	'div': {
		maxWidth: '300px',
	}
});

const StyledAddressBookEntryTable = styled.table({
	'td:not(:last-of-type)': {
		verticalAlign: 'top',
		width: '33%',
	},

	'tbody:last-of-type': {
		textAlign: 'right',
	},

	'.MuiFilledInput-input': {
		padding: '10px !important', // Makes sure there is no extra padding on inputs with no titles.
	},

	'.MuiFilledInput-multiline': {
		padding: '0px', // The notes field contains a .MuiFilledInput-input, so use the padding from that.
	},
});

interface Props {
	addressBook?: C.IAddressBookDto;
}

interface IAddressBookEntryFormValues {
	addressBookEntryId?: string;
	userName: string;
	userIdentifier: string;
	notes: string;
}

interface IAddressBookFormValues {
	name: string;
	logOffDevicesWithSameUserIdentifier: boolean;
	dealerId?: string | null;
	clientId: string;
	siteId?: string;
	type?: C.AddressBookType;
	addressBookEntries:IAddressBookEntryFormValues[];
}

interface IManageAddressBookPageData {
	dealers?: QueryDealerList_dealers[];
	clients: C.IClientDto[];
	sites: C.ISiteDto[];
}

export const ManageAddressBooks = observer((props: Props) => {
	const addressBookService = useInjection<AddressBookService>(Service.AddressBooks);
	const apolloClient = useInjection<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const clientsService = useInjection<ClientService>(Service.Client);
	const historyService = useInjection<HistoryService>(Service.History);
	const siteService = useInjection<SiteService>(Service.Site);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const [pageData, setPageData] = React.useState<IManageAddressBookPageData>({
		dealers: [],
		clients: [],
		sites: [],
	});

	const [selectedDealer, setSelectedDealer] = React.useState<string | undefined>(undefined);
	const [selectedSite, setSelectedSite] = React.useState<C.ISiteDto | undefined>(undefined);
	const [initialValues, setInitialValues] = React.useState<IAddressBookFormValues>(() => {
		return {
			name: props.addressBook?.name ?? '',
			logOffDevicesWithSameUserIdentifier: props.addressBook?.logOffDevicesWithSameUserIdentifier ?? true,
			clientId: props.addressBook?.clientId ?? '',
			siteId: props.addressBook?.siteId ?? '',
			type: props.addressBook?.type ?? undefined,
			addressBookEntries: props.addressBook?.addressBookEntries != null ? props.addressBook.addressBookEntries.map(x => ({
				addressBookEntryId: x.addressBookEntryId,
				userIdentifier: x.userIdentifier || '',
				userName: x.userName || '',
				notes: x.notes || '',
			})) : [],
		};
	});

	const identityType = authenticationService.currentAuth.user.identity.type;

	useAsyncEffect(async () => {
			let allDealers: QueryDealerList_dealers[] | undefined = [];
			let allClients: C.IClientDto[] | null = [];
			let allSites: C.ISiteDto[] | null = [];

			if (identityType === C.IdentityType.SuperUser) {
				try {
					const dealersListQuery = await executeQueryDealerList(apolloClient);

					if (dealersListQuery.error || !dealersListQuery.data)
						throw dealersListQuery.error || 'Failed to load dealers.';

					allDealers = dealersListQuery.data.dealers ?? undefined;

				} catch (err) {
					toasterService.handleWithToast(err, 'Failed to load dealers.');
				}
			}

			if (identityType !== C.IdentityType.Client) {
				allClients = await clientsService.getAllClients() ?? [];
				allSites = await siteService.getAllSites() ?? [];
				setPageData({
					dealers: allDealers,
					clients: allClients,
					sites: allSites,
				});

				if (initialValues.dealerId) {
					setSelectedDealer(initialValues.dealerId);
				} else if (!initialValues.dealerId && authenticationService.currentAuth.user.identity.type === C.IdentityType.Dealer) {
					setSelectedDealer(authenticationService.currentAuth.user.identity.dealerId!);
				}
			}

			if (!!props.addressBook && props.addressBook.siteId) {
				const site = allSites.find(x => x.siteId === props.addressBook!.siteId);
				if (site) {
					setSelectedSite(site);
				}
			}
	}, []);

	async function onSubmit(values: IAddressBookFormValues, { setSubmitting }: FormikHelpers<IAddressBookFormValues>) {
		setSubmitting(true);

		try {
			const addressBookEntries: C.IAddressBookEntryRequest[] = values.addressBookEntries.map(x => ({
				addressBookEntryId: x.addressBookEntryId,
				userIdentifier: x.userIdentifier!,
				userName: x.userName!,
				notes: x.notes,
			}));

			const selectedSiteIsTier3 = (selectedSite && selectedSite.networkType === C.NetworkType.TaitTier3);

			if (props.addressBook) {
				const updateRequest: C.IUpdateAddressBookRequest = {
					name: values.name !== initialValues.name ? values.name : null,
					logOffDevicesWithSameUserIdentifier: selectedSiteIsTier3 && values.logOffDevicesWithSameUserIdentifier !== initialValues.logOffDevicesWithSameUserIdentifier ? values.logOffDevicesWithSameUserIdentifier : null,
					addressBookEntries: addressBookEntries,
					siteId: values.siteId === '' ? null : values.siteId,
					unsetSite: values.siteId === '' && props?.addressBook?.siteId != null,
				};

				await addressBookService.updateAddressBookById(props.addressBook!.addressBookId, updateRequest);
			} else {
				const addRequest: C.IAddAddressBookRequest = {
					name: values.name,
					logOffDevicesWithSameUserIdentifier: selectedSiteIsTier3 ? values.logOffDevicesWithSameUserIdentifier : false,
					addressBookEntries: addressBookEntries,
					clientId: values.clientId,
					siteId: values.siteId!,
					type: values.type!,
				};

				await addressBookService.addAddressBook(addRequest);
			}

			toasterService.showSuccess(`Address book successfully ${props.addressBook ? 'updated' : 'added'}.`);
		} catch (err) {
			toasterService.handleWithToast(err, `Failed to ${props.addressBook ? 'update' : 'add'} address book${props.addressBook?.name ? ' ' + props.addressBook!.name : ''}.`);
		}

		historyService.history.push('/app/address-books/list');
		setSubmitting(false);
	}

	function addAddressBookEntryRow(arrayHelpers: ArrayHelpers) {
		const addressBookEntry: IAddressBookEntryFormValues = {
			userName: '',
			userIdentifier: '',
			notes: '',
		};

		arrayHelpers.push(addressBookEntry);
	}

	function removeAddressEntry(arrayHelpers: ArrayHelpers, index: number) {
		arrayHelpers.remove(index);
	}

	const validateForm = (values: IAddressBookFormValues, errors: FormikErrors<IAddressBookFormValues>) => {
		if (!values.name)
			errors.name = 'Name is required.';

		if (!values.siteId)
			errors.siteId = 'Site is required.';

		if (!values.type)
			errors.type = 'Type is required.';

		if ((identityType === C.IdentityType.Dealer || identityType === C.IdentityType.SuperUser) && !props.addressBook) {
			if (!values.clientId)
				errors.clientId = 'Client is required.';

			if (!values.dealerId && identityType === C.IdentityType.SuperUser)
				errors.dealerId = 'Dealer is required.';
		}

		let hasAddressBookEntriesError = false;
		const addressBookEntriesErrors: FormikErrors<IAddressBookEntryFormValues>[] = [];

		for (const entry of values.addressBookEntries) {
			const entryErrors = addFormArrayError(addressBookEntriesErrors);

			if (!entry.userIdentifier)
				entryErrors.userIdentifier = `${values.type ? getPrettyName(values.type) : ''} identifier is required.`;
			else if (values.addressBookEntries.filter(x => x.userIdentifier === entry.userIdentifier).length > 1)
				entryErrors.userIdentifier = `${values.type ? getPrettyName(values.type) : ''} identifiers must be unique.`;

			if (!entry.userName)
				entryErrors.userName = `${values.type ? getPrettyName(values.type) : ''} name is required`;

			if (entry.notes.length > 300)
				entryErrors.notes = 'Notes most not exceed 300 characters.';

			if (Object.entries(entryErrors).length > 0)
				hasAddressBookEntriesError = true;
		}

		if (hasAddressBookEntriesError)
			errors.addressBookEntries = addressBookEntriesErrors;
	};

	function handleDealerChange(dealer: QueryDealerList_dealers | QueryDealerList_dealers[] | null, formikProps: FormikProps<IAddressBookFormValues>) {
		if (dealer === null)
			return;

		const isArray = Array.isArray(dealer);
		if (isArray)
			return;

		const dealerDto = dealer as QueryDealerList_dealers;
		setSelectedDealer(dealerDto.id);
		formikProps.values.clientId = '';
		formikProps.values.siteId = '';
	}

	function handleSiteChange(site: C.ISiteDto | C.ISiteDto[] | null, formikProps: FormikProps<IAddressBookFormValues>) {
		if (site === null)
			return;

		const isArray = Array.isArray(site);
		if (isArray)
			return;

		const siteDto = site as C.ISiteDto;
		setSelectedSite(siteDto);
	}

	function renderAddressBookEntries(arrayHelpers: ArrayHelpers, formikProps: FormikProps<IAddressBookFormValues>, addressBookPageData: IManageAddressBookPageData) {
		const canAddAddressBooks = authenticationService.currentAuth.permissions.general.addAddressBooks;

		return	<StyledAddressBookEntryTable className="card-table">
			<thead>
				<tr>
					<th>{formikProps.values.type ? getPrettyName(formikProps.values.type) : ''} Identifier</th>
					<th>{formikProps.values.type ? getPrettyName(formikProps.values.type) : ''} Name</th>
					<th>Notes</th>
					{identityType !== C.IdentityType.Client && <th></th>}
				</tr>
			</thead>

			<tbody>
				{formikProps.values.addressBookEntries.length === 0 && <tr
					key="no-address-book-entires"
					className="content-box"
				>
					<td
						colSpan={4}
						align="center"
					>
						None
					</td>
				</tr>}

				{formikProps.values.addressBookEntries.map((_, index) => renderAddressBookEntryRow(index, arrayHelpers, formikProps, addressBookPageData))}

				{canAddAddressBooks && <tr
					key="add-address-books"
				>
					<td
						colSpan={4}
					>
						<Button
							onClick={() => addAddressBookEntryRow(arrayHelpers)}
							text="Add"
							startIcon={<AddIcon />}
							variant="outlined"
							color="primary"
						/>
					</td>
				</tr>}
			</tbody>
		</StyledAddressBookEntryTable>;
	}

	function renderAddressBookEntryRow(index: number, arrayHelpers: ArrayHelpers, formikProps: FormikProps<IAddressBookFormValues>, addressBookPageData: IManageAddressBookPageData): JSX.Element | undefined {
		return <tr
			key={index}
			className="content-box"
		>
			<td>
				<Field
					name={`addressBookEntries[${index}].userIdentifier`}
					className="input-field"
					component={FormikTextField}
					disabled={identityType === C.IdentityType.Client}
				/>
			</td>

			<td>
				<Field
					name={`addressBookEntries[${index}].userName`}
					className="input-field"
					component={FormikTextField}
				/>
			</td>

			<td>
				<Field
					name={`addressBookEntries[${index}].notes`}
					type="text"
					component={FormikTextField}
					multiline
				/>
			</td>

			{identityType !== C.IdentityType.Client && <td>
				<IconButton
					className="icon-button-small"
					title="Remove Address Book Entry"
					onClick={() => removeAddressEntry(arrayHelpers, index)}
				>
					<ClearIcon />
				</IconButton>
			</td>}
		</tr>;
	}

	function getAddressBookTypes(): IOption<C.AddressBookType>[] {
		const addressBookTypes: IOption<C.AddressBookType>[] = [];

		addressBookTypes.push({label: getPrettyName(C.AddressBookType.Attachment), value: C.AddressBookType.Attachment});
		addressBookTypes.push({label: getPrettyName(C.AddressBookType.User), value: C.AddressBookType.User});

		return addressBookTypes;
	}

	return <Formik
			initialValues={initialValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<IAddressBookFormValues>) => <Form className="formik-form">
				<FixedWidthPage
					headingText={ `${props.addressBook ? 'Edit' : 'Add'} ${formikProps.values.type ? getPrettyName(formikProps.values.type) : ''} Address Book`}
					pageItemId={props.addressBook?.addressBookId}
					noContentBackground
					headingActions={<Button
						type="submit"
						variant="contained"
						color="primary"
						startIcon={<SaveIcon />}
						text={'Save all Changes'}
						loading={formikProps.isSubmitting}
					/>}
				>
					<StyledAddressBookDetailsDiv className="content-box">
						<Field
							inputProps={{
								className: addressBookNameStyles,
							}}
							name="name"
							label="Address Book Name"
							type="text"
							component={FormikTextField}
							disabled={identityType === C.IdentityType.Client}
						/>


						{!props.addressBook && identityType === C.IdentityType.SuperUser && pageData.dealers && <FormikSelect
							name="dealerId"
							label="Dealer"
							placeholder="Select a dealer..."
							clearable={false}
							options={pageData.dealers!}
							form={formikProps}
							disabled={!!props.addressBook}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.id}
							onChange={(dealer) => handleDealerChange(dealer, formikProps)}
						/>}

						{!props.addressBook && identityType !== C.IdentityType.Client && <>
							<FormikSelect
								className="input-field"
								label="Client"
								placeholder= "Select a client..."
								name="clientId"
								options={selectedDealer ? pageData.clients.filter(x => x.dealerId == selectedDealer) : []}
								clearable={false}
								form={formikProps}
								disabled={!!props.addressBook}
								getOptionLabel={option => option.name}
								getOptionValue={option => option.clientId}
							/>

							<FormikSelect
								className="input-field"
								label="Site"
								placeholder= "Select a site..."
								name="siteId"
								options={pageData.sites.filter(x => selectedDealer && x.dealerIds.includes(selectedDealer))}
								clearable={false}
								form={formikProps}
								disabled={!!props.addressBook}
								getOptionLabel={option => option.name}
								getOptionValue={option => option.siteId}
								onChange={(site) => handleSiteChange(site, formikProps)}
							/>

							<FormikSelect
								className="input-field"
								label="Type"
								placeholder= "Select a type..."
								name="type"
								options={getAddressBookTypes()}
								clearable={false}
								form={formikProps}
								disabled={!!props.addressBook}
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
							/>
						</>}

						{identityType !== C.IdentityType.Client && selectedSite && selectedSite.networkType === C.NetworkType.TaitTier3 &&
						<div className={logOffCheckBoxStyles}>
							<Field
								name="logOffDevicesWithSameUserIdentifier"
								label="Log off devices with identical user identifiers"
								type="checkbox"
								component={FormikCheckbox}
							/>
							<Alert severity="info">Not supported in all scenarios. Please check with your dealer/account manager to confirm functionality.</Alert>
						</div>}
					</StyledAddressBookDetailsDiv>

					<AddressBookEntriesHeading>Address Book Entries</AddressBookEntriesHeading>
					<FieldArray
						name="addressBookEntries"
						render={arrayHelpers => <>
							{renderAddressBookEntries(arrayHelpers, formikProps, pageData)}
						</>}
					/>
				</FixedWidthPage>
			</Form>}
		/>;
});
