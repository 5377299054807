import React from 'react';
import { useRef } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { styled } from '@material-ui/core/styles';

import BellIcon from '@material-ui/icons/Notifications';

import { FixedWidthPage, MaterialTable, PopoverMenu, PopoverMenuItem, Button } from 'src/components';
import { AssetServiceRemindersStateBubblesDisplay } from 'src/components/assetServiceRemindersStateBubblesDisplay';
import { ErrorMessagePage, LoadingMessagePage } from 'src/components';
import { getPrettyName } from 'src/util';

import { useQueryAssetsForVehicleInformationList, QueryAssetsForVehicleInformationList_assets_assetTelematicsCurrentValueAndReadings } from 'src/graphql/__generated__/queries/queryAssetsForVehicleInformationList';
import { AssetServiceReminderState, AssetTelematicsType } from 'src/../__generated__/globalTypes';

import {
	AuthenticationService,
	DistanceService,
	DistanceUnit,
	useInjection,
	Service,
} from 'src/services';

const HideAssetsWithNoReadingsLabel = styled(FormControlLabel)({
	color: '#4c4b4b',
});

const assetServiceReminderStatesLookupValues: { [key: string]: string } = {
	[AssetServiceReminderState.NOT_EXPIRED] : getPrettyName(AssetServiceReminderState.NOT_EXPIRED),
	[AssetServiceReminderState.WITHIN_REMINDER_PERIOD]: getPrettyName(AssetServiceReminderState.WITHIN_REMINDER_PERIOD),
	[AssetServiceReminderState.EXPIRED]: getPrettyName(AssetServiceReminderState.EXPIRED),
};

interface CurrentTelematicsAndServiceRemindersValues {
	assetId: string;
	assetName: string;
	currentTelematicsValues: QueryAssetsForVehicleInformationList_assets_assetTelematicsCurrentValueAndReadings[];
	notExpiredAssetServiceRemindersCount: number;
	withinExpiryAssetServiceRemindersCount: number;
	expiredAssetServiceRemindersCount: number;
}

export const VehicleInformationList = observer(() => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const distanceService = useInjection<DistanceService>(Service.DistanceService);

	const [hideAssetsWithNoInformation, setHideAssetsWithNoInformation] = React.useState<boolean>(false);
	const [assetsCurrentValues, setAssetsCurrentValues] = React.useState<CurrentTelematicsAndServiceRemindersValues[] | undefined>(undefined);
	const allCurrentTelematicsAndServiceRemindersValues = useRef<CurrentTelematicsAndServiceRemindersValues[] | undefined> (undefined);

	const assetsQuery = useQueryAssetsForVehicleInformationList({});

	if (assetsQuery.loading)
		return <LoadingMessagePage />;

	if (assetsQuery.error || !assetsQuery.data?.assets)
		return <ErrorMessagePage />;

	let rowData: CurrentTelematicsAndServiceRemindersValues[] = [];
	assetsQuery.data.assets.forEach(x => {
		const serviceReminders = x.serviceReminders ?? [];
		rowData.push({
			assetId: x.id,
			assetName: x.name,
			currentTelematicsValues: x.assetTelematicsCurrentValueAndReadings ?? [],
			notExpiredAssetServiceRemindersCount: serviceReminders.filter(x => x.state === AssetServiceReminderState.NOT_EXPIRED).length,
			withinExpiryAssetServiceRemindersCount: serviceReminders.filter(x => x.state === AssetServiceReminderState.WITHIN_REMINDER_PERIOD).length,
			expiredAssetServiceRemindersCount: serviceReminders.filter(x => x.state === AssetServiceReminderState.EXPIRED).length,
		});
	});

	// SortBy odo and engine time, then sort by odo, then sort by engine time.
	rowData = sortBy(rowData, x => (getTelematicsValue(x, AssetTelematicsType.ODOMETER), getTelematicsValue(x, AssetTelematicsType.ENGINE_TIME)),  y => getTelematicsValue(y, AssetTelematicsType.ODOMETER), z => getTelematicsValue(z, AssetTelematicsType.ENGINE_TIME));

	if (!assetsCurrentValues) {
		setAssetsCurrentValues(rowData);
		allCurrentTelematicsAndServiceRemindersValues.current = rowData;
	}

	function getTelematicsValue(value: CurrentTelematicsAndServiceRemindersValues, telematicsType: AssetTelematicsType) {
		return value.currentTelematicsValues.find(y => y.type === telematicsType);
	}

	function hideAssetWithNoReadingsChange() {
		const currentCheckBoxValue = !hideAssetsWithNoInformation;

		if (allCurrentTelematicsAndServiceRemindersValues.current) {
			if (currentCheckBoxValue)
				setAssetsCurrentValues(allCurrentTelematicsAndServiceRemindersValues.current.filter(x => !!getTelematicsValue(x, AssetTelematicsType.ODOMETER) || !!getTelematicsValue(x, AssetTelematicsType.ENGINE_TIME)));
			else
				setAssetsCurrentValues(allCurrentTelematicsAndServiceRemindersValues.current);
		}

		setHideAssetsWithNoInformation(currentCheckBoxValue);
	}

	return <FixedWidthPage
		headingText="Vehicle Information"
		headingActions={<Button href="/app/asset-service-reminder-types/list" text="Service Reminders" startIcon={<BellIcon />} variant="outlined" color="primary"/>}
		noContentBackground
		contentClassName={classNames({ 'flex-fill-no-overflow': true })}
	>
		<MaterialTable
			tableName="vehicle-information-list"
			columns={[
				{
					title: 'Name',
					field: 'assetName',
					grouping: false,
					width: '25%',
					cellStyle: () => ({
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}),
				},
				{
					title: 'Odometer Distance',
					field: 'odometerDistance',
					grouping: false,
					filtering: false,
					sorting: false,
					render: rowData => {
						const odoValue = rowData.currentTelematicsValues.find(x => x.type === AssetTelematicsType.ODOMETER);
						return <>{!odoValue ? 'No Reading Entered' : distanceService.formatDistance(odoValue.value!, authenticationService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles, true)}</>;
					},
				},
				{
					title: 'Engine Hours',
					field: 'engineTime',
					grouping: false,
					filtering: false,
					sorting: false,
					render: rowData => {
						const engineTime = rowData.currentTelematicsValues.find(x => x.type === AssetTelematicsType.ENGINE_TIME);
						return <>{!engineTime ? 'No Reading Entered' : `${(engineTime.value! / 60).toFixed(1)} hrs`}</>;
					},
				},
				{
					title: 'Service Reminders',
					field: 'serviceReminders',
					grouping: false,
					filtering: true,
					sorting: true,
					lookup: assetServiceReminderStatesLookupValues,
					customFilterAndSearch: (selectedLookUpOptions: string[], rowData: CurrentTelematicsAndServiceRemindersValues) => {
						if (selectedLookUpOptions.length == 0)
							return true;

						let showValue = false;
						if (selectedLookUpOptions.includes(AssetServiceReminderState.NOT_EXPIRED) && rowData.notExpiredAssetServiceRemindersCount != undefined && rowData.notExpiredAssetServiceRemindersCount > 0)
							showValue = true;

						if (selectedLookUpOptions.includes(AssetServiceReminderState.WITHIN_REMINDER_PERIOD) && rowData.withinExpiryAssetServiceRemindersCount != undefined && rowData.withinExpiryAssetServiceRemindersCount > 0)
							showValue = true;

						if (selectedLookUpOptions.includes(AssetServiceReminderState.EXPIRED) && rowData.expiredAssetServiceRemindersCount != undefined && rowData.expiredAssetServiceRemindersCount > 0)
							showValue = true;

						return showValue;
					},
					render: rowData => {
						return <AssetServiceRemindersStateBubblesDisplay
							totalNotExpiredReminders={rowData.notExpiredAssetServiceRemindersCount}
							totalRemindersAboutToExpire={rowData.withinExpiryAssetServiceRemindersCount}
							totalExpiredReminders={rowData.expiredAssetServiceRemindersCount}
							href={`/app/vehicle-information/${rowData.assetId}`}
							size="small"
						/>;
					},
				},
				{
					title: 'Options',
					grouping: false,
					filtering: false,
					sorting: false,
					headerStyle: ({
						textAlign: 'right',
					}),
					cellStyle: () => ({
						textAlign: 'right',
					}),
					render: rowData => <PopoverMenu
						renderOptions={() => [
							<PopoverMenuItem
								key="vehicle-information"
								text="View vehicle information"
								href={`/app/vehicle-information/${rowData.assetId}`}
								title="View vehicle information"
							/>,
							<PopoverMenuItem
								key="add-service-reminder-for-asset"
								text="Add service reminder"
								href={`/app/assets/${rowData.assetId}/service-reminders/add`}
								title="Add asset service reminder for an asset"
							/>,
						]}
					/>,
					width: '10%',
				},
			]}
			data={assetsCurrentValues ?? []}
			options={{
				grouping: false,
				search: false,
			}}
			components={{
				Actions: () => <HideAssetsWithNoReadingsLabel
					control={<Checkbox
						checked={hideAssetsWithNoInformation}
						onClick={hideAssetWithNoReadingsChange}
					/>}
					label="Hide assets with no readings"
				/>
			}}
		/>
	</FixedWidthPage>;
});
