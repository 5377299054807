import { Container, interfaces } from 'inversify';
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import 'reflect-metadata';

import { baseUrl, tileServerBaseUrl } from 'src/config';

import { Service } from './service';
import { GatewayService } from './gatewayService';
import { Client, IdentityType, IUserDto, IGeneralIdentityPermissions } from './client';
import { AddressBookService } from './addressBookService';
import { AuthenticationService } from './authenticationService';
import { AssetService } from './assetService';
import { DeviceConfigurationService } from './deviceConfigurationService';
import { AssetTypesService } from './assetTypeService';
import { AssetGroupService } from './assetGroupService';
import { AssetEventsService } from './assetEventsService';
import { BeaconsService } from './beaconsService';
import { BrandingService } from './brandingService';
import { CallService } from './callService';
import { CallGroupsService } from './callGroupsService';
import { ClientService } from './clientService';
import { EmergencyService } from './emergencyService';
import { EnableFleetConfigurationService } from './enableFleetConfigurationService';
import { FloorPlansService } from './floorPlansService';
import { GatewayLogsService } from './gatewayLogsService';
import { GeofenceService } from './geofenceService';
import { LinkedDeviceService } from './linkedDeviceService';
import { SafetyTimerService } from './safetyTimerService';
import { TelematicsService } from './telematicsService';
import { PermissionManagementService } from './permissionManagementService';
import { PermissionsService } from './permissionsService';
import { ReportService } from './reportService';
import { UserGroupService } from './userGroupService';
import { SiteService } from './siteService';
import { SoundService } from './soundService';
import { TileServerClient } from './tileServerClient';
import { UsersService } from './usersService';
import { TableDataService } from './tableDataService';
import { WebSocketService } from './webSocketService';
import { MapDataService } from './mapDataService';

import { CefSharpService } from './cefSharpService';
import { DistanceService } from './distanceService';
import { FloorLevelConverter } from './floorLevelConverter';
import { ToasterService } from './toasterService';
import { HistoryService } from './history';

// Create a httpLink that works for file uploads using apollo client.
const httpLink = createUploadLink({
	uri: baseUrl + '/graphql',
	credentials: 'include',
	headers: {
		'GraphQL-Preflight': 1,
	},
}) as unknown as ApolloLink;

const apolloClient = new ApolloClient({
	link: httpLink,
	cache: new InMemoryCache({
		typePolicies: {
			Instant: {

			},
		},
	}),
	defaultOptions: {
		query: {
			fetchPolicy: 'no-cache',
		},
		watchQuery: {
			fetchPolicy: 'no-cache',
		},
	},
});

const myContainer = new Container();

myContainer.bind(Service.ApolloClient).toConstantValue(apolloClient);
myContainer.bind(Service.Gateway).to(GatewayService).inSingletonScope();
myContainer.bind(Service.AddressBooks).to(AddressBookService).inSingletonScope();
myContainer.bind(Service.ApiClient).toConstantValue(new Client(baseUrl));
myContainer.bind(Service.Authentication).to(AuthenticationService).inSingletonScope();
myContainer.bind(Service.Asset).to(AssetService).inSingletonScope();
myContainer.bind(Service.DeviceConfiguration).to(DeviceConfigurationService).inSingletonScope();
myContainer.bind(Service.AssetType).to(AssetTypesService).inSingletonScope();
myContainer.bind(Service.AssetEvents).to(AssetEventsService).inSingletonScope();
myContainer.bind(Service.AssetGroup).to(AssetGroupService).inSingletonScope();
myContainer.bind(Service.Beacons).to(BeaconsService).inSingletonScope();
myContainer.bind(Service.Branding).to(BrandingService).inSingletonScope();
myContainer.bind(Service.Call).to(CallService).inSingletonScope();
myContainer.bind(Service.CallGroup).to(CallGroupsService).inSingletonScope();
myContainer.bind(Service.CefSharp).to(CefSharpService).inSingletonScope();
myContainer.bind(Service.Client).to(ClientService).inSingletonScope();
myContainer.bind(Service.DistanceService).to(DistanceService).inTransientScope();
myContainer.bind(Service.Emergency).to(EmergencyService).inSingletonScope();
myContainer.bind(Service.EnableFleetConfiguration).to(EnableFleetConfigurationService).inSingletonScope();
myContainer.bind(Service.FloorLevelConverter).to(FloorLevelConverter).inSingletonScope();
myContainer.bind(Service.FloorPlans).to(FloorPlansService).inSingletonScope();
myContainer.bind(Service.GatewayLogs).to(GatewayLogsService).inSingletonScope();
myContainer.bind(Service.Geofence).to(GeofenceService).inSingletonScope();
myContainer.bind(Service.History).to(HistoryService).inSingletonScope();
myContainer.bind(Service.LinkedDevice).to(LinkedDeviceService).inSingletonScope();
myContainer.bind(Service.PermissionManagement).to(PermissionManagementService).inSingletonScope();
myContainer.bind(Service.Permissions).to(PermissionsService).inSingletonScope();
myContainer.bind(Service.Report).to(ReportService).inSingletonScope();
myContainer.bind(Service.UserGroup).to(UserGroupService).inSingletonScope();
myContainer.bind(Service.Site).to(SiteService).inSingletonScope();
myContainer.bind(Service.Sound).to(SoundService).inSingletonScope();
myContainer.bind(Service.TileServerApiClient).toConstantValue(new TileServerClient(tileServerBaseUrl));
myContainer.bind(Service.Toaster).to(ToasterService).inSingletonScope();
myContainer.bind(Service.Users).to(UsersService).inSingletonScope();
myContainer.bind(Service.TableDataService).to(TableDataService).inSingletonScope();
myContainer.bind(Service.Telematics).to(TelematicsService).inSingletonScope();
myContainer.bind(Service.WebSocket).to(WebSocketService).inSingletonScope();
myContainer.bind(Service.MapData).to(MapDataService).inSingletonScope();
myContainer.bind(Service.SafetyTimer).to(SafetyTimerService).inSingletonScope();

function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>) {
	return myContainer.get<T>(identifier);
}

const useCurrentUser = (): (IUserDto | undefined) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	return authenticationService.currentAuth?.user || undefined;
};

const useIdentityType = (): (IdentityType | undefined) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	return authenticationService.currentAuth?.user?.identity?.type || undefined;
};

const usePermissions = (): (IGeneralIdentityPermissions | undefined) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	return authenticationService.currentAuth?.permissions.general || undefined;
};

export {
	myContainer as Container,
	useInjection,
	useCurrentUser,
	usePermissions,
	useIdentityType,
};
