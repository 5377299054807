import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';

import { useQueryEditAsset } from 'src/graphql/__generated__/queries/queryEditAsset';
import { useMutationUpdateAsset } from 'src/graphql/__generated__/mutations/mutationUpdateAsset';
import { ManageAssetComponent, ManageAssetFormValues } from './manageAssetComponent';

import {
	Client as C,
	Service,
	useIdentityType,
	useInjection,
	ToasterService,
	useCurrentUser,
} from 'src/services';

import { ErrorMessagePage, LoadingMessagePage } from 'src/components';
import { undefinedIfUnchanged } from 'src/util';

export interface Props {
	match: match<{
		id: string,
	}>;
}

export const EditAsset = observer((props: Props) => {
	const currentUser = useCurrentUser()!;
	const identityType = useIdentityType();
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const query = useQueryEditAsset({
		variables: {
			id: props.match.params.id,
			includeDealers: identityType === C.IdentityType.SuperUser,
			includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		},
	});

	const [updateAsset] = useMutationUpdateAsset();

	if (query.loading)
		return <LoadingMessagePage />;

	if (query.error || !query.data?.asset)
		return <ErrorMessagePage />;

	const asset = query.data.asset;

	const submit = async (values: ManageAssetFormValues) => {
		let speedLimit: number | null = null;
		if (values.speedLimitEnabled && values.speedLimit) {
			speedLimit = values.speedLimit;

			if (!currentUser.usesMetric)
				speedLimit = Math.round(speedLimit * 1.60934);
		}

		try {
			await updateAsset({
				variables: {
					input: {
						assetId: asset.id,
						name: undefinedIfUnchanged(asset.name, values.name),
						dealerId: undefinedIfUnchanged(asset.dealer?.id, values.dealerId),
						clientId: undefinedIfUnchanged(asset.client?.id, values.clientId),
						assetTypeId: undefinedIfUnchanged(asset.assetType?.id, values.assetTypeId),
						speedLimit: undefinedIfUnchanged(asset.speedLimit, speedLimit),
						emergencyNotes: undefinedIfUnchanged(asset.emergencyNotes, values.emergencyNotes),
						billingType: undefinedIfUnchanged(asset.billingType, values.billingType),
						trialPeriodEndTimestamp: undefinedIfUnchanged(asset.trialPeriodEndTimestamp, values.trialPeriodEndTimestamp),
					},
				},
			});

			toasterService.showSuccess('Saved changes.');
			await query.refetch();
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to save changes.');
		}
	};

	return <ManageAssetComponent
		asset={asset}
		assetTypes={query.data.assetTypes || undefined}
		dealers={query.data.dealers || undefined}
		clients={query.data.clients || undefined}
		submit={submit}
	/>;
});
