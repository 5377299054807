import React, { useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import ApartmentIcon from '@material-ui/icons/Apartment';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import MenuIcon from '@material-ui/icons/Menu';
import PublicIcon from '@material-ui/icons/Public';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutline';
import WarningIcon from '@material-ui/icons/Warning';
import SearchIcon from '@material-ui/icons/Search';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import TimerIcon from '@material-ui/icons/Timer';

import {
	Service,
	Client as C,
	FloorPlansService,
	HistoryService,
	TileServerClient,
	useInjection,
} from 'src/services';

import { CustomTrailerIcon } from '../customIcons';
import { LiveMapAssetFilterDialog, LiveMapFilter } from './liveMapAssetFilterDialog';
import { LiveMapSidebarManager, SidebarAsset } from './liveMapSidebarManager';
import { SelectFloorPlanDialog } from './selectFloorPlanDialog';
import { Button } from 'src/components';
import { LiveMapAssetState } from './liveMapAssetState';
import { AssetServiceRemindersStateBubblesDisplay } from 'src/components/assetServiceRemindersStateBubblesDisplay';

import { AssetServiceReminderState } from 'src/../__generated__/globalTypes';

export enum MapDataSearchState {
	Idle = 1,
	Searching = 2,
	Error = 3,
}

interface Props {
	liveMapSidebarManager: LiveMapSidebarManager;
	assetStates: Map<string, LiveMapAssetState>;
	assetTypes: C.IAssetTypeDto[];
	setSelectedAssetId: (assetId: string | undefined) => void;
	selectFloorPlan: (floorPlan: C.IFloorPlanDto) => void;
	selectFloorPlanGroup: (floorPlanGroup: C.IFloorPlanGroupDto) => void;
	floorPlan?: C.IFloorPlanDto;
	floorPlanGroup?: C.IFloorPlanGroupDto;
	clearFloorPlan: () => void;
	clearFloorPlanGroup: () => void;
	changeFloorPlanView: () => void;
	canChangeFloorPlan: boolean;
	assetGroups: C.IAssetGroupDto[];
	canSearchMapData: boolean;
	mapDataSearchQuery: string | null;
	onChangeMapDataSearchQuery: (term: string | null) => void;
	mapDataSearchState: MapDataSearchState;
	mapDataSearchResults: TileServerClient.ISearchResponseDto[];
	onClickMapDataSearchFeature: (searchFeature: TileServerClient.ISearchResponseDto) => void;
	filterOrSearchUpdateCallBack: () => void;
}

const floorPlanButtonTheme = createTheme({
	palette: {
		primary: {
			main: '#1e88e5',
		},
		secondary: {
			main: '#f44336',
		},
	},
});

export const CurrentMapSidebar = observer((props: Props) => {
	const _floorPlanService = useInjection<FloorPlansService>(Service.FloorPlans);

	const [selectFloorPlanDialogOpen, setSelectFloorPlanDialogOpen] = useState<boolean>(false);
	const [filterAssetsDialogOpen, setFilterAssetsDialogOpen] = useState<boolean>(false);

	const onClickAsset = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const assetId = event.currentTarget.dataset['assetId'];
		props.setSelectedAssetId(assetId);
	};

	const setFloorPlan = (floorPlan: C.IFloorPlanDto) => {
		props.selectFloorPlan(floorPlan);
		setSelectFloorPlanDialogOpen(false);
	};

	const selectFloorPlanGroup = (floorPlanGroup: C.IFloorPlanGroupDto) => {
		props.selectFloorPlanGroup(floorPlanGroup);
		setSelectFloorPlanDialogOpen(false);
	};

	const filterOrSearchUpdated = () => {
		props.liveMapSidebarManager.updateAssetStatesOnFilterOrSearchUpdate();
		props.filterOrSearchUpdateCallBack();
	};

	const onChangeAssetSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
		props.liveMapSidebarManager.assetSearchQuery = event.target.value || null;
		filterOrSearchUpdated();
	};

	const onClearAssetSearchQuery = () => {
		props.liveMapSidebarManager.assetSearchQuery = null;
		filterOrSearchUpdated();
	};

	const onApplyFilter = (filter?: LiveMapFilter) => {
		props.liveMapSidebarManager.liveMapFilter = filter;
		filterOrSearchUpdated();
	};

	const renderAsset = (sidebarAsset: SidebarAsset): JSX.Element => {
		return <div
			key={sidebarAsset.asset.assetId}
			data-asset-id={sidebarAsset.asset.assetId}
			className={classNames('sidebar-item asset-item', { selected: sidebarAsset.selected })}
			onClick={onClickAsset}
		>
			<div className="status">
				{sidebarAsset.safetyTimer && <span className="safety-timer" title="Asset has an active safety timer.">
					<TimerIcon />
				</span>}

				<div
					className={classNames('status-indicator', sidebarAsset.status)}
					title={sidebarAsset.statusText}
				/>
			</div>

			<div className="asset-info">
				<div>{sidebarAsset.asset.name}</div>
				{sidebarAsset.userName && <div className="current-username"><PersonOutlinedIcon /><span>&nbsp;{sidebarAsset.userName}</span></div>}
				{sidebarAsset.attachmentName && <div className="current-username"><CustomTrailerIcon /><span>&nbsp;{sidebarAsset.attachmentName}</span></div>}
				{sidebarAsset.locationText && <div className="current-location" title={sidebarAsset.locationText}>{sidebarAsset.locationText}</div>}
			</div>
		</div>;
	};

	const renderSearchResults = (layerSearchResult: TileServerClient.ISearchResponseDto): JSX.Element => {
		return <div
			key={layerSearchResult.featureId}
			className={classNames('sidebar-item asset-item', { selected: false })}
			onClick={() => props.onClickMapDataSearchFeature(layerSearchResult)}
		>

			<div className="asset-info">
				<div>{layerSearchResult.featureName}</div>
				<div>{layerSearchResult.featureId}</div>
			</div>
		</div>;
	};

	const groupForFloorPlan = props.floorPlan && _floorPlanService.floorPlanGroups.get(props.floorPlan.parentGroupId);

	const assetServiceReminders = props.liveMapSidebarManager.assetServiceReminders;
	const remindersWithinPeriod = assetServiceReminders.filter(x => x.state == AssetServiceReminderState.WITHIN_REMINDER_PERIOD).length;
	const remindersExpired = assetServiceReminders.filter(x => x.state == AssetServiceReminderState.EXPIRED).length;

	return <div className="map-sidebar__content">
		{_floorPlanService.collections.size > 0 && <>
			<div className="map-sidebar__content__heading">
				Floor Plan
			</div>

			<div className="map-sidebar__floor-plan">
				<div className={classNames('map-sidebar__floor-plan__current', { 'map-sidebar__floor-plan__current--has-floorplan': !!props.floorPlan || !!props.floorPlanGroup })}>
					<MuiThemeProvider theme={floorPlanButtonTheme}>
						{!props.floorPlan && !props.floorPlanGroup && <>
							None
							<Button text={<MenuIcon fontSize="small" />} color="primary" title="Select floor plan" variant="outlined" onClick={() => setSelectFloorPlanDialogOpen(true)} disabled={!props.canChangeFloorPlan} />
						</>}

						{props.floorPlan && !props.floorPlanGroup && <>
							<div>{props.floorPlan.name}</div>
							<div className="material-button-group">
								<Button text={<PublicIcon fontSize="small" />} color="primary" title="Change view" variant="outlined" onClick={props.changeFloorPlanView} />
								{groupForFloorPlan && groupForFloorPlan.imageUrl &&
									<Button text={<ApartmentIcon fontSize="small" />} color="primary" title="View building" variant="outlined" onClick={() => props.selectFloorPlanGroup(groupForFloorPlan)} />}
								<Button text={<SwapHorizIcon fontSize="small" />} color="primary" title="Change floor plan" variant="outlined" onClick={() => setSelectFloorPlanDialogOpen(true)} />
								<Button text={<ClearIcon fontSize="small" />} color="secondary" title="Exit floor plan" variant="outlined" onClick={props.clearFloorPlan} />
							</div>
						</>}

						{props.floorPlanGroup && <>
							<div>{props.floorPlanGroup.name}</div>
							<div className="material-button-group">
								<Button text={<SwapHorizIcon fontSize="small" />} color="primary" title="Change view" variant="outlined" onClick={() => setSelectFloorPlanDialogOpen(true)} />
								<Button text={<ClearIcon fontSize="small" />} color="secondary" title="Exit building view" variant="outlined" onClick={props.clearFloorPlanGroup} />
							</div>
						</>}
					</MuiThemeProvider>
				</div>

				{selectFloorPlanDialogOpen && <SelectFloorPlanDialog
					setFloorPlan={setFloorPlan}
					selectFloorPlanGroup={selectFloorPlanGroup}
					close={() => setSelectFloorPlanDialogOpen(false)}
				/>}
			</div>
		</>}

		{props.canSearchMapData && <>
			<div className="map-sidebar__content__heading">
				<div>Map</div>
				{props.mapDataSearchState === MapDataSearchState.Searching && <CircularProgress size={15}></CircularProgress>}
				{props.mapDataSearchState === MapDataSearchState.Error && <WarningIcon/>}
			</div>

			<div className="map-sidebar__content__search sidebar-item">
				<SearchIcon className="icon-search"/>

				<input
					type="text"
					value={props.mapDataSearchQuery || ''}
					onChange={e => props.onChangeMapDataSearchQuery(e.target.value || null)}
					className="search-box"
					placeholder="Map search..."
				/>

				{props.mapDataSearchQuery && <ClearIcon fontSize="small" onClick={() => props.onChangeMapDataSearchQuery(null)} className="icon-clear" />}
			</div>

			{props.mapDataSearchResults.map(renderSearchResults)}
		</>}

		<div className="map-sidebar__content__heading"
			style={{ cursor: 'pointer' }}
			onClick={() => setFilterAssetsDialogOpen(true)}
		>
			<div>Assets</div>

			{(remindersWithinPeriod > 0 || remindersExpired > 0) && <AssetServiceRemindersStateBubblesDisplay
				totalRemindersAboutToExpire={remindersWithinPeriod}
				totalExpiredReminders={remindersExpired}
				href="/app/vehicle-information/list"
				whiteBackgroundColour={true}
				size="small"
			/>}

			<FilterListIcon className={classNames({ 'filter-active': !!props.liveMapSidebarManager.liveMapFilter })} />
		</div>

		<div className="map-sidebar__content__search sidebar-item">
			<SearchIcon className="icon-search" />

			<input
				type="text"
				value={props.liveMapSidebarManager.assetSearchQuery || ''}
				onChange={onChangeAssetSearchQuery}
				className="search-box"
				placeholder="Search..."
			/>

			{props.liveMapSidebarManager.assetSearchQuery && <ClearIcon fontSize={'small'} onClick={onClearAssetSearchQuery} className="icon-clear" />}
		</div>

		{props.liveMapSidebarManager.sidebarAssets.map(renderAsset)}

		{filterAssetsDialogOpen && <LiveMapAssetFilterDialog
			onClose={() => setFilterAssetsDialogOpen(false)}
			onApply={onApplyFilter}
			liveMapFilter={props.liveMapSidebarManager.liveMapFilter}
			assets={props.liveMapSidebarManager.sidebarAssets.map(x => x.asset)}
			assetGroups={props.assetGroups}
			assetTypes={props.assetTypes}
		/>}
	</div>;
});
