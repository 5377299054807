import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';

import { ThingLoader, Button, PopoverMenuItem, PopoverMenu } from 'src/components';

import { ManageUserGroup } from './manageUserGroup';
import { AddAssetGroupsDialog } from './addAssetGroupsDialog';

import {
	Client as C,
	AssetGroupService,
	UserGroupService,
	Service,
	ToasterService,
	useInjection,
	useCurrentUser,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

interface IPageData {
	userGroup: C.IUserGroupDto;
	userGroupAssetGroups: C.IUserGroupAssignedAssetGroupDto[];
	allAssetGroups: C.IAssetGroupDto[];
}

export const UserGroupAssets = observer((props: Props) => {
	const _assetGroupService = useInjection<AssetGroupService>(Service.AssetGroup);
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const currentUser = useCurrentUser();

	const [selectedAssetGroups, setSelectedAssetGroups] = useState<Set<string>>(new Set<string>());
	const [addAssetGroupsDialogType, setAddAssetGroupsDialogType] = useState<null | 'all-assets' | 'client'>(null);
	const [saving, setSaving] = useState<boolean>(false);

	const load = async (userGroupId: string): Promise<IPageData> => {
		const userGroup = await _userGroupService.getUserGroup(userGroupId);
		const userGroupAssetGroups = await _userGroupService.getAssetGroups(userGroupId);
		const allAssetGroups = await _assetGroupService.getAssetGroups(C.ListAssetGroupsType.Manage);

		return {
			userGroup: userGroup!,
			userGroupAssetGroups: userGroupAssetGroups.sort((a, b) => a.assetGroup.name.localeCompare(b.assetGroup.name, undefined, { numeric: true })),
			allAssetGroups: allAssetGroups,
		};
	};

	const addAssetGroupsComplete = (reload: Function) => {
		setAddAssetGroupsDialogType(null);
		reload();
	};

	const toggleAssetGroup = (assetGroupId: string) => {
		const newSelectedAssetGroups = new Set(selectedAssetGroups);
		if (newSelectedAssetGroups.has(assetGroupId))
			newSelectedAssetGroups.delete(assetGroupId);
		else
			newSelectedAssetGroups.add(assetGroupId);

		setSelectedAssetGroups(newSelectedAssetGroups);
	};

	const removeAssetGroups = async (reload: Function) => {
		if (!confirm('Are you sure you want to remove the selected asset group(s) from this user group?'))
			return;

		setSaving(true);

		try {
			await _userGroupService.updateAssetGroups(props.match.params.id, {
				removeAssetGroupIds: Array.from(selectedAssetGroups),
			});

			reload();
			setSelectedAssetGroups(new Set<string>());
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to remove assets from group.');
		}

		setSaving(false);
	};

	const renderActionMenu = () => {
		return [
			<PopoverMenuItem
				key="client"
				text="Asset groups from this client"
				onClick={() => setAddAssetGroupsDialogType('client')}
			/>,
			<PopoverMenuItem
				key="all-assets"
				text="Asset groups from any client"
				onClick={() => setAddAssetGroupsDialogType('all-assets')}
			/>,
		];
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(pageData: IPageData, reload: Function) => <ManageUserGroup
			userGroup={pageData.userGroup}
		>
			<div className="user-group-assets">
				Users in this group have access to all assets in the asset groups listed below.

				<div className="actions">
					<Button
						text="Remove"
						variant="outlined"
						disabled={selectedAssetGroups.size === 0 || saving}
						loading={saving}
						onClick={() => removeAssetGroups(reload)}
					/>

					{currentUser!.identity.type === C.IdentityType.SuperUser
						? <PopoverMenu renderOptions={renderActionMenu}>
							<Button
								text="Add"
								color="primary"
								variant="contained"
							/>
						</PopoverMenu>
						: <Button
							text="Add"
							color="primary"
							variant="contained"
							onClick={() => setAddAssetGroupsDialogType('client')}
						/>}
				</div>

				<table className="card-table">
					<tbody>
						{pageData.userGroupAssetGroups!.map(x => <tr key={x.assetGroup.assetGroupId} className="content-box">
							<td>
								<Checkbox
									checked={selectedAssetGroups.has(x.assetGroup.assetGroupId)}
									onClick={() => toggleAssetGroup(x.assetGroup.assetGroupId)}
								/>
							</td>
							<td>{currentUser!.identity.client?.clientId === x.assetGroup.client.clientId ? x.assetGroup.name : x.assetGroup.name + ` (${x.assetGroup.client.name})`}</td>
						</tr>)}
					</tbody>
				</table>

				{addAssetGroupsDialogType && <AddAssetGroupsDialog
					userGroupId={pageData.userGroup.userGroupId}
					assetGroups={addAssetGroupsDialogType === 'client'
						? pageData.allAssetGroups.filter(x => x.client.clientId === pageData.userGroup.client.clientId)
						: pageData.allAssetGroups}
					existing={pageData.userGroupAssetGroups!.map(x => x.assetGroup)}
					complete={() => addAssetGroupsComplete(reload)}
					closeDialog={() => setAddAssetGroupsDialogType(null)}
				/>}
			</div>
		</ManageUserGroup>}
	/>;
});
