import { inject, injectable } from 'inversify';
import { action, makeObservable, observable } from 'mobx';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import * as A from './authenticationService';
import * as C from './client';
import { Service } from './service';
import { PlayState, SoundService } from './soundService';
import { GetGraphQlDeviceType } from '../util/deviceTypeHelper';

import { EmergencyStatus, EmergencyType } from 'src/../__generated__/globalTypes';
import { executeQueryEmergenciesForEmergencyService, QueryEmergenciesForEmergencyService_emergencies } from 'src/graphql/__generated__/queries/queryEmergenciesForEmergencyService';

export interface EmergencyState {
	assetId: string;
	hasEmergency: boolean;
	emergencyType: C.EmergencyType;
}

@injectable()
export class EmergencyService {
	@observable allEmergencies = new Map<string, QueryEmergenciesForEmergencyService_emergencies>();
	@observable emergenciesDismissed = false;

	private _emergencyStateChangeSubscriptionCallback: ((emergencyState: EmergencyState) => void) | null = null;

	constructor(
		@inject(Service.ApiClient) private client: C.Client,
		@inject(Service.Authentication) private auth: A.AuthenticationService,
		@inject(Service.Sound) private soundService: SoundService,
		@inject(Service.ApolloClient) private _apolloClient: ApolloClient<InMemoryCache>,
	) {
		makeObservable(this);
		this.resetState();
	}

	@action
	async resetState() {
		this.allEmergencies = new Map<string, QueryEmergenciesForEmergencyService_emergencies>();
		this.emergenciesDismissed = false;

		if (this.auth.currentAuth)
			await this.load();

		this.updateAlertSound();
	}

	@action
	private async load(): Promise<void> {
		try {
			const apiEmergenciesQuery = executeQueryEmergenciesForEmergencyService(this._apolloClient, {
				emergencyStatus: EmergencyStatus.NEW,
			});

			const { error: emergencyQueryError, data: emergencyData } = await apiEmergenciesQuery;
			if (emergencyQueryError || !emergencyData?.emergencies)
				return;

			const emergencies = emergencyData.emergencies;

			for (const emergency of emergencies) {
				this.allEmergencies.set(emergency.id, emergency);
			}
		} catch (err) {

		}
	}

	@action
	handleEmergency(emergency: C.IEmergencyDto) {

		let convertedEmergencyType = undefined;
		switch (emergency.type) {
			case C.EmergencyType.PriorityAlert:
				convertedEmergencyType = EmergencyType.PRIORITY_ALERT;
				break;
			default:
				convertedEmergencyType = EmergencyType.EMERGENCY;
				break;
		}

		if (emergency.status === C.EmergencyStatus.New) {
			const newEmergency: QueryEmergenciesForEmergencyService_emergencies = {
				__typename: 'Emergency',
				id: emergency.emergencyId,
				status: EmergencyStatus.NEW,
				type: convertedEmergencyType,
				asset: {
					__typename: 'Asset',
					id: emergency.asset.assetId,
					name: emergency.asset.name,
				},
				deviceIoConfiguration: emergency.deviceIoConfigurationEmergencyInformation ? {
					__typename: 'DeviceIoConfiguration',
					name: emergency.deviceIoConfigurationEmergencyInformation.deviceIoConfigurationName,
					device: {
						__typename: GetGraphQlDeviceType(emergency.deviceIoConfigurationEmergencyInformation.deviceType),
						name: emergency.deviceIoConfigurationEmergencyInformation!.deviceName,
					}
				} :
				null,
			};

			this.allEmergencies.set(emergency.emergencyId, newEmergency);
			this.emergenciesDismissed = false;
		} else {
			this.allEmergencies.delete(emergency.emergencyId);
		}

		this.updateAlertSound();

		if (this._emergencyStateChangeSubscriptionCallback) {
			const emergencies = Array.from(this.allEmergencies.values()).filter(x => x.asset.id === emergency.asset.assetId);

			const assetHasEmergency = emergencies.length > 0;
			let emergencyType = C.EmergencyType.Emergency;

			if (assetHasEmergency) {
				const emergencyTypeIsEmergency = emergencies.some(x => x.type === EmergencyType.EMERGENCY);
				if (!emergencyTypeIsEmergency && emergencies.some(x => x.type === EmergencyType.PRIORITY_ALERT))
					emergencyType = C.EmergencyType.PriorityAlert;
			}

			this._emergencyStateChangeSubscriptionCallback({
				assetId: emergency.asset.assetId,
				hasEmergency: assetHasEmergency,
				emergencyType: emergencyType,
			});
		}
	}

	subscribeToEmergencyStateChanges(callback: (emergencyState: EmergencyState) => void) {
		this._emergencyStateChangeSubscriptionCallback = callback;
	}

	unsubscribeFromEmergencyStateChanges() {
		this._emergencyStateChangeSubscriptionCallback = null;
	}

	dismissAlertEmergencies() {
		this.emergenciesDismissed = true;
		this.updateAlertSound();
	}

	updateAlertSound() {
		this.soundService.priorityAlert(PlayState.Stop);
		this.soundService.emergency(PlayState.Stop);

		if (this.emergenciesDismissed || this.allEmergencies.size == 0)
			return;

		const distinctAlertTypes = new Set(Array.from(this.allEmergencies.values(), x => x.type));

		if (distinctAlertTypes.has(EmergencyType.EMERGENCY))
			this.soundService.emergency(PlayState.Play);
		else if (distinctAlertTypes.has(EmergencyType.PRIORITY_ALERT))
			this.soundService.priorityAlert(PlayState.Play);
		else
			this.soundService.emergency(PlayState.Play);
	}

	async resolveEmergency(emergencyId: string, request: C.IResolveEmergencyRequest): Promise<void> {
		await this.client.resolveEmergencyById(emergencyId, new C.ResolveEmergencyRequest(request));
	}

	async editUserAlertSettings(userId: string, request: C.IEditUserAlertSettingsRequest): Promise<C.IUserAlertSettingsDto> {
		return await this.client.updateUserAlertSettingsById(userId, new C.EditUserAlertSettingsRequest(request));
	}

	async fetchUserAlertSettings(userId: string): Promise<C.IUserAlertSettingsDto> {
		return await this.client.getUserAlertSettingsById(userId);
	}

	async emergencyQuickAccessGet(accessToken: string) {
		return this.client.getEmergencyQuickAccessInfoByAccessToken(accessToken);
	}

	async emergencyQuickAccessResolve(accessToken: string, request: C.IResolveEmergencyRequest): Promise<void> {
		this.client.resolveEmergencyByQuickAccessToken(accessToken, new C.ResolveEmergencyRequest(request));
	}
}
