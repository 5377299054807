import { Client as C } from 'src/services';
import { ApolloClient, InMemoryCache } from '@apollo/client';

import { AddDeviceAssetInput, DeviceType } from 'src/../__generated__/globalTypes';
import { executeMutationAddATrackDevice } from 'src/graphql/__generated__/mutations/mutationAddATrackDevice';
import { executeMutationAddCamperVanDevice } from 'src/graphql/__generated__/mutations/mutationAddCamperVanDevice';
import { executeMutationAddDigitalMatterDevice } from 'src/graphql/__generated__/mutations/mutationAddDigitalMatterDevice';
import { executeMutationAddEroadDevice } from 'src/graphql/__generated__/mutations/mutationAddEroadDevice';
import { executeMutationAddHyteraRadioDevice } from 'src/graphql/__generated__/mutations/mutationAddHyteraRadioDevice';
import { executeMutationAddMobilePhoneDevice } from 'src/graphql/__generated__/mutations/mutationAddMobilePhoneDevice';
import { executeMutationAddTaitRadioDevice } from 'src/graphql/__generated__/mutations/mutationAddTaitRadioDevice';
import { executeMutationAddTeltonikaDevice } from 'src/graphql/__generated__/mutations/mutationAddTeltonikaDevice';

import { ManageDeviceFormValues } from './manageDeviceComponent';

const createAddDeviceAssetInput = (values: ManageDeviceFormValues, currentUser: C.IUserDto) => {
	let speedLimit: number | null = null;
	if (values.speedLimitEnabled && values.speedLimit) {
		speedLimit = values.speedLimit;

		if (!currentUser.usesMetric)
			speedLimit = Math.round(speedLimit * 1.60934);
	}

	return {
		name: values.assetName || undefined,
		assetTypeId: values.assetTypeId,
		speedLimit: speedLimit,
		emergencyNotes: values.emergencyNotes,
	};
};

interface BaseDeviceInput {
	name: string;
	serialNumber?: string | null;
	dealerId?: string | null;
	clientId?: string | null;
}

export const submitAddDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, addAssetView: boolean, currentUser: C.IUserDto) => {
	let deviceName = values.deviceName;
	if (addAssetView && values.deviceSameNameAsAsset)
		deviceName = values.assetName;

	const asset: AddDeviceAssetInput | undefined = addAssetView ? createAddDeviceAssetInput(values, currentUser) : undefined;

	const base = {
		name: deviceName,
		dealerId: values.dealerId,
		clientId: values.clientId,
		serialNumber: values.serialNumber || undefined,
		asset,
	};

	switch (values.deviceType) {
		case DeviceType.ATRACK:
			return addATrackDevice(apolloClient, values, base);

		case DeviceType.CAMPER_VAN:
			return addCamperVanDevice(apolloClient, values, base);

		case DeviceType.DIGITAL_MATTER:
			return addDigitalMatterDevice(apolloClient, values, base);

		case DeviceType.EROAD:
			return addEroadDevice(apolloClient, values, base);

		case DeviceType.HYTERA_RADIO:
			return addHyteraRadioDevice(apolloClient, values, base);

		case DeviceType.MOBILE_PHONE:
			return addMobilePhoneDevice(apolloClient, values, base);

		case DeviceType.TAIT_RADIO:
			return addTaitRadioDevice(apolloClient, values, base);

		case DeviceType.TELTONIKA:
			return addTeltonikaDevice(apolloClient, values, base);

		default:
			throw `Unhandled device type: ${values.deviceType}`;
	}
};

const addATrackDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, base: BaseDeviceInput) => {
	const result = await executeMutationAddATrackDevice(apolloClient, {
		input: {
			...base,
			imei: values.imei,
			phoneNumber: values.phoneNumber || undefined,
			modelType: values.atrackModelType,
		},
	});

	return result.data!.addATrackDevice!.atrackDevice;
};

const addCamperVanDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, base: BaseDeviceInput) => {
	const result = await executeMutationAddCamperVanDevice(apolloClient, {
		input: {
			...base,
			imei: values.imei,
		},
	});

	return result.data!.addCamperVanDevice!.camperVanDevice;
};

const addDigitalMatterDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, base: BaseDeviceInput) => {
	const result = await executeMutationAddDigitalMatterDevice(apolloClient, {
		input: {
			...base,
			imei: values.imei,
			phoneNumber: values.phoneNumber || undefined,
			modelType: values.digitalMatterModelType,
		},
	});

	return result.data!.addDigitalMatterDevice!.digitalMatterDevice;
};

const addEroadDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, base: BaseDeviceInput) => {
	const result = await executeMutationAddEroadDevice(apolloClient, {
		input: {
			...base,
			vehiclePlate: values.vehiclePlate,
		},
	});

	return result.data!.addEroadDevice!.eroadDevice;
};

const addHyteraRadioDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, base: BaseDeviceInput) => {
	const result = await executeMutationAddHyteraRadioDevice(apolloClient, {
		input: {
			...base,
			networkId: values.networkId || undefined,
			radioId: values.radioId || undefined,
			bleEnabled: values.bleEnabled,
			bluetoothBeaconSetIds: values.bluetoothBeaconSetIds,
			gpsEnabled: values.gpsEnabled,
		},
	});

	return result.data!.addHyteraRadioDevice!.hyteraRadioDevice;
};

const addMobilePhoneDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, base: BaseDeviceInput) => {
	const result = await executeMutationAddMobilePhoneDevice(apolloClient, {
		input: {
			...base,
			networkId: values.networkId || undefined,
			chatterPttUserId: values.chatterPttUserId || undefined,
			callRecordingEnabled: values.callRecordingEnabled,
			bluetoothBeaconSetIds: values.bluetoothBeaconSetIds,
		},
	});

	return result.data!.addMobilePhoneDevice!.mobilePhoneDevice;
};

const addTaitRadioDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, base: BaseDeviceInput) => {
	const result = await executeMutationAddTaitRadioDevice(apolloClient, {
		input: {
			...base,
			networkId: values.networkId || undefined,
			radioId: values.radioId || undefined,
			selcallId: values.selCallId || undefined,
			bleEnabled: values.bleEnabled,
			bluetoothBeaconSetIds: values.bluetoothBeaconSetIds,
			gpsEnabled: values.gpsEnabled,
			callRecordingEnabled: values.callRecordingEnabled,
			manualPollingEnabled: values.manualPollingEnabled,
		},
	});

	return result.data!.addTaitRadioDevice!.taitRadioDevice;
};

const addTeltonikaDevice = async (apolloClient: ApolloClient<InMemoryCache>, values: ManageDeviceFormValues, base: BaseDeviceInput) => {
	const result = await executeMutationAddTeltonikaDevice(apolloClient, {
		input: {
			...base,
			imei: values.imei,
			phoneNumber: values.phoneNumber || undefined,
			modelType: values.teltonikaModelType,
			networkId: values.networkId || undefined,
		},
	});

	return result.data!.addTeltonikaDevice!.teltonikaDevice;
};
