import React from 'react';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import { Formik, FormikHelpers, FormikProps, Form, Field, FormikErrors } from 'formik';

import {
	HistoryService,
	Service,
	ToasterService,
	useInjection,
	UsersService,
} from 'src/services';

import { Button, FormikTextField } from 'src/components';
import { runFormValidation } from 'src/util';

interface ResetPasswordConfirmFormValues {
	emailAddress: string;
	confirmationCode: string;
	password: string;
	passwordConfirm: string;
}

const validateForm = (values: ResetPasswordConfirmFormValues, errors: FormikErrors<ResetPasswordConfirmFormValues>) => {
	if (!values.emailAddress)
		errors.emailAddress = 'Email address is required.';

	if (!values.confirmationCode)
		errors.confirmationCode = 'Confirmation code is required.';

	if (!values.password)
		errors.password = 'Password is required.';
	else if (values.password.length < 8)
		errors.password = 'Password must be at least 8 characters.';

	if (!values.passwordConfirm)
		errors.passwordConfirm = 'Password confirmation is required.';
	else if (values.passwordConfirm !== values.password)
		errors.passwordConfirm = 'Passwords must match.';
};

export const ResetPasswordConfirm = observer(() => {
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _usersService = useInjection<UsersService>(Service.Users);

	const query = queryString.parse(_historyService.history.location.search);

	const onSubmit = async (values: ResetPasswordConfirmFormValues, { setSubmitting }: FormikHelpers<ResetPasswordConfirmFormValues>) => {
		try {
			await _usersService.confirmResetPassword({
				emailAddress: values.emailAddress,
				resetToken: values.confirmationCode,
				password: values.password,
			});

			_toasterService.showSuccess('Password successfully changed, please sign in with your new password to continue.');
			_historyService.history.push('/auth/sign-in');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to reset password.');
			setSubmitting(false);
		}
	};

	return <div>
		<h1>Reset password</h1>

		<Formik
			initialValues={{
				emailAddress: query.emailAddress as string || '',
				confirmationCode: query.confirmationCode as string || '',
				password: '',
				passwordConfirm: '',
			}}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ResetPasswordConfirmFormValues>) => <Form className="formik-form">
				<Field
					name="emailAddress"
					label="Email Address"
					type="email"
					component={FormikTextField}
				/>

				<Field
					name="confirmationCode"
					label="Confirmation Code"
					type="text"
					component={FormikTextField}
				/>

				<Field
					name="password"
					label="New Password"
					type="password"
					component={FormikTextField}
				/>

				<Field
					name="passwordConfirm"
					label="Confirm New Password"
					type="password"
					component={FormikTextField}
				/>

				<div className="auth-action">
					<Button
						type="submit" variant="contained" color="primary"
						text="Reset password"
						loading={formikProps.isSubmitting}
					/>
				</div>
			</Form>}
		/>
	</div>;
});
