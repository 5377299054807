import * as C from 'src/services/client';

export const reportTypes = (permisions: C.IGeneralIdentityPermissions): { value: C.ReportType, label: string }[] => {
	const options: { value: C.ReportType, label: string }[] = [];

	if (permisions.viewAssetLocations) {
		options.push(
			{
				value: C.ReportType.Activity,
				label: 'Activity',
			},
			{
				value: C.ReportType.AssetEventHistory,
				label: 'Asset Event History',
			},
			{
				value: C.ReportType.Daily,
				label: 'Daily',
			},
			{
				value: C.ReportType.Geofence,
				label: 'Geofence',
			},
			{
				value: C.ReportType.Location,
				label: 'Location',
			},
			{
				value: C.ReportType.SafetyTimer,
				label: 'Safety Timer',
			},
		);
	}

	if (permisions.manageAddressBooks && permisions.manageAssets) {
		options.push({
			value: C.ReportType.Driver,
			label: 'Driver And Attachment Report',
		});
	}

	if (permisions.manageUsers && permisions.manageUserGroups) {
		options.push({
			value: C.ReportType.UserAuthAudit,
			label: 'User Auth Audit',
		});
	}

	if (permisions.accessBillingReports) {
		options.push(
			{
				value: C.ReportType.AssetLogHistory,
				label: 'Asset Log History',
			},
			{
				value: C.ReportType.Billing,
				label: 'Billing',
			},
		);
	}

	return options;
};

export const reportFrequency: { value: C.ReportFrequency, label: string }[] = [
	{
		value: C.ReportFrequency.Daily,
		label: 'Daily',
	},
	{
		value: C.ReportFrequency.Weekly,
		label: 'Weekly',
	},
	{
		value: C.ReportFrequency.TwoWeeks,
		label: 'Two Weeks',
	},
	{
		value: C.ReportFrequency.Monthly,
		label: 'Monthly',
	},
];
