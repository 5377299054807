import React from 'react';
import { css } from '@emotion/css';
import { FormikHelpers } from 'formik';
import moment from 'moment-timezone';

import { EmergencyInfo } from './emergencyInfo';
import { SafetyTimerEmergencyInfo, ISafetyTimer } from './safetyTimerEmergencyInfo';
import { ResolveEmergencyForm, IResolveEmergencyFormValues } from './resolveEmergencyForm';
import { FixedWidthPage } from 'src/components';

import { EmergencyStatus, EmergencyType } from 'src/../__generated__/globalTypes';

interface IEmergencyViewerProps {
	headingText?: string;
	emergency: IEmergency;
	timeZone: string;
	onSubmit: (values: IResolveEmergencyFormValues, FormikHelpers: FormikHelpers<IResolveEmergencyFormValues>) => void;
	children?: React.ReactNode;
}

export interface IEmergency {
	status: EmergencyStatus;
	type: EmergencyType;
	safetyTimer: ISafetyTimer | null;
	generatedAt: moment.Moment | null | undefined;
	recordedAt: moment.Moment;
	actions: IEmergencyAction[] | null;
	asset: IEmergencyAsset;
	ioConfiguration: IDeviceIoConfiguration | null;
}

export interface IEmergencyAction {
	performedAt: moment.Moment;
	performedByUserName?: string;
}

export interface IEmergencyAsset {
	name: string;
	emergencyNotes: string | null | undefined;
}

export interface IDeviceIoConfiguration {
	name: string;
	deviceName: string;
}

const style = css`
	> * {
		margin-top: 10px;
	}

	td {
		padding-right: 15px;
	}
`;

export const EmergencyViewer = (props: IEmergencyViewerProps) => <FixedWidthPage
	headingText={props.headingText}
	noContentBackground
	contentClassName={style}
>
	<EmergencyInfo
		emergency={props.emergency}
		timeZone={props.timeZone}
	/>

	{props.emergency.safetyTimer && <SafetyTimerEmergencyInfo
		safetyTimer={props.emergency.safetyTimer}
		timeZone={props.timeZone}
	/>}

	{props.children}

	{props.emergency.status !== EmergencyStatus.RESOLVED && <ResolveEmergencyForm
		onSubmit={props.onSubmit}
	/>}
</FixedWidthPage>;
