import React from 'react';
import { observer } from 'mobx-react';
import { Formik, Form, Field, FormikProps, FormikHelpers, FormikErrors } from 'formik';
import parsePhoneNumber from 'libphonenumber-js/mobile';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FormikSelect, FormikTextField, FormikCheckbox } from 'src/components';
import { runFormValidation } from 'src/util';
import { selectOptions } from 'src/util/timeZones';
import { measurementsSystemsOptions } from 'src/util/systemsOfMeasurement';

import {
	Client as C,
	AuthenticationService,
	Service,
	ToasterService,
	UsersService,
	useInjection,
} from 'src/services';

interface EditUserDetailsFormValues {
	name: string;
	emailAddress: string;
	mobilePhoneNumber: string;
	isAdmin: boolean;
	timeZone: string;
	usesMetric: string;
}

const validateForm = (values: EditUserDetailsFormValues, errors: FormikErrors<EditUserDetailsFormValues>) => {
	if (!values.name)
		errors.name = 'Name is required.';

	if (values.mobilePhoneNumber) {
		const phoneNumber = parsePhoneNumber(values.mobilePhoneNumber);
		if (phoneNumber == null || !phoneNumber.isValid())
			errors.mobilePhoneNumber = 'Please enter a valid international mobile phone number (beginning with +). For example, the New Zealand mobile number 027 123 4567 should be entered as +64 27 123 4567.';
	}
};

export interface EditUserDetailsComponentProps {
	user: C.IUserDto;
	onEditSuccess?: Function;
}

export const EditUserDetailsComponent = observer((props: EditUserDetailsComponentProps) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _usersService = useInjection<UsersService>(Service.Users);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const initialFormValues: EditUserDetailsFormValues = {
		name: props.user.name,
		emailAddress: props.user.emailAddress,
		mobilePhoneNumber: props.user.mobilePhoneNumber || '',
		isAdmin: !!props.user.identity.isAdmin,
		timeZone: props.user.timeZone,
		usesMetric: props.user.usesMetric == true ? 'metric' : 'imperial',
	};

	const onSubmit = async (values: EditUserDetailsFormValues, { setSubmitting }: FormikHelpers<EditUserDetailsFormValues>) => {
		const request: C.IUpdateUserRequest = {
			name: values.name,
			mobilePhoneNumber: values.mobilePhoneNumber,
			timeZone: values.timeZone,
			usesMetric: values.usesMetric === 'metric' ? true : false,
		};

		if (props.user.identity.isAdmin !== values.isAdmin)
			request.isAdmin = values.isAdmin;

		try {
			await _usersService.updateUser(props.user.userId, request);
			setSubmitting(false);

			if (props.onEditSuccess)
				props.onEditSuccess();
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to update user details.');
			setSubmitting(false);
		}
	};

	const editingClientUser = props.user.identity.type === C.IdentityType.Client;
	const canSetClientAdmin = _authService.currentAuth.user.identity.type === C.IdentityType.SuperUser ||
		_authService.currentAuth.user.identity.type === C.IdentityType.Dealer ||
		(_authService.currentAuth.user.identity.type === C.IdentityType.Client && _authService.currentAuth.user.identity.isAdmin);

	return <Formik
		initialValues={initialFormValues}
		validate={values => runFormValidation(values, validateForm)}
		validateOnChange={false}
		onSubmit={onSubmit}
		render={(formikProps: FormikProps<EditUserDetailsFormValues>) => <Form className="formik-form">
			<Field
				name="name"
				label="Name"
				type="text"
				component={FormikTextField}
				required
			/>

			<Field
				name="emailAddress"
				label="Email Address"
				type="text"
				component={FormikTextField}
				required
				disabled
			/>

			<Field
				name="mobilePhoneNumber"
				label="Mobile Phone Number"
				component={FormikTextField}
			/>

			{editingClientUser && canSetClientAdmin && <Field
				name="isAdmin"
				label="Administrator"
				type="checkbox"
				component={FormikCheckbox}
			/>}

			<FormikSelect
				name="timeZone"
				label="Time Zone"
				options={selectOptions}
				form={formikProps}
				clearable={false}
				getOptionLabel={option => option.label}
				getOptionValue={option => option.value}
				required
			/>

			<FormikSelect
				name="usesMetric"
				label="System of Measurement"
				options={measurementsSystemsOptions}
				form={formikProps}
				clearable={false}
				getOptionLabel={option => option.label}
				getOptionValue={option => option.value}
				required
			/>

			<Button
				type="submit"
				variant="contained"
				color="primary"
				startIcon={<SaveIcon />}
				text="Save Changes"
				disabled={formikProps.isSubmitting}
			/>
		</Form>}
	/>;
});
