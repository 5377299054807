import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Formik, Form, Field, FormikProps, FormikHelpers, FormikErrors } from 'formik';
import queryString from 'query-string';
import Alert from '@material-ui/lab/Alert';

import {
	Client as C,
	AuthenticationService,
	HistoryService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

import { baseUrl } from 'src/config';
import { Button, FormikTextField, MessagePage } from 'src/components';
import { runFormValidation } from 'src/util';

enum SignInFormState {
	EmailAddress = 1,
	Password = 2,
	Redirecting = 3,
}

interface SignInFormValues {
	emailAddress: string;
	password: string;
}

export const SignIn = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toaster = useInjection<ToasterService>(Service.Toaster);

	const [signInFormState, setSignInFormState] = useState<SignInFormState>(SignInFormState.EmailAddress);

	const queryParams = queryString.parse(_historyService.history.location.search);

	let returnUrl = '/app';
	if (queryParams?.returnUrl)
		returnUrl = queryParams.returnUrl as string;

	const createSignInUrl = (provider: C.ICheckAuthProvidersResponseProvider, emailAddress: string) => {
		return `${baseUrl}/api/v1/external-auth/sign-in`
			+ `?provider=${provider.scheme}`
			+ `&email=${encodeURIComponent(emailAddress)}`
			+ `&returnUrl=${encodeURIComponent(returnUrl)}`;
	};

	const setSignInError = (error: any) => {
		let errorString = null;

		if (error !== null) {
			if (error instanceof C.ErrorResponse)
				errorString = error.errors.join(' ');
			else if (typeof(error) === 'string')
				errorString = error;
			else
				_toaster.handleWithToast(error);
		}

		queryParams['error'] = errorString;
		_historyService.history.replace(`${_historyService.history.location.pathname}?${queryString.stringify(queryParams, { skipNull: true })}`);
	};

	const validateForm = (values: SignInFormValues, errors: FormikErrors<SignInFormValues>) => {
		if (!values.emailAddress)
			errors.emailAddress = 'Email address is required.';

		if (signInFormState === SignInFormState.Password) {
			if (!values.password)
				errors.password = 'Password is required.';
		}
	};

	const onSubmit = async (values: SignInFormValues, { setSubmitting }: FormikHelpers<SignInFormValues>) => {
		// Clear any errors before continuing, otherwise it might look like the same error came back.
		setSignInError(null);

		switch (signInFormState) {
			case SignInFormState.EmailAddress: {
				try {
					const authProvidersResult = await _authService.checkAuthProviders({
						emailAddress: values.emailAddress,
					});

					if (authProvidersResult.providers.length === 0) {
						setSignInFormState(SignInFormState.Password);
					} else {
						setSignInFormState(SignInFormState.Redirecting);

						const provider = authProvidersResult.providers[0];
						window.location.href = createSignInUrl(provider, values.emailAddress);
					}
				} catch (err) {
					setSignInError(err);
				}

				break;
			}

			case SignInFormState.Password: {
				try {
					await _authService.authenticate(values.emailAddress, values.password);
					// Authentication reaction handles sign in success.
				} catch (err) {
					setSignInError(err);
				}

				break;
			}
		}

	};

	if (_authService.isAttemptingReauthentication)
		return <MessagePage loading />;

	return <div>
		<h1>Sign in</h1>

		{queryParams?.error && <Alert severity="error" style={{ marginBottom: '15px' }}>{queryParams.error}</Alert>}

		<Formik
			initialValues={{
				emailAddress: '',
				password: '',
			}}
			validate={values => runFormValidation(values, validateForm)}
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<SignInFormValues>) => <Form className="formik-form">
				<Field
					name="emailAddress"
					label="Email address"
					type="text"
					component={FormikTextField}
					disabled={signInFormState !== SignInFormState.EmailAddress}
					autoFocus
				/>

				{signInFormState === SignInFormState.Password && <>
					<Field
						name="password"
						label="Password"
						type="password"
						component={FormikTextField}
						autoFocus
					/>

					<div className="forgot-password">
						<Link to={!formikProps.values.emailAddress ? '/auth/reset-password' : `/auth/reset-password?emailAddress=${formikProps.values.emailAddress}`}>
							Forgot password?
						</Link>
					</div>
				</>}

				<div className="auth-action">
					{signInFormState !== SignInFormState.EmailAddress && <Button
						type="button" variant="outlined" color="primary"
						text="Back"
						disabled={formikProps.isSubmitting || signInFormState === SignInFormState.Redirecting}
						onClick={() => setSignInFormState(SignInFormState.EmailAddress)}
					/>}

					<Button
						type="submit" variant="contained" color="primary"
						text={signInFormState === SignInFormState.Password ? 'Sign In' : 'Next'}
						loading={formikProps.isSubmitting || signInFormState === SignInFormState.Redirecting}
					/>
				</div>
			</Form>}
		/>
	</div>;
});
