import { injectable, inject } from 'inversify';
import { runInAction, computed, observable, makeObservable } from 'mobx';
import { BooleanResponse } from './';

import * as C from './client';
import { Service } from './service';

interface CurrentAuth {
	user: C.IUserDto;
	permissions: {
		general: C.IGeneralIdentityPermissions,
	};
	userAlertSettings: C.IUserAlertSettingsDto;
	signInAsActor?: C.ISignInAsActorDto | null;
	ssoSignIn: boolean;
}

@injectable()
export class AuthenticationService {
	@observable isAttemptingReauthentication: boolean = false;
	@observable currentAuth: CurrentAuth;
	@observable private authInProgress = false;

	constructor(
		@inject(Service.ApiClient) private client: C.Client
	) {
		this.checkSignInState();
		makeObservable(this);
	}

	@computed get isAuthenticated(): boolean {
		return this.currentAuth != null;
	}

	@computed get authenticationInProgress(): boolean {
		return this.authInProgress;
	}

	private async getCurrentAuth(): Promise<CurrentAuth> {
		const userResult = await this.client.getCurrentUser();
		const permissions = await this.client.getIdentityGeneralPermissionsById(userResult.user.identityId);
		const settings = await this.client.getUserAlertSettingsById(userResult.user.userId);

		return {
			user: userResult.user,
			permissions: {
				general: permissions,
			},
			userAlertSettings: settings,
			signInAsActor: userResult.signInAsActor,
			ssoSignIn: userResult.ssoSignIn,
		};
	}

	checkAuthProviders(request: C.ICheckAuthProvidersRequest): Promise<C.ICheckAuthProvidersResponse> {
		return this.client.checkAuthProviders(new C.CheckAuthProvidersRequest(request));
	}

	async authenticate(emailAddress: string, password: string): Promise<BooleanResponse> {
		try {
			this.authInProgress = true;

			await this.client.authSignIn(new C.SignInRequest({ emailAddress, password }));
			const currentAuth = await this.getCurrentAuth();

			runInAction(() => {
				this.authInProgress = false;
				this.currentAuth = currentAuth;
			});

			return { success: true };
		} catch (err) {
			this.authInProgress = false;
			throw(err);
		}
	}

	async checkForCurrentAuthUserUpdate(): Promise<void> {
		try {
			const currentUser = await this.client.getCurrentUser();
			this.currentAuth.user = currentUser.user;
		} catch (err) {
			// Failed to check for latest user details
		}
	}

	async update(update: C.IUpdateUserRequest): Promise<void> {
		await this.client.updateUserById(this.currentAuth.user.userId, new C.UpdateUserRequest(update));

		const currentUser = await this.client.getCurrentUser();
		this.currentAuth.user = currentUser.user;
	}

	public async checkSignInState() {
		this.isAttemptingReauthentication = true;

		try {
			const currentAuth = await this.getCurrentAuth();
			runInAction(() => {
				this.isAttemptingReauthentication = false;
				this.currentAuth = currentAuth;
			});
		} catch (err) {
			//Ignore, probably 401
		}

		//Validation failed, reset
		if (!this.currentAuth) {
			runInAction(() => {
				this.isAttemptingReauthentication = false;
			});
		}
	}

	async signInAsReturn(): Promise<void> {
		await this.client.authSignInAsReturn();
		await this.checkSignInState();
	}

	clearUserState(): void {
		this.currentAuth = <any> null;
	}

	async signInAs(userId: string): Promise<void> {
		await this.client.authSignInAs(new C.SignInAsRequest({ userId: userId }));
		await this.checkSignInState();
	}

	updateGeneralPermissions(permissions: C.IGeneralIdentityPermissions): void {
		this.currentAuth = {
			...this.currentAuth,
			permissions: {
				general: permissions,
			},
		};
	}
}
