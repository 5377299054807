import React from 'react';
import { LiveMapAssetState } from './liveMapAssetState';
import WarningIcon from '@material-ui/icons/Warning';

import { InformationBox } from './informationBox';
import { IMapAsset } from './mapAsset';

import './informationBox.scss';

import {
	Client as C,
} from 'src/services';

interface Props {
	selectedAssets: IMapAsset[];
	assetStates: Map<string, LiveMapAssetState>;
	onCloseButtonClick?: (() => void) | null;
	assetSelected: (assetId: string | undefined) => void;
}

export const SelectedClusteredAssetsInfoBox = (props: Props) => {

	const renderAssetRow = (asset: IMapAsset) => {
		const assetState = props.assetStates.get(asset.assetId);

		return <div
			key={asset.assetId}
			className="asset-row"
			onClick={() => props.assetSelected(asset.assetId)}
		>
			{assetState?.hasEmergency && <WarningIcon className="asset-emergency-icon" />}
			<span className="asset-name">{asset.name}</span>
		</div>;
	};

	const { assetStates, selectedAssets, onCloseButtonClick } = props;

	const selectedAssetsIds = selectedAssets.map(x => x.assetId);
	const assetsInEmergencies = Array.from(assetStates.values()).filter(x => x.hasEmergency && selectedAssetsIds.includes(x.asset.assetId));

	const title = selectedAssets.length === 1 ? '1 Asset' : `${selectedAssets.length} Assets`;

	let emergencyType = C.EmergencyType.Emergency;

	const hasTypeEmergency = assetsInEmergencies.some(x => x.emergencyType === C.EmergencyType.Emergency);
	if (!hasTypeEmergency && assetsInEmergencies.some(x => x.emergencyType === C.EmergencyType.PriorityAlert))
		emergencyType = C.EmergencyType.PriorityAlert;

	return <InformationBox
		className="selected-clustered-assets"
		onCloseButtonClick={onCloseButtonClick}
		inEmergency={assetsInEmergencies.length > 0}
		emergencyType={emergencyType}
		title={title}
	>
		<div className="assets-list">
			{selectedAssets.map(renderAssetRow)}
		</div>
	</InformationBox>;
};
