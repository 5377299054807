import React from 'react';
import { match } from 'react-router-dom';
import { observer } from 'mobx-react';
import moment from 'moment-timezone';
import { styled } from '@material-ui/core/styles';

import { QueryReport_reportById } from 'src/graphql/__generated__/queries/queryReport';

import { FixedWidthPage, ThingLoader, ReportParametersDisplay } from 'src/components';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';

import {
	AuthenticationService,
	Client as C,
	ReportService,
	Service,
	useInjection,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

const DriverReportTable = styled('table') ({
	marginBottom: '15px',

	'& tr:nth-child(even)': {
		backgroundColor: '#f8f8f8',
	}
});

interface DriverReportViewData {
	report: QueryReport_reportById;
	reportData: C.IDriverReportData;
}

export const ViewDriverReport = observer((props: Props) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const reportService = useInjection<ReportService>(Service.Report);

	const load = async (reportId: string): Promise<DriverReportViewData> => {
		const report = reportService.getReportById(reportId);

		const reportDataResponseObject = reportService.getDriverReportData(reportId);

		return {
			report: await report,
			reportData: await reportDataResponseObject,
		};
	};

	const renderReport = (reportViewData: DriverReportViewData) => {
		if (!reportViewData.reportData.driversAndChanges)
			return undefined;

		return reportViewData.reportData.driversAndChanges.map(driverDetails => {

			if (!driverDetails.changes)
				return undefined;

			return <div key={driverDetails.addressBookEntry!.userIdentifier}>
				<h2>{driverDetails.addressBookEntry!.userName}</h2>

				<DriverReportTable className="card-table">
					<thead>
						<tr>
							<th>Date</th>
							<th>Asset</th>
							<th>Action</th>
						</tr>
					</thead>

					{renderDriverReportBody(driverDetails)}
				</DriverReportTable>
			</div>;
		});
	};

	const renderDriverReportBody = (driverDetails: C.IDriverReportDriverAndChanges) => {
		const rows: JSX.Element[] = [];
		if (driverDetails.changes.length == 0) {
			rows.push(<tr key="noChanges" className="content-box">
				<td align="center" colSpan={3}>
					No Changes Recorded
				</td>
			</tr>);
		} else {
			if (driverDetails.driverDetailsBeforeReport)
				rows.push(renderDriverReportRow(driverDetails.driverDetailsBeforeReport, true));

			driverDetails.changes!.map(x => rows.push(renderDriverReportRow(x, false)));
		}

		return <tbody>
			{rows}
		</tbody>;
	};

	const renderDriverReportRow = (driverChange: C.IDriverChange, beforeReport: boolean) => {
		let message = driverChange.type === C.DriverChangeType.SignedIn ? 'Signed into asset' : 'Signed out of asset';

		if (beforeReport)
			message = 'Signed into asset before report';

		return <tr
				key={driverChange.userIdentifierChangeId}
				className="content-box">
			<td>{moment.tz(driverChange.eventTimestamp, authenticationService.currentAuth.user.timeZone).format(longDateTimeFormat)}</td>
			<td>{driverChange.assetName}</td>
			<td>{message}</td>
		</tr>;
	};

	return <ThingLoader
		id={props.match.params.id}
		load={load}
		render={(reportViewData: DriverReportViewData) => <FixedWidthPage
			headingText="Driver/Attachment Report"
			subheadingText={reportViewData.report.name}
			noContentBackground
			contentClassName="view-driver-report"
		>
			{reportViewData.report.parameters && <ReportParametersDisplay
				reportParameters={reportViewData.report.parameters}
			/>}

			{renderReport(reportViewData)}
		</FixedWidthPage>}
	/>;
});
