import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { Formik, Form } from 'formik';

import { CustomActionDialogBox } from 'src/components/customActionDialogBox';
import { getUsersOrUserGroupIdsToAddAndDelete, IAssetServiceReminderUser, IAssetServiceReminderUserGroup, AssetServiceReminderUserOrUserGroupAlertState } from '../app/assetServiceReminders/assetServiceRemindersModelsAndHelperMethods';
import { IAssetServiceReminderWithUsers, IUser, IUserGroup } from './assetServiceRemindersTable';
import { Button, ErrorMessagePage, LoadingMessagePage, FormikAssetServiceReminderUserOrUserGroupsCheckboxSelector } from 'src/components';

import { useMutationUpdateAssetServiceRemindersUsersAndUserGroups } from 'src/graphql/__generated__/mutations/mutationUpdateAssetServiceRemindersUsersAndUserGroups';
import { useQueryUsersAndUserGroupsForAssetServiceRemindersSelector } from 'src/graphql/__generated__/queries/queryUsersAndUserGroupsForAssetServiceRemindersSelector';

import {
	AuthenticationService,
	Client as C,
	Service,
	ToasterService,
	useIdentityType,
	useInjection,
} from 'src/services';

export interface Props {
	clientId: string;
	assetServiceReminders: IAssetServiceReminderWithUsers[];
	close: () => void;
}

export interface AssetServiceReminderFormUpdateUsersAndUserGroupsFormValues {
	usersToAlert: IAssetServiceReminderUser[];
	userGroupsToAlert: IAssetServiceReminderUserGroup[];
}

export const AssetServiceRemindersUsersAndUserGroupsDialog = observer((props: Props) => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const toasterService = useInjection<ToasterService>(Service.Toaster);
	const identityType = useIdentityType();
	const includeUsers = identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer || (identityType === C.IdentityType.Client && !!authenticationService.currentAuth.user.identity.isAdmin);

	const [initialValues, setInitialValues] = useState<AssetServiceReminderFormUpdateUsersAndUserGroupsFormValues | undefined>(undefined);
	const [updateAssetServiceReminderUsersAndGroups] = useMutationUpdateAssetServiceRemindersUsersAndUserGroups();
	const [currentAssetServiceReminderUsersToAlertOptions, setAssetServiceReminderUsersToAlertOptions] = useState<IAssetServiceReminderUser[]>([]);
	const [currentAssetServiceReminderUserGroupsToAlertOptions, setAssetServiceReminderUserGroupsToAlertOptions] = useState<IAssetServiceReminderUserGroup[]>([]);

	const usersAndUserGroupsQuery = useQueryUsersAndUserGroupsForAssetServiceRemindersSelector({
		variables: {
			includeUsers: includeUsers,
			includeUserGroups: includeUsers,
			includeClients: identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer,
		}
	});

	if (usersAndUserGroupsQuery.loading)
		return <LoadingMessagePage />;

	if (!usersAndUserGroupsQuery.data?.users || !usersAndUserGroupsQuery.data?.userGroups)
		return <ErrorMessagePage />;

	const allUsers = usersAndUserGroupsQuery.data.users;
	const allUserGroups = usersAndUserGroupsQuery.data.userGroups;

	if (!initialValues) {
		// Filter by client for dealers and super users, client should only be returned users and groups for their client.
		if (identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer || (identityType === C.IdentityType.Client && authenticationService.currentAuth.user.identity.isAdmin)) {
			let currentUsersForThisClient = allUsers;
			if (identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer)
				currentUsersForThisClient = allUsers
					.filter(x => x.identity?.client?.id && x.identity.client.id === props.clientId);

			let currentUserGroupsForThisClient = allUserGroups;
			if (identityType === C.IdentityType.SuperUser || identityType === C.IdentityType.Dealer)
				currentUserGroupsForThisClient = allUserGroups
					.filter(x => x.client?.id === props.clientId);

			setAssetServiceReminderUsersToAlertOptions(currentUsersForThisClient.map(x => ({
				id: x.id,
				name: x.name,
				state: AssetServiceReminderUserOrUserGroupAlertState.DoNotAlertForSelectedReminders,
			})));

			setAssetServiceReminderUserGroupsToAlertOptions(currentUserGroupsForThisClient.map(x => ({
				id: x.id,
				name: x.name,
				state: AssetServiceReminderUserOrUserGroupAlertState.DoNotAlertForSelectedReminders,
			})));
		}

		let allUsersForSelectedReminders: IUser[] = [];
		let allUserGroupsForSelectedReminders: IUserGroup[] = [];
		for (const serviceReminder of props.assetServiceReminders) {
			if (serviceReminder.users)
				allUsersForSelectedReminders = allUsersForSelectedReminders.concat(serviceReminder.users);

			if (serviceReminder.userGroups)
				allUserGroupsForSelectedReminders = allUserGroupsForSelectedReminders.concat(serviceReminder.userGroups);
		}

		const initialFormValues: AssetServiceReminderFormUpdateUsersAndUserGroupsFormValues = {
			usersToAlert: [],
			userGroupsToAlert: [],
		};

		// Figure out which users are set to alert on all, some or no asset service reminders.
		for (const selectedUser of allUsersForSelectedReminders) {
			if (initialFormValues.usersToAlert.findIndex(y => y.id === selectedUser.id) < 0) {
				// Check if every selected reminder has this user set on it.
				if (allUsersForSelectedReminders.filter(z => z.id === selectedUser.id).length === props.assetServiceReminders.length) {
					initialFormValues.usersToAlert.push({
						id: selectedUser.id,
						name: selectedUser.name,
						state: AssetServiceReminderUserOrUserGroupAlertState.AlertForSelectedReminders,
					});
				} else {
					initialFormValues.usersToAlert.push({
						id: selectedUser.id,
						name: selectedUser.name,
						state: AssetServiceReminderUserOrUserGroupAlertState.MixedAlertingForSelectedReminders,
					});
				}
			}
		}

		// Figure out which users groups are set to alert on all, some or no asset service reminders.
		for (const selectedUserGroup of allUserGroupsForSelectedReminders) {
			if (initialFormValues.userGroupsToAlert.findIndex(y => y.id === selectedUserGroup.id) < 0) {
				// Check if there every selected reminder has this user set on it.
				if (allUserGroupsForSelectedReminders.filter(z => z.id === selectedUserGroup.id).length === props.assetServiceReminders.length) {
					initialFormValues.userGroupsToAlert.push({
						id: selectedUserGroup.id,
						name: selectedUserGroup.name,
						state: AssetServiceReminderUserOrUserGroupAlertState.AlertForSelectedReminders,
					});
				} else {
					initialFormValues.userGroupsToAlert.push({
						id: selectedUserGroup.id,
						name: selectedUserGroup.name,
						state: AssetServiceReminderUserOrUserGroupAlertState.MixedAlertingForSelectedReminders,
					});
				}
			}
		}
		setInitialValues(initialFormValues);
	}

	if (!initialValues)
		return <LoadingMessagePage />;

	const submit = async (values: AssetServiceReminderFormUpdateUsersAndUserGroupsFormValues) => {
		const assetServiceReminderIds = props.assetServiceReminders.map(x => x.assetServiceReminderId);

		// Figure out which user groups have changed, to decide what users to add or delete.
		const usersToAddAndDelete = getUsersOrUserGroupIdsToAddAndDelete(initialValues.usersToAlert, values.usersToAlert);
		const userGroupsToAddAndDelete = getUsersOrUserGroupIdsToAddAndDelete(initialValues.userGroupsToAlert, values.userGroupsToAlert);

		try {
			await updateAssetServiceReminderUsersAndGroups({
				variables: {
				input: {
					assetReminderIds: assetServiceReminderIds,
					usersToAdd: usersToAddAndDelete.usersOrUserGroupsToAdd,
					usersToDelete: usersToAddAndDelete.usersOrUserGroupsToDelete,
					userGroupsToAdd: userGroupsToAddAndDelete.usersOrUserGroupsToAdd,
					userGroupsToDelete: userGroupsToAddAndDelete.usersOrUserGroupsToDelete,
				},
			}});
			toasterService.showSuccess('Updated users to alert.');
		} catch (err) {
			toasterService.handleWithToast(err, 'Failed to update users to alert.');
		}

		props.close();
	};

	return <Formik
		initialValues={initialValues}
		validateOnChange={false}
		onSubmit={submit}
	>
		{formikProps => <Form className="formik-form">
				<CustomActionDialogBox
					title="Update Selected Service Reminders"
					actionButton={<Button
						variant="contained"
						type="submit"
						loading={formikProps.isSubmitting}
						color="primary"
						text="Update Selected Service Reminders"
						onClick={() => submit(formikProps.values)}
					/>}
					dialogCloseCallback={props.close}
				>
					<p><strong>Asset service reminders selected: {props.assetServiceReminders.length}</strong></p>
					<FormikAssetServiceReminderUserOrUserGroupsCheckboxSelector
						name="usersToAlert"
						options={currentAssetServiceReminderUsersToAlertOptions}
						form={formikProps}
					/>

					<FormikAssetServiceReminderUserOrUserGroupsCheckboxSelector
						name="userGroupsToAlert"
						label="User Groups To Alert"
						options={currentAssetServiceReminderUserGroupsToAlertOptions}
						form={formikProps}
					/>
				</CustomActionDialogBox>
			</Form>}
	</Formik>;
});
