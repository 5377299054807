import { injectable } from 'inversify';
import { Filter, Column } from '@material-table/core';

export interface TableData {
	pageColumnFilterOptions: Map<string, string>; // Key is the field value of the column e.g. 'name'.
	pageColumnHiddenOptions: Map<string, boolean>; // Key is the field value of the column e.g. 'name'.
	pageColumnSortingOptions?: ColumnSortingOptions;
	tablePaginationDetails: TablePaginationDetails;
}

export interface TablePaginationDetails {
	pageNumber?: number;
	pageSize?: number;
}

export interface ColumnSortingOptions {
	columnFieldName: string;
	sortingOrder: 'asc' | 'desc';
}

@injectable()
export class TableDataService {
	private _allTableData = new Map<string, TableData>();

	// Clear all current table data. Should be called if the user logs out or switches users.
	resetState() {
		this._allTableData = new Map<string, TableData>();
	}

	// Gets the data stored about a table. If none exists, a new table data object is returned.
	getPageData(pageName: string): TableData {
		let pageData = this._allTableData.get(pageName);

		if (!pageData) {
			pageData = {
				pageColumnFilterOptions: new Map<string, string>(),
				pageColumnHiddenOptions: new Map<string, boolean>(),
				tablePaginationDetails: {},
			};

			this._allTableData.set(pageName, pageData);
		}

		return pageData;
	}

	setColumnFilters<T extends object>(pageName: string, filters: Filter<T>[]) {
		const pageData = this.getPageData(pageName);

		const currentFilters = new Map<string, string>();
		for (let i = 0; i < filters.length; i++) {
			if (filters[i].column.field && typeof filters[i].column.field === 'string')
				currentFilters.set(filters[i].column.field! as string, filters[i].value);
		}

		pageData.pageColumnFilterOptions = currentFilters;
	}

	setHiddenColumn<T extends object>(pageName: string, column: Column<T>) {
		const pageData = this.getPageData(pageName);
		if (column.field)
			pageData.pageColumnHiddenOptions.set(column.field! as string, column.hidden!);
	}

	getColumnFilter(pageName: string, fieldName: string): string | undefined {
		const pageData = this.getPageData(pageName);
		return pageData.pageColumnFilterOptions.get(fieldName);
	}

	getHiddenColumn(pageName: string, fieldName: string): boolean | undefined {
		const pageData = this.getPageData(pageName);
		return pageData.pageColumnHiddenOptions.get(fieldName);
	}

	setColumnSortingOrder(pageName: string, columnFieldName: string | undefined, orderDirection: 'asc' | 'desc') {
		const pageData = this.getPageData(pageName);

		if (!columnFieldName) {
			pageData.pageColumnSortingOptions = undefined;
			return;
		}

		pageData.pageColumnSortingOptions = {
			columnFieldName: columnFieldName,
			sortingOrder: orderDirection,
		};
	}

	getColumnSortingOrder(pageName: string, fieldName: string): 'asc' | 'desc' | undefined {
		const pageData = this.getPageData(pageName);
		if (!pageData.pageColumnSortingOptions)
			return undefined;

		if (pageData.pageColumnSortingOptions.columnFieldName === fieldName)
			return pageData.pageColumnSortingOptions.sortingOrder;

		return undefined;
	}

	setPaginationPageSize(pageName: string, pageSize: number) {
		const pageData = this.getPageData(pageName);
		pageData.tablePaginationDetails.pageSize = pageSize;
	}

	getPaginationPageSize(pageName: string): number | undefined {
		const pageData = this.getPageData(pageName);
		return pageData.tablePaginationDetails.pageSize;
	}

	setPaginationPageNumber(pageName: string, pageNumber: number) {
		const pageData = this.getPageData(pageName);
		pageData.tablePaginationDetails.pageNumber = pageNumber;
	}

	getPaginationPageNumber(pageName: string): number | undefined {
		const pageData = this.getPageData(pageName);
		return pageData.tablePaginationDetails.pageNumber;
	}
}
