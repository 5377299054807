import { inject, injectable } from 'inversify';

import * as D from './distanceService';

import { Service } from './service';

const noReadingMessage = 'No reading available';

@injectable()
export class TelematicsService {
	constructor(
		@inject(Service.DistanceService) private _distance: D.DistanceService,
	) {
	}

	getFormattedEngineTimeTelematicsReadingValue(currentTelematicsValue: number | undefined) {
		return `Asset current engine time reading: ${currentTelematicsValue != null ? `${Math.round(currentTelematicsValue / 60)} hrs` : noReadingMessage}`;
	}

	getFormattedOdometerTelematicsReadingValue(currentTelematicsValue: number | undefined, distanceUnit: D.DistanceUnit) {
		const metricSuffix = distanceUnit == D.DistanceUnit.Miles ? 'mi' : 'km';
		return `Asset current odometer reading: ${currentTelematicsValue != null ? `${this._distance.getDistance(currentTelematicsValue, distanceUnit)} ${metricSuffix}` : noReadingMessage}`;
	}
}
