import React from 'react';

import { Button, FixedWidthPage } from 'src/components';

import {
	AuthenticationService,
	Client as C,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

import { EditUserDetailsComponent } from './editUserDetailsComponent';

import './account.scss';

export const Account = () => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const user = _authService.currentAuth.user;

	const onEditSuccess = () => {
		_toasterService.showSuccess('Account details saved successfully.');
		_authService.checkForCurrentAuthUserUpdate();
	};

	const actionButtons = [];

	if (!_authService.currentAuth.ssoSignIn) {
		actionButtons.push(<Button
			className="fill"
			variant="outlined" color="primary"
			href="/app/account/change-password"
			text="Change Password"
		/>);
	}

	if (user.identity.type == C.IdentityType.Client) {
		actionButtons.push(<Button
			href={`/app/users/${user.userId}/settings/alerts`}
			text="Manage Alert & Emergency Settings"
			className="fill"
			variant="outlined" color="primary"
		/>);
	}

	return <FixedWidthPage
		className="form-page account"
		headingText="Account"
		subheadingText={user.name}
		noContentBackground
	>
		{actionButtons.length > 0 && <div className="content-box actions">
			{actionButtons}
		</div>}

		<div className="content-box">
			<EditUserDetailsComponent
				user={user}
				onEditSuccess={onEditSuccess}
			/>
		</div>
	</FixedWidthPage>;
};
