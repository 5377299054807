import React, { useState } from 'react';
import { observer } from 'mobx-react';
import queryString from 'query-string';
import { Formik, FormikHelpers, FormikProps, Form, Field, FormikErrors } from 'formik';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import useAsyncEffect from 'use-async-effect';

import {
	AuthenticationService,
	HistoryService,
	Service,
	ToasterService,
	UsersService,
	useInjection,
} from 'src/services';

import { Button, FormikTextField, MessagePage } from 'src/components';
import { runFormValidation } from 'src/util';

interface ActivateFormValues {
	activationCode: string;
	emailAddress: string;
	password: string;
	passwordConfirm: string;
}

const validateForm = (values: ActivateFormValues, errors: FormikErrors<ActivateFormValues>) => {
	if (!values.activationCode)
		errors.activationCode = 'Activation code is required.';

	if (!values.emailAddress)
		errors.emailAddress = 'Email address is required.';

	if (!values.password)
		errors.password = 'Password is required.';
	else if (values.password.length < 8)
		errors.password = 'Password must be at least 8 characters.';

	if (!values.passwordConfirm)
		errors.passwordConfirm = 'Password is required.';
	else if (values.passwordConfirm !== values.password)
		errors.passwordConfirm = 'Passwords must match.';
};

export const Activate = observer(() => {
	const _historyService = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);
	const _usersService = useInjection<UsersService>(Service.Users);

	const queryStringDetails = queryString.parse(_historyService.history.location.search);

	const initialFormValues = {
		emailAddress: queryStringDetails.emailAddress as string || '',
		activationCode: queryStringDetails.activationCode as string || '',
		password: '',
		passwordConfirm: '',
	};

	const onSubmit = async (values: ActivateFormValues, { setSubmitting }: FormikHelpers<ActivateFormValues>) => {
		try {
			await _usersService.activateAccount({
				emailAddress: values.emailAddress,
				activationToken: values.activationCode,
				password: values.password,
			});

			_toasterService.showSuccess('Account successfully activated, please sign in with your account details to continue.');
			_historyService.history.push('/auth/sign-in');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to activate account.');
			setSubmitting(false);
		}
	};

	return <div className="auth-activate">
		<h1>Activate account</h1>

		<Formik
			initialValues={initialFormValues}
			validate={values => runFormValidation(values, validateForm)}
			validateOnBlur={false}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<ActivateFormValues>) => <Form className="formik-form">
				<Field
					name="emailAddress"
					label="Email Address"
					type="email"
					component={FormikTextField}
				/>

				<Field
					name="activationCode"
					label="Activation Code"
					type="text"
					component={FormikTextField}
				/>

				<Field
					name="password"
					label="Password"
					type="password"
					component={FormikTextField}
				/>

				<Field
					name="passwordConfirm"
					label="Confirm Password"
					type="password"
					component={FormikTextField}
				/>

				<div className="auth-action">
					<Button
						type="submit" variant="contained" color="primary"
						text="Activate account"
						loading={formikProps.isSubmitting}
					/>
				</div>
			</Form>}
		/>
	</div>;
});
