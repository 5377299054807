import moment from 'moment-timezone';
import React from 'react';
import { bind } from 'bind-decorator';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { LngLatBounds, LngLat } from 'mapbox-gl';
import classNames from 'classnames';
import moize from 'moize';
import { ResponsiveLine, Serie } from '@nivo/line';

import ButtonGroup from '@material-ui/core/ButtonGroup';
import MenuIcon from '@material-ui/icons/Menu';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import {
	Container,
	Service,
	Client as C,
	AuthenticationService,
	HistoryService,
	AssetEventsService,
	AssetTypesService,
	FloorPlansService,
	UsersService,
	ToasterService,
} from 'src/services';

import { CurrentMapViewMode } from './historyMapContainer';
import { Button, DateTimeRange, MessagePage } from 'src/components';
import { MapboxManager, MapboxMapStyle, mapboxMaxStyleFromUserSetting } from './mapboxManager';
import { MapModeSelector } from './mapModeSelector';
import { HistoryMapMarkerManager } from './historyMapMarkerManager';
import { HistorySlider } from './historySlider';
import { HistoryFilter } from './historyFilter';
import { SelectedAssetInfoBox, InfoBoxDisplayMode } from './selectedAssetInfoBox';
import { getEventTime } from 'src/util/eventHelpers';
import { MapViewMode } from './mapViewMode';
import { MapActionButtons } from './mapActionButtons';
import { mapContainerStyle, mapStyle, fuelChartContainer } from './mapStyles';
import { IMapAsset } from './mapAsset';
import { executeQueryMapAssets } from 'src/graphql/__generated__/queries/queryMapAssets';
import { calculateFuelLevel } from 'src/util/fuelLevel';
import { shortDateShortTimeFormat } from 'src/util/dateTimeFormats';

import './mapPage.scss';
import { ApolloClient, InMemoryCache } from '@apollo/client';

export type HistoryMapProps = {
	assetId?: string | null;
	dateTimeRange: DateTimeRange;
	sliderDateTime: moment.Moment;
	onChangeFilters: (assetId: string | null, dateTimeRange: DateTimeRange) => void;
	onChangeSliderDateTime: (sliderDateTime: moment.Moment) => void;
};

interface IFuelChartDataPoint {
	x: Date;
	y: number;
	event: C.IAssetEventDto;
}

export interface IHistoryData {
	asset: IMapAsset;
	events: C.IAssetEventDto[];
	eventsIncludingPrevious: C.IAssetEventDto[];
	previousLocationEvent: C.IAssetEventDto | null;
	deviceIoConfigurations: C.IDeviceIoConfigurationDto[] | null;
	locationEventsWorldBounds: LngLatBounds;
	fuelChartSeries: Serie[];
}

export interface IEventsBeforeTime {
	event?: C.IAssetEventDto;
	eventWithLocation?: C.IAssetEventDto;
}

interface State {
	pageInitialLoading: boolean;

	loadingHistory: boolean;
	historyData?: IHistoryData;
	historyError?: string;
	playSpeed: number;

	floorPlan?: C.IFloorPlanDto;
	showFloorPlanOnWorld: boolean;

	worldMapStyle: MapboxMapStyle;
	mapViewMode: CurrentMapViewMode;

	mapMenuOpen: boolean;
}

@observer
export class HistoryMap extends React.Component<HistoryMapProps, State> {
	private _apolloClient = Container.get<ApolloClient<InMemoryCache>>(Service.ApolloClient);
	private _assetEventsService = Container.get<AssetEventsService>(Service.AssetEvents);
	private _assetTypesService = Container.get<AssetTypesService>(Service.AssetType);
	private _authenticationService = Container.get<AuthenticationService>(Service.Authentication);
	private _floorPlanService = Container.get<FloorPlansService>(Service.FloorPlans);
	private _historyService = Container.get<HistoryService>(Service.History);
	private _toasterService = Container.get<ToasterService>(Service.Toaster);
	private _usersService = Container.get<UsersService>(Service.Users);

	private _mapboxManager?: MapboxManager;
	private _mapMarkerManager?: HistoryMapMarkerManager;
	@observable private _userMapSettings: C.IUserMapSettingsDto;

	@observable private _assets = new Map<string, IMapAsset>();

	private _playInterval: any = undefined;
	private _playIndex: number = 0;

	constructor(props: any) {
		super(props);
		makeObservable(this);

		this.state = {
			pageInitialLoading: true,
			loadingHistory: true,
			showFloorPlanOnWorld: true,
			worldMapStyle: MapboxMapStyle.Streets,
			mapViewMode: {
				currentMapViewMode: MapViewMode.World,
				preferredMapViewMode: MapViewMode.FloorPlan,
			},
			mapMenuOpen: false,
			playSpeed: 0,
		};
	}

	/**
	 * Move to the bounds of the history data (if any - otherwise show whole world).
	 */
	private moveToHistoryBounds() {
		if (this.state.historyData && !this.state.historyData.locationEventsWorldBounds.isEmpty()) {
			const bounds = this.state.historyData.locationEventsWorldBounds;
			this._mapboxManager!.fitBounds(bounds, {
				maxZoom: 17,
				padding: {
					top: 50,
					left: 50,
					bottom: 50,
					right: 50,
				}
			});
		} else {
			this._mapboxManager!.fitBoundsDefault();
		}
	}

	async componentDidUpdate(prevProps: HistoryMapProps, prevState: State) {
		if (prevProps.assetId !== this.props.assetId ||
			!prevProps.dateTimeRange.start.isSame(this.props.dateTimeRange.start) ||
			!prevProps.dateTimeRange.end.isSame(this.props.dateTimeRange.end)) {
			await this.loadHistory();
		}

		if (prevProps.sliderDateTime !== this.props.sliderDateTime) {
			await this.updateSliderDateTimeSingle();
		}

		if (prevProps.assetId != this.props.assetId && this._mapMarkerManager)
			this._mapMarkerManager.setSelectedAsset(this.props.assetId ? this._assets.get(this.props.assetId) : null);
	}

	private async initialise(mapboxManager: MapboxManager) {
		const identityType = this._authenticationService.currentAuth.user.identity.type;
		const assetsQuery = await executeQueryMapAssets(this._apolloClient);
		if (assetsQuery.error || !assetsQuery.data?.assets)
			throw assetsQuery.error;

		for (const asset of assetsQuery.data.assets) {
			this._assets.set(asset.id, {
				assetId: asset.id,
				name: asset.name,
				client: asset.client,
				assetType: asset.assetType,
				devices: asset.devices,
			});
		}

		const assetTypes = await this._assetTypesService.listAssetTypes();

		this._mapboxManager = mapboxManager;
		this._mapMarkerManager = new HistoryMapMarkerManager(this._mapboxManager.map, assetTypes, this.onClickAssetEvent);
		this._mapMarkerManager.setSelectedAsset(this.props.assetId ? this._assets.get(this.props.assetId) : null);

		this._userMapSettings = await this._usersService.getUserMapSettings(this._authenticationService.currentAuth.user.userId);
		const mapStyle = mapboxMaxStyleFromUserSetting(this._userMapSettings.defaultMapStyle);
		await this._mapboxManager.world(mapStyle);

		await new Promise(resolve => {
			this.setState({
				worldMapStyle: mapStyle,
			}, resolve as any);
		});

		await this.loadHistory();

		this.setState({
			pageInitialLoading: false,
		});
	}

	@bind
	private onClickAssetEvent(assetEventId: string) {
		if (!this.state.historyData)
			return;

		const assetEvent = this.state.historyData.events.find(x => x.assetEventId === assetEventId);
		if (!assetEvent)
			return;

		this.props.onChangeSliderDateTime(getEventTime(assetEvent));
	}

	@bind
	private setWorldFloorPlanVisible(visible: boolean) {
		if (visible && this.state.floorPlan) {
			this._mapboxManager!.addFloorPlanToWorld(this.state.floorPlan);
		} else {
			this._mapboxManager!.removeFloorPlan();
		}

		this.setState({ showFloorPlanOnWorld: visible });
	}

	@bind
	private async mapRefChanged(element: HTMLDivElement | null) {
		if (!element)
			return;

		if (!this._authenticationService.currentAuth.permissions.general.viewAssetLocations) {
			this._historyService.history.replace('/app');
			return;
		}

		if (this._mapboxManager)
			return;

		const mapboxManager = new MapboxManager(element, { hasTopRightMenu: true, metric: this._authenticationService.currentAuth.user.usesMetric });
		await this.initialise(mapboxManager);
	}

	@bind
	private async loadHistory() {
		this.setState({
			loadingHistory: true,
			historyData: undefined,
			historyError: undefined,
		});

		let historyData: IHistoryData | undefined = undefined;
		let historyError: string | undefined = undefined;

		try {
			if (this.props.assetId) {
				const asset = this._assets.get(this.props.assetId);

				if (asset) {
					this._mapMarkerManager!.setSelectedAsset(this._assets.get(this.props.assetId!));
					const history = await this._assetEventsService.fetchHistory(this.props.assetId, this.props.dateTimeRange.start, this.props.dateTimeRange.end, this._floorPlanService.floorPlans);

					if (history)
						historyData = this.buildHistoryData(asset, history);
				}
			}
		} catch (e) {
			this._toasterService.handleWithToast(e, 'Failed to load history.');

			if (e instanceof C.ErrorResponse && e.errors.length > 0)
				historyError = e.errors[0];
		}

		await new Promise(resolve => {
			this.setState({
				loadingHistory: false,
				historyData: historyData,
				historyError: historyError,
			}, resolve as any);
		});

		await this.updateSliderDateTimeSingle();

		if (this.state.mapViewMode.currentMapViewMode === MapViewMode.World)
			this.moveToHistoryBounds();

		if (this.state.historyData) {
			this._mapboxManager!.setTrail(this.state.historyData.events);
			this._mapMarkerManager?.setEventMarkers(this.state.historyData.events);
		}
	}

	@bind
	private buildHistoryData(asset: IMapAsset, history: C.IAssetEventHistory): IHistoryData {
		const worldBounds = new LngLatBounds();
		const fuelChartDataPoints: IFuelChartDataPoint[] = [];

		for (const event of history.events) {
			if (event.assetLocation) {
				const lngLat = new LngLat(event.assetLocation.location.longitude, event.assetLocation.location.latitude);
				worldBounds.extend(lngLat);
			}

			if (event.properties?.fuelLevelPoC?.value != null) {
				const fuelLevel = calculateFuelLevel(event.assetId, event.properties.fuelLevelPoC.value);
				if (fuelLevel !== null) {
					fuelChartDataPoints.push({
						x: getEventTime(event).toDate(),
						y: fuelLevel,
						event,
					});
				}
			}
		}

		const historyIncludingPrevious = [...history.events];
		if (history.previousLocationEvent) {
			if (history.previousLocationEvent.assetLocation) {
				const lngLat = new LngLat(history.previousLocationEvent.assetLocation.location.longitude, history.previousLocationEvent.assetLocation.location.latitude);
				worldBounds.extend(lngLat);
			}

			historyIncludingPrevious.unshift(history.previousLocationEvent);
		}

		return {
			asset: asset,
			events: history.events,
			eventsIncludingPrevious: historyIncludingPrevious,
			previousLocationEvent: history.previousLocationEvent ?? null,
			deviceIoConfigurations: history.deviceIoConfigurations ?? null,
			locationEventsWorldBounds: worldBounds,
			fuelChartSeries: fuelChartDataPoints.length === 0 ? [] : [{
				id: 1,
				data: fuelChartDataPoints,
			}],
		};
	}

	@bind
	private async updateSliderDateTime() {
		if (!this.state.historyData) {
			this._mapboxManager?.setTrail(undefined);
			this._mapMarkerManager?.setEventMarkers(undefined);
			this._mapMarkerManager?.setSelectedEvents(undefined);
			await this.setMapView({ mapViewMode: MapViewMode.World, worldMapStyle: this.state.worldMapStyle });
			return;
		}

		const eventsBeforeSelectedTime = this.eventsBeforeSelectedTime(this.state.historyData, this.props.sliderDateTime);

		let floorPlan: C.IFloorPlanDto | null = null;
		if (eventsBeforeSelectedTime.eventWithLocation && eventsBeforeSelectedTime.eventWithLocation.assetLocation!.floorPlanLocation)
			floorPlan = this._floorPlanService.floorPlans.get(eventsBeforeSelectedTime.eventWithLocation.assetLocation!.floorPlanLocation!.floorPlanId) || null;

		await this.setMapView({ floorPlan });

		this._mapMarkerManager!.setSelectedEvents(eventsBeforeSelectedTime);
	}

	private _updateSliderTask: Promise<void> | undefined;
	private _updateSliderQueueTask: Promise<unknown> | undefined;

	@bind
	async updateSliderDateTimeSingle() {
		// No current update task, we can do the update.
		if (!this._updateSliderTask) {
			this._updateSliderTask = this.updateSliderDateTime();
			await this._updateSliderTask;
			this._updateSliderTask = undefined;
			return;
		}

		// Already a queued update, we can rely on that one.
		if (this._updateSliderQueueTask) {
			await this._updateSliderQueueTask;
			return;
		}

		// No queued update, we'll do the queue job.

		this._updateSliderQueueTask = new Promise(async _ => {
			await this._updateSliderTask;
		});

		await this._updateSliderQueueTask;
		this._updateSliderQueueTask = undefined;

		this._updateSliderTask = this.updateSliderDateTime();
		await this._updateSliderTask;
		this._updateSliderTask = undefined;
	}

	@bind
	private async toggleWorldMapStyle() {
		const newMapStyle = this.state.worldMapStyle === MapboxMapStyle.Streets ? MapboxMapStyle.Satellite : MapboxMapStyle.Streets;
		await this.setMapView({ worldMapStyle: newMapStyle });
	}

	@bind
	async setPreferredMapViewMode(mapViewMode: MapViewMode) {
		this.setState({
			mapViewMode: {
				...this.state.mapViewMode,
				preferredMapViewMode: mapViewMode,
			},
		});

		await this.setMapView({ mapViewMode });
	}

	@bind
	private async setMapView(options: { mapViewMode?: MapViewMode, floorPlan?: C.IFloorPlanDto | null, worldMapStyle?: MapboxMapStyle }): Promise<void> {
		const floorPlan = options.floorPlan === undefined ? this.state.floorPlan : options.floorPlan;
		const worldMapStyle = options.worldMapStyle === undefined ? this.state.worldMapStyle : options.worldMapStyle;

		// The map view mode might get forced to change due to floor plan viewing preference, or lack of a floor plan.
		let mapViewMode = options.mapViewMode;
		if (mapViewMode === undefined) {
			mapViewMode = this.state.mapViewMode.currentMapViewMode;

			if (mapViewMode === MapViewMode.World && floorPlan && this.state.mapViewMode.preferredMapViewMode === MapViewMode.FloorPlan)
				mapViewMode = MapViewMode.FloorPlan;
			else if (mapViewMode === MapViewMode.FloorPlan && !floorPlan)
				mapViewMode = MapViewMode.World;
		}

		// If nothing has changed, we don't need to do anything.
		if (mapViewMode === this.state.mapViewMode.currentMapViewMode &&
			floorPlan === this.state.floorPlan &&
			worldMapStyle == this.state.worldMapStyle)
			return;

		if (mapViewMode === MapViewMode.World) {
			if (this.state.mapViewMode.currentMapViewMode !== MapViewMode.World || this.state.worldMapStyle !== worldMapStyle)
				await this._mapboxManager!.world(worldMapStyle);

			// Don't need to move the map if only the floor plan is changing.
			if (this.state.mapViewMode.currentMapViewMode !== MapViewMode.World)
				this.moveToHistoryBounds();

			this._mapboxManager!.removeFloorPlan();

			if (this.state.showFloorPlanOnWorld && floorPlan)
				this._mapboxManager!.addFloorPlanToWorld(floorPlan);
		} else if (mapViewMode === MapViewMode.FloorPlan) {
			await this._mapboxManager!.viewFloorPlan(floorPlan!);
		}

		const newStateMapViewMode = {
			...this.state.mapViewMode,
			currentMapViewMode: mapViewMode,
		};

		await new Promise(resolve => {
			this.setState({
				mapViewMode: newStateMapViewMode,
				floorPlan: floorPlan || undefined,
				worldMapStyle: worldMapStyle,
			}, resolve as any);
		});

		this._mapMarkerManager!.setMapView(this.state.mapViewMode.currentMapViewMode, this.state.floorPlan);
	}

	@bind
	getEventsBeforeTime(events: C.IAssetEventDto[] | undefined | null, beforeTime: moment.Moment): IEventsBeforeTime {
		const eventsBeforeTime: IEventsBeforeTime = {};

		if (!events || events.length == 0)
			return eventsBeforeTime;

		for (let i = events.length - 1; i >= 0; i--) {
			const event = events[i];

			if (getEventTime(event).isSameOrBefore(beforeTime)) {
				if (!eventsBeforeTime.event)
					eventsBeforeTime.event = event;

				if (!eventsBeforeTime.eventWithLocation && event.assetLocation)
					eventsBeforeTime.eventWithLocation = event;
			}

			if (eventsBeforeTime.event && eventsBeforeTime.eventWithLocation)
				break;
		}

		return eventsBeforeTime;
	}

	@bind
	toggleMapMenu() {
		this.setState({ mapMenuOpen: !this.state.mapMenuOpen });
	}

	@bind
	private setPlaySpeed(speed: number) {
		// Clear the existing interval if there is one.
		if (this._playInterval) {
			clearInterval(this._playInterval);
			this._playInterval = null;
		}

		if (speed === 0 || !this.state.historyData || this.state.historyData.events.length === 0) {
			this.setState({ playSpeed: 0 });
			return;
		}

		this.setState({ playSpeed: speed });

		// Find the index of the currently selected event.
		const currentEvents = this.eventsBeforeSelectedTime(this.state.historyData, this.props.sliderDateTime);
		if (!currentEvents.event) {
			this._playIndex = 0;
		} else {
			const eventIndex = this.state.historyData.events.indexOf(currentEvents.event);
			if (eventIndex >= 0 && eventIndex < this.state.historyData.events.length - 1)
				this._playIndex = eventIndex;
			else
				this._playIndex = 0;
		}

		// Move the slider to the time of the first event.
		const event = this.state.historyData.events[this._playIndex];
		this.props.onChangeSliderDateTime(getEventTime(event));

		this._playInterval = setInterval(() => {
			if (!this.state.historyData)
				return;

			const previousPlayIndex = this._playIndex;
			this._playIndex += this.state.playSpeed;
			if (this._playIndex < 0 || this._playIndex >= this.state.historyData.events.length) {
				// Stop if we've reached the last event.
				// If we've made it to the end, but we're not quite at the last event, go to that one first.
				if (previousPlayIndex === this.state.historyData.events.length - 1) {
					this.setPlaySpeed(0);
					return;
				} else {
					this._playIndex = this.state.historyData.events.length - 1;
				}
			}

			// Move the slider to the time of the new event.
			const event = this.state.historyData.events[this._playIndex];
			this.props.onChangeSliderDateTime(getEventTime(event));
		}, 1000 / speed);
	}

	eventsBeforeSelectedTime = moize(
		(historyData: IHistoryData | undefined, time: moment.Moment) => {
			if (!historyData)
				return {};

			return this.getEventsBeforeTime(historyData.eventsIncludingPrevious, time);
		}
	);

	mapViewModeOptions = moize(
		(events?: C.IAssetEventDto[] | null) => {
			const result = new Set<MapViewMode>([MapViewMode.World]);
			if (!events)
				return result;

			if (events.some(x => x.assetLocation && !!x.assetLocation!.floorPlanLocation))
				result.add(MapViewMode.FloorPlan);

			return result;
		}
	);

	mapViewModeOptionsDisabled = moize(
		(floorPlan?: C.IFloorPlanDto | null) => floorPlan ? new Set<MapViewMode>() : new Set<MapViewMode>([MapViewMode.FloorPlan])
	);

	render() {
		const noHistory = !this.state.loadingHistory && (!this.state.historyData || this.state.historyData.events.length == 0);
		const filtersAllSet = this.props.assetId && this.props.dateTimeRange;
		const showFuelChart = this.state.historyData?.fuelChartSeries && this.state.historyData.fuelChartSeries.length > 0;

		let assetOverlay = null;

		const eventsBeforeSelectedTime = this.eventsBeforeSelectedTime(this.state.historyData, this.props.sliderDateTime);

		if (eventsBeforeSelectedTime.event) {
			const isPreviousLocationEvent = this.state.historyData?.previousLocationEvent && eventsBeforeSelectedTime.eventWithLocation === this.state.historyData.previousLocationEvent;

			let deviceIoConfigurationDetails : C.IDeviceIoConfigurationDto | null = null;
			if (this.state.historyData?.deviceIoConfigurations && eventsBeforeSelectedTime.event?.properties?.emergency?.ioConfigurationId) {
				deviceIoConfigurationDetails = this.state.historyData.deviceIoConfigurations.find(x => x.deviceIoConfigurationId === eventsBeforeSelectedTime!.event!.properties!.emergency!.ioConfigurationId) ?? null;
			}

			assetOverlay = <SelectedAssetInfoBox
				asset={this.state.historyData!.asset}
				displayMode={InfoBoxDisplayMode.LocationOnly}
				fuelLevelPoC={eventsBeforeSelectedTime.event?.properties?.fuelLevelPoC?.value ?? undefined}
				assetLocation={eventsBeforeSelectedTime.eventWithLocation?.assetLocation || undefined}
				eventProperties={eventsBeforeSelectedTime.eventWithLocation?.properties || undefined}
				emergency={eventsBeforeSelectedTime.event.type === C.AssetEventType.Emergency || (!!eventsBeforeSelectedTime.event.properties?.emergency?.emergencyActive)}
				emergencyType={eventsBeforeSelectedTime.event?.properties?.emergency?.emergencyType || undefined}
				emergencyDeviceIoConfigurationName={deviceIoConfigurationDetails?.name || undefined}
				timestamp={getEventTime(eventsBeforeSelectedTime.event)}
				eventType={eventsBeforeSelectedTime.event.type || undefined}
				floorPlanVisible={this.state.showFloorPlanOnWorld}
				onChangeFloorPlanVisible={this.state.mapViewMode.currentMapViewMode === MapViewMode.World ? this.setWorldFloorPlanVisible : undefined}
				subtitleBannerText={isPreviousLocationEvent ? 'Currently displaying the last known location of the asset before the selected period of history.' : undefined}
			/>;
		}

		return <div className="flex-fill history-page">
			<div className="flex-fill history-page__content">
				<div className={mapContainerStyle}>
					<div className="current-map__overlay no-sidebar">
						<div>
							{assetOverlay}
						</div>

						<div className="bottom-overlay">
							<MapModeSelector
								mapViewModeOptions={this.mapViewModeOptions(this.state.historyData && this.state.historyData.eventsIncludingPrevious)}
								mapViewModeOptionsDisabled={this.mapViewModeOptionsDisabled(this.state.floorPlan)}
								mapViewMode={this.state.mapViewMode.currentMapViewMode}
								onMapViewModeChange={this.setPreferredMapViewMode}
							/>
						</div>

						<div className={classNames('current-map__menu', { 'current-map__menu--open': this.state.mapMenuOpen })}>
							<button
								className="current-map__menu__toggle"
								type="button"
								onClick={this.toggleMapMenu}
							>
								<MenuIcon />
							</button>

							<div className={classNames('current-map__menu__options', { 'current-map__menu__options--open': this.state.mapMenuOpen })}>
								<button
									className="current-map__menu__option__add-geofence"
									type="button"
									title="Add Geofence"
									disabled={true}
								>
									<FormatShapesIcon />
								</button>
							</div>
						</div>

						<MapActionButtons
							mapViewMode={this.state.mapViewMode.currentMapViewMode}
							mapStyle={this.state.worldMapStyle}
							toggleMapStyle={this.toggleWorldMapStyle}
						/>
					</div>

					<div className={mapStyle} ref={this.mapRefChanged} />
				</div>

				{showFuelChart && <div className={fuelChartContainer}>
					<ResponsiveLine
						data={this.state.historyData!.fuelChartSeries!}
						margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
						xScale={{
							type: 'time',
							format: 'native',
							min: this.props.dateTimeRange.start.toDate(),
							max: this.props.dateTimeRange.end.toDate(),
						}}
						yScale={{
							type: 'linear',
							min: 0,
							max: 'auto',
						}}
						axisLeft={{
							legend: 'litres',
							legendPosition: 'middle',
							legendOffset: -50,
						}}
						axisBottom={{
							format: '%H:%M',
							legendPosition: 'middle',
							legendOffset: 46,
						}}
						useMesh={true}
						tooltip={input => <div style={{ textAlign: 'center' }}>
							{getEventTime((input.point.data as any as IFuelChartDataPoint).event).format(shortDateShortTimeFormat)}<br />
							{input.point.data.y} litres
						</div>}
					/>
				</div>}

				<div
					key="history-controls"
					className="history-controls"
				>
					<div className="history-filter-container">
						<div className="history-filter fixed-width">
							<HistoryFilter
								assetId={this.props.assetId}
								dateTimeRange={this.props.dateTimeRange}
								assets={Array.from(this._assets.values())}
								disabled={this.state.loadingHistory || this.state.playSpeed !== 0}
								onChange={this.props.onChangeFilters}
							/>

							<ButtonGroup
								className="filter"
								size="small"
								disabled={!this.state.historyData || this.state.historyData.events.length === 0}
							>
								<Button
									text={<FastRewindIcon />}
									disabled={this.state.playSpeed < 2}
									onClick={() => this.setPlaySpeed(this.state.playSpeed - 1)}
								/>

								<Button
									text={this.state.playSpeed === 0 ? <PlayArrowIcon /> : <PauseIcon />}
									onClick={this.state.playSpeed === 0 ? () => this.setPlaySpeed(1) : () => this.setPlaySpeed(0)}
								/>

								<Button
									text={<FastForwardIcon />}
									disabled={this.state.playSpeed === 0}
									onClick={() => this.setPlaySpeed(this.state.playSpeed + 1)}
								/>
							</ButtonGroup>
						</div>
					</div>

					<HistorySlider
						min={this.props.dateTimeRange.start}
						max={this.props.dateTimeRange.end}
						events={this.state.historyData && this.state.historyData.events}
						value={this.props.sliderDateTime}
						noHistory={noHistory || !filtersAllSet}
						loading={this.state.loadingHistory}
						error={this.state.historyError}
						onValueUpdated={this.props.onChangeSliderDateTime}
						disabled={this.state.playSpeed !== 0}
						loadHistory={this.loadHistory}
					/>
				</div>
			</div>

			<div className={classNames('live-map--loader', { 'live-map--loader__loaded': !this.state.pageInitialLoading })}>
				<MessagePage
					title="Loading history map..."
					loading
				/>
			</div>
		</div>;
	}
}
