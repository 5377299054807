import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';

import {
	Client as C,
	UserGroupService,
	Service,
	useCurrentUser,
	useInjection,
} from 'src/services';

import { SelectItemsDialog } from 'src/components/selectItemsDialog';

interface Props {
	userGroupId: string;
	assetGroups: C.IAssetGroupDto[];
	existing: C.IAssetGroupDto[];
	complete: () => void;
	closeDialog: () => void;
}

export const AddAssetGroupsDialog = observer((props: Props) => {
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);
	const currentUser = useCurrentUser();

	const [saving, setSaving] = useState<boolean>(false);
	const [savingError, setSavingError] = useState<boolean>(false);

	const items = useMemo(() => {
		const exclude = new Set<string>(props.existing.map(x => x.assetGroupId));

		return props.assetGroups
			.filter(x => x.name.trim().length !== 0 && !exclude.has(x.assetGroupId))
			.map(x => ({
				id: x.assetGroupId,
				name: (currentUser!.identity.type === C.IdentityType.SuperUser || currentUser!.identity.type === C.IdentityType.Dealer) ? `${x.name} (${x.client.name})` : x.name,
			}))
			.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));
	}, [props.assetGroups, props.existing]);

	const addAssetGroups = async (assetGroupIds: string[]) => {
		setSaving(true);

		try {
			await _userGroupService.updateAssetGroups(props.userGroupId, {
				addAssetGroupIds: assetGroupIds,
			});

			props.complete();
			props.closeDialog();
		} catch (err) {
			setSavingError(true);
			setSaving(false);
		}
	};

	return <SelectItemsDialog
		title="Select asset groups to add"
		complete={addAssetGroups}
		close={props.closeDialog}
		saving={saving}
		savingError={savingError}
		items={items}
	/>;
});
