import { LiveMapFilter } from './liveMapAssetFilterDialog';
import bind from 'bind-decorator';
import { action, makeObservable, observable } from 'mobx';

import {
	Container,
	AuthenticationService,
	Client as C,
	Service,
} from 'src/services';

import { LiveMapAssetState } from './liveMapAssetState';
import { longDateTimeFormat } from 'src/util/dateTimeFormats';
import { getSingleLineLocationSummary } from 'src/util/locationFormats';
import { IMapAsset } from './mapAsset';

import { QueryAssetServiceRemindersForLiveMap_assetServiceReminders } from 'src/graphql/__generated__/queries/queryAssetServiceRemindersForLiveMap';

export interface SidebarAsset {
	asset: IMapAsset;
	assetState: LiveMapAssetState;
	locationText?: string;
	assetLocation?: C.IAssetLocationDto;
	status: 'offline' | 'online' | 'danger' | 'warning';
	statusText: string;
	selected?: boolean;
	safetyTimer?: C.ISafetyTimerDto;
	userName?: string;
	attachmentName?: string;
}

export class LiveMapSidebarManager {
	private _authenticationService = Container.get<AuthenticationService>(Service.Authentication);

	private _allSidebarAssets = new Map<string, SidebarAsset>();
	private _orderedSidebarAssets: SidebarAsset[] = [];
	@observable sidebarAssets: SidebarAsset[] = [];
	@observable assetServiceReminders: QueryAssetServiceRemindersForLiveMap_assetServiceReminders[] = [];

	@observable liveMapFilter?: LiveMapFilter;
	@observable assetSearchQuery: string | null;
	private _assetGroups: C.IAssetGroupDto[] = [];

	constructor() {
		makeObservable(this);
	}

	public dispose() {

	}

	@bind
	private updateStatus(sidebarAsset: SidebarAsset): boolean {
		if (!sidebarAsset.assetState.latestEventTimestamp)
			return false;

		const previousStatus = sidebarAsset.status;

		const latestEventTime = sidebarAsset.assetState.latestEventTimestamp;
		sidebarAsset.statusText = `Last communication: ${latestEventTime.tz(this._authenticationService.currentAuth.user.timeZone).format(longDateTimeFormat)}`;

		if (sidebarAsset.assetState.hasEmergency) {
			switch (sidebarAsset.assetState.emergencyType) {
				case C.EmergencyType.PriorityAlert:
					sidebarAsset.statusText = `Asset has triggered a priority alert.\n${sidebarAsset.statusText}`;
					sidebarAsset.status = 'warning';
					break;

				default:
					sidebarAsset.statusText = `Asset has triggered an emergency alert.\n${sidebarAsset.statusText}`;
					sidebarAsset.status = 'danger';
					break;
			}

		} else {
			sidebarAsset.status = sidebarAsset.assetState.isOnline ? 'online' : 'offline';
		}

		return sidebarAsset.status !== previousStatus;
	}

	@bind
	private getLocationText(assetState: LiveMapAssetState): string | undefined {
		if (assetState.currentFloorPlan)
			return assetState.currentFloorPlan.name;

		const assetLocation = assetState.assetLocation;
		if (!assetLocation)
			return undefined;

		if (assetLocation.locationDetails) {
			const summary = getSingleLineLocationSummary(assetLocation.locationDetails);
			if (summary)
				return summary;
		}

		return undefined;
	}

	@bind
	private updateLocation(sidebarAsset: SidebarAsset) {
		const previous = sidebarAsset.assetLocation;
		const next = sidebarAsset.assetState.assetLocation;

		if (!next || previous === next)
			return;

		sidebarAsset.locationText = this.getLocationText(sidebarAsset.assetState);
		sidebarAsset.assetLocation = sidebarAsset.assetState.assetLocation;
	}

	initialize(liveAssetStates: LiveMapAssetState[], assetGroups: C.IAssetGroupDto[], assetServiceReminders: QueryAssetServiceRemindersForLiveMap_assetServiceReminders[]) {
		this._assetGroups = assetGroups;
		this.assetServiceReminders = assetServiceReminders;

		for (const assetState of liveAssetStates) {
			const sidebarAsset: SidebarAsset = observable<SidebarAsset>({
				asset: assetState.asset,
				assetState: assetState,
				selected: assetState.isSelected,
				status: 'offline',
				statusText: 'Asset has not communicated.',
				locationText: undefined,
				assetLocation: undefined,
				userName: assetState.userName,
				attachmentName: assetState.attachmentName,
			});

			// Call updates methods before changing the sidebar asset so that the update methods can compare the differences.
			this.updateStatus(sidebarAsset);
			this.updateLocation(sidebarAsset);

			sidebarAsset.selected = assetState.isSelected;
			sidebarAsset.safetyTimer = assetState.safetyTimer;

			this._allSidebarAssets.set(sidebarAsset.asset.assetId, sidebarAsset);
		}

		this._orderedSidebarAssets = Array.from(this._allSidebarAssets.values())
			.sort((a, b) => a.asset.name.localeCompare(b.asset.name, undefined, { numeric: true }));

		this.filterAndSortAssets();
	}

	handleAssetEventUpdate(assetState: LiveMapAssetState) {
		const sidebarAsset = this._allSidebarAssets.get(assetState.asset.assetId);
		if (!sidebarAsset)
			return;

		// Call updates methods before changing the sidebar asset so that the update methods can compare the differences.
		const statusChanged = this.updateStatus(sidebarAsset);
		this.updateLocation(sidebarAsset);
		sidebarAsset.userName = assetState.userName;
		sidebarAsset.attachmentName = assetState.attachmentName;

		sidebarAsset.selected = assetState.isSelected;

		const safetyTimerChanged = sidebarAsset.safetyTimer !== assetState.safetyTimer;
		if (safetyTimerChanged)
			sidebarAsset.safetyTimer = assetState.safetyTimer;

		if (statusChanged || safetyTimerChanged) {
			const hiddenByFilter = !this.matchesFilterAndSearch(sidebarAsset);
			if (sidebarAsset.assetState!.hiddenBySearchOrFilter != hiddenByFilter) {
				sidebarAsset.assetState!.hiddenBySearchOrFilter = hiddenByFilter;
			}
			this.filterAndSortAssets();
		}
	}

	@action
	filterAndSortAssets() {
		const emergencyAssets: SidebarAsset[] = [];
		const safetyTimerAssets: SidebarAsset[] = [];
		const otherAssets: SidebarAsset[] = [];

		for (const asset of this._orderedSidebarAssets) {
			if (asset.assetState.hiddenBySearchOrFilter) {
				continue;
			} else if (asset.status === 'danger') {
				emergencyAssets.push(asset);
			} else if (!!asset.safetyTimer) {
				safetyTimerAssets.push(asset);
			} else {
				otherAssets.push(asset);
			}
		}

		this.sidebarAssets = [
			...emergencyAssets,
			...safetyTimerAssets,
			...otherAssets,
		];
	}

	@bind
	updateAssetStatesOnFilterOrSearchUpdate() {
		let filterEmpty = false;
		if ((this.liveMapFilter?.assetGroups?.length == null || this.liveMapFilter.assetGroups.length <= 0)
			&& (this.liveMapFilter?.assetTypes == null || this.liveMapFilter.assetTypes.length <= 0)
			&& !this.liveMapFilter?.lastUpdate
			&& !this.liveMapFilter?.status)
			filterEmpty = true;

		// When the filter is cleared, call clearAssetsFilteredAndSearchedFor with no debounce to update markers instantly.
		if (!this.assetSearchQuery && (!this.liveMapFilter || filterEmpty)) {
			this.clearAssetsFilteredAndSearchedFor();
		} else {
			for (let i = 0; i < this._orderedSidebarAssets.length; i++) {
				const sidebarAsset = this._orderedSidebarAssets[i];
				sidebarAsset.assetState!.hiddenBySearchOrFilter = !this.matchesFilterAndSearch(sidebarAsset);
			}
		}

		this.filterAndSortAssets();
	}

	@bind
	private matchesFilterAndSearch(sideBarAsset: SidebarAsset) {
		let matchesSearch = true;
		if (this.assetSearchQuery != null) {
			const assetNameLower = sideBarAsset.asset.name.toLowerCase();
			matchesSearch = assetNameLower.indexOf(this.assetSearchQuery!.toLowerCase()) > -1;

			// Search by username too.
			if (!matchesSearch && sideBarAsset.userName) {
				const userNameLower = sideBarAsset.userName!.toLowerCase();
				matchesSearch = userNameLower.indexOf(this.assetSearchQuery!.toLowerCase()) > -1;
			}
		}

		const matchesFilter = this.matchesFilter(sideBarAsset);

		return matchesFilter && matchesSearch;
	}

	@bind
	private matchesFilter(sidebarAsset: SidebarAsset) {
		let status = true;
		let assetGroup = true;
		let assetType = true;
		let lastUpdate = true;

		if (this.liveMapFilter?.status)
			status = sidebarAsset.status === (this.liveMapFilter.status === 'Active' ? 'online' : 'offline');

		const filterAssetGroups = this.liveMapFilter?.assetGroups && this._assetGroups.filter(x => this.liveMapFilter!.assetGroups!.includes( x.assetGroupId));
		if (filterAssetGroups && filterAssetGroups.length > 0)
			assetGroup = filterAssetGroups.some(x => x.assetIds && x.assetIds.includes(sidebarAsset.asset.assetId));

		if (this.liveMapFilter?.assetTypes && this.liveMapFilter.assetTypes.length > 0)
			assetType = !!sidebarAsset.asset.assetType?.id && this.liveMapFilter.assetTypes.includes(sidebarAsset.asset.assetType.id);

		if (this.liveMapFilter?.lastUpdate && sidebarAsset.assetState)
			lastUpdate = !!sidebarAsset.assetState.latestEventTimestamp && sidebarAsset.assetState.latestEventTimestamp > this.liveMapFilter.lastUpdate;

		return status && assetGroup && assetGroup && assetType && lastUpdate;
	}

	@bind
	private clearAssetsFilteredAndSearchedFor() {
		this._allSidebarAssets.forEach(sideBarAssets => {
			sideBarAssets.assetState.hiddenBySearchOrFilter = false;
		});
	}
}
