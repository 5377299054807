import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { DialogContentText } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { getPrettyName } from 'src/util';

import AddIcon from '@material-ui/icons/Add';

import { CustomActionDialogBox } from 'src/components/customActionDialogBox';
import { Button, FixedWidthPage, MaterialTable, MessagePage, PopoverMenu, PopoverMenuItem, ThingLoader } from 'src/components';

import {
	AddressBookService,
	AuthenticationService,
	Client as C,
	ClientService,
	Service,
	ToasterService,
	SiteService,
	useInjection,
} from 'src/services';

interface IAddressBookRow {
	name: string;
	addressBookId: string;
	siteName?: string;
	clientName?: string;
	addressBookType: C.AddressBookType;
}

interface IPageData {
	addressBookData: IAddressBookRow[];
}

export const AddressBooksList = observer(() => {
	const addressBookService = useInjection<AddressBookService>(Service.AddressBooks);
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const clientsService = useInjection<ClientService>(Service.Client);
	const siteService = useInjection<SiteService>(Service.Site);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const [addressBookToBeDeleted, setAddressBookToBeDeleted] = React.useState<IAddressBookRow | null>(null);

	const currentUserType = authenticationService.currentAuth.user.identity.type;

	async function load(): Promise<IPageData | null> {
		let addressBooks: C.IAddressBookDto[] = [];

		try {
			addressBooks = await addressBookService.getAllAddressBooks();
		} catch (err) {
			return null;
		}

		let clients: C.IClientDto[] = [];
		let sites: C.ISiteDto[] = [];

		if (currentUserType !== C.IdentityType.Client) {
			clients = await clientsService.getAllClients() || [];
			sites = await siteService.getAllSites() || [];
		}

		const addressBookRows = addressBooks.map((addressBook): IAddressBookRow => {
			const site = sites.find(x => x.siteId == addressBook.siteId);

			return {
				addressBookId: addressBook.addressBookId,
				name: addressBook.name,
				siteName: addressBook.siteId ? site?.name ?? undefined : undefined,
				clientName: clients.find(x => x.clientId == addressBook.clientId)?.name ?? undefined,
				addressBookType: addressBook.type,
			};
		});

		return {addressBookData: addressBookRows};
	}

	function renderActionMenu(addressBookRow: IAddressBookRow): JSX.Element[] {
		const options: JSX.Element[] = [];

		options.push(<PopoverMenuItem
			key="edit"
			text="Edit"
			href={`/app/address-books/${addressBookRow.addressBookId}/edit`}
		/>);

		options.push(<PopoverMenuItem
			key="delete"
			text="Delete"
			onClick={() => setAddressBookToBeDeleted(addressBookRow)}
		/>);

		return options;
	}

	async function handleDelete(addressBookRow: IAddressBookRow, reload: Function) {
		try {
			await addressBookService.deleteAddressBook(addressBookRow.addressBookId);
			toasterService.showSuccess('Successfully deleted the address book selected.');
		} catch (err) {
			toasterService.handleWithToast(err, 'Unable to delete the address book selected.');
		}

		setAddressBookToBeDeleted(null);
		reload();
	}

	function clearDialogState() {
		setAddressBookToBeDeleted(null);
	}

	function renderDialog(addressBookRow: IAddressBookRow, reload: Function) {
		return <CustomActionDialogBox
				title={`Delete ${addressBookRow.name}?`}
				actionButton={<Button
					variant="contained"
					color={red}
					text="Delete Address Book"
					onClick={() => handleDelete(addressBookRow, reload)}
				/>}
				dialogCloseCallback={clearDialogState}
			>
				<DialogContentText>
					If deleted, assets will not have their user's names shown on the live map. <br />
					<strong>This cannot be undone.</strong>
				</DialogContentText>
		</CustomActionDialogBox>;
	}

	return <ThingLoader
		load={load}
		render={(pageData: IPageData, reload: Function) => <FixedWidthPage
			headingText="Address Books"
			headingActions={<>
				{pageData && authenticationService.currentAuth.permissions.general.addAddressBooks && <Button text="Add" startIcon={<AddIcon />} variant="outlined" color="primary" href="/app/address-books/add" />}
			</>}
			noContentBackground
			contentClassName={classNames({ 'flex-fill-no-overflow': pageData.addressBookData.length === 0 })}
		>
			{pageData && pageData.addressBookData.length != 0 && <MaterialTable
				tableName="address-books-list"
				columns={[
					{
						title: 'Name',
						field: 'name',
						grouping: false,
					},
					{
						title: 'Client',
						hidden: currentUserType !== C.IdentityType.SuperUser && currentUserType !== C.IdentityType.Dealer,
						field: 'clientName',
					},
					{
						title: 'Site',
						hidden: currentUserType !== C.IdentityType.SuperUser && currentUserType !== C.IdentityType.Dealer,
						field: 'siteName',
					},
					{
						title: 'Type',
						field: 'addressBookType',
						render: rowData => {
							return getPrettyName(rowData.addressBookType);
						},
					},
					{
						title: 'Options',
						field: 'options',
						filtering: false,
						grouping: false,
						sorting: false,
						headerStyle: ({
							textAlign: 'right',
						}),
						cellStyle: ({
							textAlign: 'right',
						}),
						render: rowData => <PopoverMenu
							renderOptions={() => renderActionMenu(pageData.addressBookData.find(x => x.addressBookId === rowData.addressBookId)!)}
						/>,
					},
				]}
				data={pageData.addressBookData}
				options={{
					toolbar: false,
				}}
			/>}

			{pageData && pageData.addressBookData.length === 0 && <MessagePage
				title="No address books."
				action={authenticationService.currentAuth.permissions.general.addAddressBooks ? <Button href="/app/address-books/add" text="Add a address book?" variant="outlined" /> : undefined}
			/>}

			{addressBookToBeDeleted && renderDialog(addressBookToBeDeleted, reload)}

		</FixedWidthPage>}
	/>;
});
