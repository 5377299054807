import React from 'react';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import { bind } from 'bind-decorator';
import { Card } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { FormikHelpers } from 'formik';
import classNames from 'classnames';
import { css } from '@emotion/css';

import {
	Container,
	Client as C,
	EmergencyService,
	Service,
	ToasterService,
} from 'src/services';

import { MessagePage } from 'src/components';
import { IResolveEmergencyFormValues, EmergencyViewer, IEmergency } from 'src/components/emergency';
import { getLocationParts } from 'src/util/locationFormats';
import { MapboxManager, MapboxMapStyle } from 'src/app/map/mapboxManager';
import { LiveMapMarkerManager } from 'src/app/map/liveMapMarkerManager';

import { EmergencyStatus, EmergencyType } from 'src/../__generated__/globalTypes';

import './viewEmergency.scss';

interface State {
	loading: boolean;
	emergencyInfo?: C.IEmergencyQuickAccessViewResponse;
}

export interface Props {
	match: match<{ id: string }>;
}

const emergencyMapCardStyle = css`
	.map-label {
		padding: 20px;
	}

	.map {
		height: 400px;
		width: 100%;
	}

	.icon-emergency {
		width: 22px;
		height: 22px;
	}
`;

const priorityAlertMapCardStyle = css`
	.map-label {
		padding: 20px;
	}

	.map {
		height: 400px;
		width: 100%;
	}

	.icon-warning {
		width: 22px;
		height: 22px;
	}
`;

const HeaderEmergencyIcon = styled(ErrorIcon)({
	height: '50px',
	width: '50px',
	marginTop: '-5px',
	marginBottom: '-5px',
});

@observer
export class ViewEmergency extends React.Component<Props, State> {
	private _emergencyService = Container.get<EmergencyService>(Service.Emergency);
	private _toasterService = Container.get<ToasterService>(Service.Toaster);

	constructor(props: any) {
		super(props);

		this.state = {
			loading: true,
		};
	}

	@bind
	async onSubmit(values: IResolveEmergencyFormValues, { setSubmitting }: FormikHelpers<IResolveEmergencyFormValues>) {
		const request: C.IResolveEmergencyRequest = {
			notes: values.notes,
		};

		try {
			await this._emergencyService.emergencyQuickAccessResolve(this.props.match.params.id, request);

			this.setState({
				emergencyInfo: {
					...this.state.emergencyInfo!!,
					emergency: {
						...this.state.emergencyInfo!!.emergency,
						status: C.EmergencyStatus.Resolved,
					},
				},
			});
		} catch (err) {
			this._toasterService.handleWithToast(err, 'Failed to resolve alert.');
			setSubmitting(false);
		}
	}

	async componentDidMount() {
		try {
			const info = await this._emergencyService.emergencyQuickAccessGet(this.props.match.params.id);

			this.setState({
				loading: false,
				emergencyInfo: info,
			});
		} catch (err) {
			this.setState({ loading: false });
		}
	}

	@bind
	private async initializeMap(element: HTMLDivElement | null) {
		const info = this.state.emergencyInfo;

		if (!element || !info || !info.latestEventWithLocation)
			return;

		const mapboxManager = new MapboxManager(element, { metric: info.viewer.usesMetric });
		await mapboxManager.world(MapboxMapStyle.Streets);
		mapboxManager.jumpToLocation(info.latestEventWithLocation.assetLocation!.location.longitude, info.latestEventWithLocation.assetLocation!.location.latitude, 15);

		const asset = info.emergency.asset;

		const mapMarkerManager = new LiveMapMarkerManager(mapboxManager.map, [], () => {}, C.MapLabelBehavior.ShowAlways, false, () => {});
		mapMarkerManager.initialize([{
			asset: {
				assetId: asset.assetId,
				name: asset.name,
			},
			assetLocation: info.latestEventWithLocation.assetLocation ?? undefined,
			isOnline: true,
			properties: {},
			isAgedOff: false,
			hasEmergency: true,
			emergencyType: info.emergency.type,
			hiddenBySearchOrFilter: false,
		}]);
	}

	@bind
	private renderLocation(emergencyInfo: C.IEmergencyQuickAccessViewResponse): JSX.Element | null {
		if (!emergencyInfo.latestEventWithLocation || !emergencyInfo.latestEventWithLocation.assetLocation)
			return null;

		const { location, locationDetails } = emergencyInfo.latestEventWithLocation.assetLocation;

		let locationText = `${location.latitude}, ${location.longitude}`;

		const locationParts = locationDetails && getLocationParts(locationDetails);
		if (locationParts)
			locationText = `${locationParts.join(', ')} (${locationText})`;

		return <a
			href={`https://www.google.com/maps/dir/?api=1&destination=${location.latitude},${location.longitude}`}
			target="_blank"
			rel="noopener noreferrer"
		>
			{locationText} <OpenInNewIcon fontSize="small" style={{ marginBottom: '-5px' }} />
		</a>;
	}

	render() {
		if (this.state.loading) {
			return <MessagePage
				loading
			/>;
		}

		const emergencyInfo = this.state.emergencyInfo;
		if (!emergencyInfo) {
			return <MessagePage
				title="Emergency link has expired or you no longer have permission to view this emergency."
			/>;
		}

		const isResolved = emergencyInfo.emergency.status === C.EmergencyStatus.Resolved;

		let emergencyType = EmergencyType.EMERGENCY;
		if (emergencyInfo.emergency.type == 'PriorityAlert')
			emergencyType = EmergencyType.PRIORITY_ALERT;

		const parsedEmergency: IEmergency = {
			status: isResolved ? EmergencyStatus.RESOLVED : EmergencyStatus.NEW,
			type: emergencyType,
			generatedAt: emergencyInfo.emergency.generatedAt,
			recordedAt: emergencyInfo.emergency.recordedAt,
			safetyTimer: emergencyInfo.emergency.safetyTimer ? {startTimestamp: emergencyInfo.emergency.safetyTimer.startTimestamp, details: emergencyInfo.emergency.safetyTimer.details} : null,
			actions: emergencyInfo.emergency.actions ? emergencyInfo.emergency.actions.map(x => ({performedAt: x.performedAt, performedByUserName: x.user.name})) : null,
			asset: {name: emergencyInfo.emergency.asset.name, emergencyNotes: emergencyInfo.emergency.asset.emergencyNotes},
			ioConfiguration: emergencyInfo.emergency.deviceIoConfigurationEmergencyInformation ? {name: emergencyInfo.emergency.deviceIoConfigurationEmergencyInformation?.deviceIoConfigurationName, deviceName: emergencyInfo.emergency.deviceIoConfigurationEmergencyInformation.deviceName} : null
		};

		let isPriorityAlert = false;
		let styleCard = undefined;
		let header = undefined;
		switch (emergencyInfo.emergency.type) {
			case C.EmergencyType.PriorityAlert:
				isPriorityAlert = true;
				styleCard = priorityAlertMapCardStyle;
				header = 'Priority Alert';
				break;

			default:
				styleCard = emergencyMapCardStyle;
				header = 'Emergency';
				break;
		}

		return <div className="quick-access-emergency">
			<div className={classNames('header', { priority: isPriorityAlert, resolved: isResolved })}>
				<div className="fixed-width">
					<div className="header-content">
						<HeaderEmergencyIcon />
						<h1>{header} {isResolved && ' (Resolved)'}</h1>
					</div>
				</div>
			</div>

			<EmergencyViewer
				emergency={parsedEmergency}
				timeZone={emergencyInfo.viewer.timeZone}
				onSubmit={this.onSubmit}
			>
				{emergencyInfo.latestEventWithLocation && <Card className={styleCard}>
					<div className="map-label">
						Last known location: {this.renderLocation(emergencyInfo)}
					</div>

					<div className="map" ref={this.initializeMap} />
				</Card>}
			</EmergencyViewer>
		</div>;
	}
}
