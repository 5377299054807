import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Box from '@material-ui/core/Box';
import moment from 'moment-timezone';
import { MaterialTableProps } from '@material-table/core';

import { ResolveAssetServiceReminderDialog } from 'src/app/assetServiceReminders/resolveAssetServiceReminderDialog';
import { AssetServiceReminderStateBubble, MaterialTable, PopoverMenu, PopoverMenuItem, Button } from 'src/components';
import { shortDateFormat } from 'src/util/dateTimeFormats';
import { AssetServiceRemindersUsersAndUserGroupsDialog } from './assetServiceReminderUsersAndUserGroupsDialog';

import { useMutationDeleteAssetServiceReminders } from 'src/graphql/__generated__/mutations/mutationDeleteAssetServiceReminders';
import { AssetServiceReminderState } from 'src/../__generated__/globalTypes';

import {
	Client as C,
	AuthenticationService,
	DistanceService,
	DistanceUnit,
	ToasterService,
	useInjection,
	Service,
} from 'src/services';

interface Props {
	assetServiceReminders: AssetServiceReminderTableRow[];
	showAssetName: boolean;
	showAssetServiceReminderType: boolean;
	showToolbar: boolean;
	grouping: boolean;
	refetchData: () => Promise<void>;
}

export interface AssetServiceReminderTableRowReminderType {
	name: string;
	clientId: string;
	dateEnabled: boolean;
	odometerEnabled: boolean;
	engineTimeEnabled: boolean;
}

export interface IUser {
	id: string;
	name: string;
}

export interface IUserGroup {
	id: string;
	name: string;
}

export interface IAssetServiceReminderWithUsers {
	assetServiceReminderId: string;
	users: IUser[] | null;
	userGroups: IUserGroup[] | null;
}

export interface AssetServiceReminderTableRow {
	id: string;
	assetServiceReminderType: AssetServiceReminderTableRowReminderType;
	assetId: string;
	assetName: string;
	state: AssetServiceReminderState;
	nextDateTriggerDate: moment.Moment | null;
	nextEngineTimeTrigger: number | null;
	nextOdometerTrigger: number | null;
	dateDefaultFrequency: number | null;
	engineTimeDefaultFrequency: number | null;
	odometerDefaultFrequency: number | null;
	odometerReadingValue: number | null;
	engineTimeReadingValue: number | null;
	extraInformation: string | null;
	users: IUser[] | null;
	userGroups: IUserGroup[] | null;
}

export const AssetServiceRemindersTable: React.FunctionComponent<Props> = observer(props => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const distanceService = useInjection<DistanceService>(Service.DistanceService);
	const toasterService = useInjection<ToasterService>(Service.Toaster);

	const [reminderSelectedToResolve, setReminderSelectedToResolve] = useState<AssetServiceReminderTableRow | undefined>(undefined);

	const [selectedAssetServiceReminders, setSelectedAssetServiceReminders] = useState<IAssetServiceReminderWithUsers[] | undefined>(undefined);

	const [ deleteAssetServiceReminders ] = useMutationDeleteAssetServiceReminders();

	const identity = authenticationService.currentAuth.user.identity;
	const identityDistanceUnit = authenticationService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles;
	let clientId: string | null = null;
	if (identity.type === C.IdentityType.Client) {
		clientId = identity.clientId ?? null;
	} else {
		clientId = props.assetServiceReminders.length > 0 ? props.assetServiceReminders[0].assetServiceReminderType.clientId : null;
	}

	const showDate = props.assetServiceReminders.find(x => x.nextDateTriggerDate !== null) !== undefined;
	const showOdo = props.assetServiceReminders.find(x => x.nextOdometerTrigger !== null) !== undefined;
	const showEngineTime = props.assetServiceReminders.find(x => x.nextEngineTimeTrigger !== null) !== undefined;

	const deleteAssetServiceReminder = async (reminderId: string) => {
		try {
			await deleteAssetServiceReminders({
				variables: {
					input: {
						ids: [reminderId],
					}
				}
			});

			toasterService.showSuccess(`Asset service reminder deleted.`);
			await props.refetchData();
		} catch (err) {
			toasterService.handleWithToast(err, `Failed to delete asset service reminder.`);
		}
	};

	function onClickResolveAssetServiceReminder(reminderId: string) {
		const reminder = props.assetServiceReminders.find(x => x.id === reminderId);

		setReminderSelectedToResolve(reminder);
	}

	async function onDialogClose() {
		setReminderSelectedToResolve(undefined);
		setSelectedAssetServiceReminders(undefined);

		await props.refetchData();
	}

	async function onSuccessfulResolve() {
		await props.refetchData();
		setReminderSelectedToResolve(undefined);
	}

	const renderTableActions = (props: MaterialTableProps<AssetServiceReminderTableRow>) => {
		if (!props.data || props.data.length === 0)
			return null;

		return <Button
			variant="contained"
			color="primary"
			text="Update users to alert"
			title="Update users to alert"
			onClick={() => {
				const rowData = props.data as AssetServiceReminderTableRow[];

				const selectedAssetServiceReminders: IAssetServiceReminderWithUsers[] = rowData.map(x => ({
					assetServiceReminderId: x.id,
					users: x.users,
					userGroups: x.userGroups,
				}));

				setSelectedAssetServiceReminders(selectedAssetServiceReminders);
			}}
		/>;
	};

	return <Box>
		<MaterialTable
			tableName="asset-service-reminders-list"
			options={{
				filtering: false,
				showTitle: false,
				pageSize: 25,
				selection: true,
				toolbar: props.showToolbar,
				draggable: false,
				grouping: false,
			}}
			columns={[
				{
					title: 'Asset',
					field: 'assetName',
					grouping: false,
					hidden: !props.showAssetName
				},
				{
					title: 'Service Reminder Type',
					field: 'assetServiceReminderType.name',
					grouping: false,
					hidden: !props.showAssetServiceReminderType
				},
				{
					title: 'State',
					field: 'state',
					filtering: false,
					render: rowData => {
						return <AssetServiceReminderStateBubble
							state={rowData.state}
						/>;
					},
				},
				{
					title: 'Next Date',
					field: 'nextDateTriggerDate',
					grouping: false,
					filtering: false,
					render: rowData => {
						return <>{rowData.nextDateTriggerDate != null ? moment(rowData.nextDateTriggerDate).format(shortDateFormat) : undefined}</>;
					},
					hidden: !showDate,
				},
				{
					title: 'Next Odometer',
					field: 'nextOdometerTrigger',
					grouping: false,
					filtering: false,
					render: rowData => {
						const metricSuffix = authenticationService.currentAuth.user.usesMetric ? 'km' : 'mi';
						return <>{rowData.nextOdometerTrigger != null ? `${distanceService.getDistance(rowData.nextOdometerTrigger, identityDistanceUnit)} ${metricSuffix}` : undefined}</>;
					},
					hidden: !showOdo,
				},
				{
					title: 'Next Engine Time',
					field: 'nextEngineTimeTrigger',
					grouping: false,
					filtering: false,
					render: rowData => {
						return <>{rowData.nextEngineTimeTrigger != null ? `${Math.round(rowData.nextEngineTimeTrigger / 60)} hrs` : undefined}</>;
					},
					hidden: !showEngineTime,
				},
				{
					title: 'Extra Information',
					field: 'extraInformation',
					grouping: false,
					filtering: false,
				},
				{
					title: 'Options',
					field: 'options',
					grouping: false,
					filtering: false,
					sorting: false,
					headerStyle: ({
						textAlign: 'right',
					}),
					cellStyle: () => ({
						textAlign: 'right',
					}),
					render: rowData => <PopoverMenu
						renderOptions={() => {
							const popoverMenuItems = [<PopoverMenuItem
								key="edit"
								text="Edit"
								href={`/app/asset-service-reminders/${rowData.id}/edit`}
							/>];

							if (rowData.state != AssetServiceReminderState.NOT_EXPIRED) {
								popoverMenuItems.push(<PopoverMenuItem
									key="resolveReminder"
									text="Resolve Reminder"
									onClick={() => onClickResolveAssetServiceReminder(rowData.id)}
								/>);
							}

							popoverMenuItems.push(<PopoverMenuItem
								key="delete"
								text="Delete"
								onClick={() => deleteAssetServiceReminder(rowData.id)}
							/>);

							return popoverMenuItems;
						}}
					/>,
				},
			]}
			components={{
				Actions: renderTableActions,
			}}
			localization={{
				toolbar: {
					nRowsSelected: '{0} asset service reminder(s) selected',
				},
			}}
			data={props.assetServiceReminders}
		/>

		{reminderSelectedToResolve && <ResolveAssetServiceReminderDialog
			assetServiceReminderType={reminderSelectedToResolve.assetServiceReminderType}
			assetServiceReminder={reminderSelectedToResolve}
			onSuccess={onSuccessfulResolve}
			close={onDialogClose}
		/>}

		{selectedAssetServiceReminders && clientId && <AssetServiceRemindersUsersAndUserGroupsDialog
			assetServiceReminders={selectedAssetServiceReminders}
			clientId={clientId}
			close={onDialogClose}
		/>}
	</Box>;
});
