import React, { useRef, useState } from 'react';
import { match } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import { observer } from 'mobx-react';
import { TreeItem, TreeIndex, TreeNode as TreeNodeData, removeNodeAtPath, NodeData, find, addNodeUnderParent, walk as walkTree, FullTree, getNodeAtPath } from 'react-sortable-tree';

import SaveIcon from '@material-ui/icons/Save';
import UndoIcon from '@material-ui/icons/Undo';

import {
	AuthenticationService,
	FloorPlansService,
	HistoryService,
	TreeNode,
	Service,
	ToasterService,
	useInjection,
	Client as C,
} from 'src/services';

import { FloorPlanTree, InvalidNode } from './floorPlanTree';
import { Button, FixedWidthPage, ThingLoader, PopoverMenu, PopoverMenuItem } from 'src/components';

import './floorPlansList.scss';
import 'react-sortable-tree/style.css';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import uuid from 'src/util/uuid';

export interface FloorPlansListProps {
	match: match<{ collectionId: string }>;
}

export const FloorPlansList = observer((props: FloorPlansListProps) => {
	const _auth = useInjection<AuthenticationService>(Service.Authentication);
	const _floorPlans = useInjection<FloorPlansService>(Service.FloorPlans);
	const _history = useInjection<HistoryService>(Service.History);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const [originalFloorPlanTree, setOriginalFloorPlanTree] = useState<TreeItem[] | null>(null);
	const [floorPlanTree, setFloorPlanTree] = useState<TreeItem[] | null>(null);
	const [invalidNodes, setInvalidNodes] = useState<InvalidNode[]>([]);
	const [deletedNodePaths, setDeletedNodePaths] = useState<string[][]>([]);
	const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
	const [saving, setSaving] = useState<boolean>(false);

	const loadCollection = async (collectionId: string) => {
		const collection = await _floorPlans.getFloorPlanCollection(collectionId);
		let tree: TreeItem[] | null = null;

		if (collection)
			tree = _floorPlans.buildTreeFromCollection(collection);

		setOriginalFloorPlanTree(tree);
		setFloorPlanTree(cloneDeep(tree));

		return true;
	};

	const findNodeById = (id: string, tree: TreeItem[]): NodeData | null => {
		const matchingNodes = find({
			treeData: tree,
			getNodeKey: getNodeKey,
			searchMethod: search => (search.node as TreeNode).id === search.searchQuery,
			searchQuery: id,
		});

		if (matchingNodes.matches.length === 0)
			return null;

		return matchingNodes.matches[0];
	};

	const addGroup = (parentGroupId: string) => {
		setUnsavedChanges(true);

		const parent = findNodeById(parentGroupId, floorPlanTree!);
		if (!parent)
			throw Error(`Failed to find the group in the tree with id '${parentGroupId}' to add under`);

		const newGroup: TreeNode = {
			title: 'New group',
			expanded: true,
			id: uuid(),
			parentId: parentGroupId,
			isNew: true,
			nameChanged: false,
			type: C.FloorPlanCollectionItemType.Group,
		};

		const treeWithNewGroup = addNodeUnderParent({
			treeData: floorPlanTree!,
			parentKey: parent.path[parent.path.length - 1],
			expandParent: true,
			getNodeKey: getNodeKey,
			newNode: newGroup
		}).treeData;

		onFloorPlanTreeChanged(treeWithNewGroup);
		validateTree(treeWithNewGroup);
	};

	const discardChanges = () => {
		setFloorPlanTree(cloneDeep(originalFloorPlanTree));
		setInvalidNodes([]);
		setDeletedNodePaths([]);
		setUnsavedChanges(false);
	};

	const getNodeKey = (data: TreeIndex & TreeNodeData) => {
		return (data.node as TreeNode).id!;
	};

	const onMove = (data: NodeData & FullTree) => {
		// Sort node against its siblings
		const newParent = getNodeAtPath({
			treeData: data.treeData,
			path: data.path.slice(0, data.path.length - 1),
			getNodeKey: getNodeKey,
			ignoreCollapsed: false,
		});

		// For this parent, set each child's new display order.
		if (newParent && newParent.node.children) {
			const floorPlans = (newParent.node.children as TreeNode[]);
			for (let index = 0; index < floorPlans.length; index++) {
				const data = floorPlans[index].data;
				if (!data)
					continue;

				data.displayOrder = index;
			}

			// Since changing children doesn't change the top level object,
			// force react to re-render by changing the tree array reference.
			setFloorPlanTree([ ...data.treeData ]);
		}

		validateTree(data.treeData);
	};

	const validateNode = (node: TreeNode, errors: InvalidNode[]) => {
		if (node.children && node.children.length > 0) {
			const firstChild = (node.children[0] as TreeNode);
			const mixedChildren = !node.children.every(x => (x as TreeNode).type === firstChild.type);

			if (mixedChildren) {
				for (const c of node.children) {
					errors.push({
						id: `${c.id}`,
						reason: 'A group can only contain other groups or floor plans, but not both',
					});
				}
			}
		}
	};

	const validateTree = (tree: TreeItem[]) => {
		const errors: InvalidNode[] = [];

		walkTree({
			treeData: tree,
			getNodeKey: getNodeKey,
			callback: (node: TreeNodeData) => validateNode(node.node as TreeNode, errors),
			ignoreCollapsed: false,
		});

		setInvalidNodes(errors);
	};

	const saveChanges = async () => {
		setSaving(true);

		let hadError = false;
		try {
			let prunedTree: TreeItem[] = floorPlanTree!;
			for (let i = 0; i < deletedNodePaths.length; i++) {
				prunedTree = removeNodeAtPath({
					treeData: prunedTree,
					path: deletedNodePaths[i],
					getNodeKey: getNodeKey,
				});
			}

			await _floorPlans.updateFloorPlanCollection(props.match.params.collectionId, prunedTree[0]);
			await loadCollection(props.match.params.collectionId);
		} catch (err) {
			_toasterService.handleWithToast(err);
			hadError = true;
		}

		setSaving(false);
		setUnsavedChanges(hadError);
		setDeletedNodePaths([]);
	};

	const onAddFloorPlanClick = (parentGroupId: string) => {
		if (unsavedChanges)
			return;

		_history.history.push(`/app/floorplans/${parentGroupId}/add`);
	};

	const onDeleteGroupClick = (path: string[]) => {
		if (!confirm("Are you sure you want to delete this group? All groups and plans under it will also be marked for deletion! Changes won't be saved yet."))
			return;

		// Find longer paths that start with this path and remove them
		let tempDeletedGroups = deletedNodePaths;
		for (let i = tempDeletedGroups.length - 1; i >= 0; i--) {
			if (path.length >= tempDeletedGroups[i].length)
				continue;

			if (path.every((k, j) => k === tempDeletedGroups[i][j]))
				tempDeletedGroups = [...tempDeletedGroups.slice(0, i), ...tempDeletedGroups.slice(i + 1)];
		}

		setUnsavedChanges(true);
		setDeletedNodePaths([...tempDeletedGroups, path]);
	};

	const onEditFloorPlanClick = (floorPlanId: string) => {
		if (unsavedChanges)
			return;

		_history.history.push(`/app/floorplans/${floorPlanId}/edit`);
	};

	const onDeleteFloorPlanClick = (path: string[]) => {
		if (!confirm("Are you sure you want to delete this floor plan? Changes won't be saved yet."))
			return;

		setUnsavedChanges(true);
		setDeletedNodePaths([...deletedNodePaths, path]);
	};

	const onFloorPlanTreeChanged = (newTree: TreeItem[]) => {
		setUnsavedChanges(floorPlanTree !== newTree);
		setFloorPlanTree(newTree);
	};

	const renderGroupActions = (floorPlanGroupId: string, path: string[]) => {
		const options = [
			<PopoverMenuItem
				key="add-plan"
				text="Add floor plan"
				disabled={unsavedChanges}
				onClick={() => onAddFloorPlanClick(floorPlanGroupId)}
			/>,
		];

		if (path.length < 5) {
			options.push(<PopoverMenuItem
				key="add-group"
				text="Add group"
				onClick={() => addGroup(floorPlanGroupId)}
			/>);
		}

		if (path.length > 1) {
			options.push(<PopoverMenuItem
				key="delete-group"
				text="Delete"
				onClick={() => onDeleteGroupClick(path)}
			/>);
		}

		return options;
	};

	const renderGroupMenu = (floorPlanGroupId: string, path: string[], deleted: boolean) => {
		if (deleted)
			return null;

		return <PopoverMenu
			disabled={saving}
			renderOptions={() => renderGroupActions(floorPlanGroupId, path)}
		/>;
	};

	const renderFloorPlanActions = (floorPlanId: string, path: string[]) => {
		return [
			<PopoverMenuItem
				key="edit"
				text="Edit"
				disabled={unsavedChanges}
				onClick={() => onEditFloorPlanClick(floorPlanId)}
			/>,
			<PopoverMenuItem
				key="delete"
				text="Delete"
				onClick={() => onDeleteFloorPlanClick(path)}
			/>,
		];
	};

	const renderFloorPlanMenu = (floorPlanId: string, path: string[], deleted: boolean) => {
		if (deleted)
			return null;

		return <PopoverMenu
			renderOptions={() => renderFloorPlanActions(floorPlanId, path)}
		/>;
	};

	const nodeDeleted = (path: string[]) => {
		return _floorPlans.nodeAtPathDeleted(deletedNodePaths, path);
	};

	const discardButtonDisabled = !unsavedChanges || !floorPlanTree;
	const saveButtonDisabled = discardButtonDisabled || invalidNodes.length > 0;

	return <ThingLoader
		id={props.match.params.collectionId}
		load={loadCollection}
		render={() => <FixedWidthPage
			className="floorplans-list"
			headingText="Floor Plans"
			noContentBackground
			contentClassName="tree"
			headingActions={_auth.currentAuth.permissions.general.manageFloorPlans ? [
				<Button
					key="discard"
					text="Discard"
					startIcon={<UndoIcon />}
					disabled={discardButtonDisabled}
					loading={saving}
					onClick={discardChanges}
				/>,
				<Button
					key="save"
					text="Save"
					startIcon={<SaveIcon />}
					disabled={saveButtonDisabled}
					loading={saving}
					onClick={saveChanges}
					variant="contained"
					color="primary"
				/>,
			] : []}
		>
			{unsavedChanges && <Alert severity="info" style={{ marginBottom: '15px' }}>
				<AlertTitle>Unsaved Changes</AlertTitle>
				You have unsaved changes. You must save/discard these changes before you can add/edit a floor plan.
			</Alert>}

			{floorPlanTree && <FloorPlanTree
				floorPlanTree={floorPlanTree}
				invalidNodes={invalidNodes}
				onMove={onMove}
				nodeDeleted={nodeDeleted}
				updateFloorPlanTree={onFloorPlanTreeChanged}
				renderFloorPlanActions={renderFloorPlanMenu}
				renderGroupActions={renderGroupMenu}
				getNodeKey={getNodeKey}
				loading={saving}
			/>}
		</FixedWidthPage>}
	/>;
});
