import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import moment from 'moment-timezone';

import { AssetSelector, Button, FixedWidthPage, FormikDateTimeRangePicker, DateTimeRange, FormikTextField, MessagePage, AssetGroupSelector } from 'src/components';
import { maxReportDuration } from 'src/app/reports/reportConfiguration';
import { runFormValidation } from 'src/util';

import {
	Client as C,
	AuthenticationService,
	HistoryService,
	ReportService,
	Service,
	ToasterService,
	useInjection,
	useAssetSelectorService,
	useAssetGroupSelectorService,
} from 'src/services';

interface CreateGeofenceReportFormValues {
	name: string;
	assets: string[];
	assetGroups: string[];
	dateTimeRange: DateTimeRange;
}

export const CreateGeofenceReport = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _reportService = useInjection<ReportService>(Service.Report);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const _assetSelectorService = useAssetSelectorService();
	const _assetGroupSelectorService = useAssetGroupSelectorService(C.ListAssetGroupsType.Report);

	const validateForm = (values: CreateGeofenceReportFormValues, errors: FormikErrors<CreateGeofenceReportFormValues>) => {
		if (!values.name)
			errors.name = 'Report name is required.';

		if (values.assets.length == 0 && values.assetGroups.length == 0) {
			errors.assets = 'At least one asset or asset group is required.';
			errors.assetGroups = 'At least one asset or asset group is required.';
		}
	};

	const onSubmit = async (values: CreateGeofenceReportFormValues, { setSubmitting }: FormikHelpers<CreateGeofenceReportFormValues>) => {
		try {
			const request: C.INewGeofenceReportRequest = {
				name: values.name,
				assetIds: values.assets,
				assetGroupIds: values.assetGroups,
				startTimestamp: values.dateTimeRange.start,
				endTimestamp: values.dateTimeRange.end,
			};

			await _reportService.createGeofenceReport(request);
			_historyService.history.push('/app/reports');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to create report.');
			setSubmitting(false);
		}
	};

	const now = moment.tz(_authService.currentAuth.user.timeZone);

	return <FixedWidthPage
		className="form-page"
		headingText="Create Geofence Report"
		contentMessageReplace={_assetSelectorService.loading && <MessagePage loading />}
	>
		<Formik
			initialValues={{
				name: '',
				assets: [],
				assetGroups: [],
				dateTimeRange: { start: now.clone().subtract(1, 'day'), end: now },
			}}
			validate={values => runFormValidation(values, validateForm)}
			validateOnChange={false}
			onSubmit={onSubmit}
			render={(formikProps: FormikProps<CreateGeofenceReportFormValues>) => <Form className="formik-form material">
				<Field
					name="name"
					label="Report Name"
					component={FormikTextField}
					required
				/>

				<AssetSelector
					options={_assetSelectorService.assetOptions}
					formikProps={formikProps}
				/>

				<AssetGroupSelector
					options={_assetGroupSelectorService.assetGroupOptions}
					formikProps={formikProps}
				/>

				<FormikDateTimeRangePicker
					name="dateTimeRange"
					label="Report Period"
					form={formikProps}
					fullWidth
					maxDuration={maxReportDuration}
				/>

				<Button
					type="submit"
					variant="contained"
					color="primary"
					text="Create"
					loading={formikProps.isSubmitting}
				/>
			</Form>}
		/>
	</FixedWidthPage>;
});
