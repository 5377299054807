import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikProps, Form, Field, FormikHelpers, FormikErrors } from 'formik';
import moment from 'moment-timezone';

import { Button, FixedWidthPage, FormikTextField, ThingLoader, FormikSelect } from 'src/components';
import { runFormValidation } from 'src/util';
import { BillingCodesHelper } from 'src/app/reports/billingCodesHelper';
import { maxReportDuration } from 'src/app/reports/reportConfiguration';

import {
	Client as C,
	AuthenticationService,
	ToasterService,
	HistoryService,
	ReportService,
	Service,
	useInjection,
} from 'src/services';

interface CreateBillingReportFormValues {
	name: string;
	startDate: string;
	endDate: string;
	billingCodes: string[];
}

const validateForm = (values: CreateBillingReportFormValues, errors: FormikErrors<CreateBillingReportFormValues>) => {
	if (!values.name)
		errors.name = 'Report name is required.';

	if (!values.startDate)
		errors.startDate = 'A start date is required.';

	if (!values.endDate)
		errors.endDate = 'An end date is required.';
};

export const CreateBillingReport = observer(() => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _reportService = useInjection<ReportService>(Service.Report);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const load = async (): Promise<string[]> => {
		const billingTypesHelper = new BillingCodesHelper();
		return billingTypesHelper.getBillingCodes();
	};

	const onSubmit = async (values: CreateBillingReportFormValues, { setSubmitting, setFieldError }: FormikHelpers<CreateBillingReportFormValues>) => {
		const startDate = moment(values.startDate);
		const endDate = moment(values.endDate);

		if (endDate.isBefore(startDate)) {
			setFieldError('endDate', 'Report end date cannot be before report start date.');
			setSubmitting(false);
			return;
		}

		if (startDate < endDate.clone().subtract(maxReportDuration)) {
			setFieldError('endDate', `Duration may not exceed ${maxReportDuration.humanize()}.`);
			setSubmitting(false);
			return;
		}

		try {
			const request: C.INewBillingReportRequest = {
				name: values.name,
				startDate: startDate.format('YYYY-MM-DD'),
				endDate: endDate.format('YYYY-MM-DD'),
				billingCodes: !values.billingCodes || values.billingCodes.length === 0 ? null : values.billingCodes,
			};

			await _reportService.createBillingReport(request);
			_historyService.history.push('/app/reports');
		} catch (err) {
			_toasterService.handleWithToast(err, 'Failed to create report.');
			setSubmitting(false);
		}
	};

	const today = moment.tz(_authService.currentAuth.user.timeZone);

	return <ThingLoader
		load={load}
		render={(billingCodes: string[]) => <FixedWidthPage
			className="form-page"
			headingText="Create Billing Report"
		>
			<Formik
				initialValues={{
					name: '',
					startDate: today.subtract(1, 'day').format('YYYY-MM-DD'),
					endDate: today.format('YYYY-MM-DD'),
					billingCodes: [],
				}}
				validate={values => runFormValidation(values, validateForm)}
				validateOnChange={false}
				onSubmit={onSubmit}
				render={(formikProps: FormikProps<CreateBillingReportFormValues>) => <Form className="formik-form material">
					<Field
						name="name"
						label="Report Name"
						component={FormikTextField}
						required
					/>

					<Field
						name="startDate"
						label="Report Start Date"
						type="date"
						component={FormikTextField}
						required
					/>

					<Field
						name="endDate"
						label="Report End Date"
						type="date"
						component={FormikTextField}
						required
					/>

					<FormikSelect
						name="billingCodes"
						label="Billing Codes"
						placeholder="Select billing codes..."
						multi={true}
						options={billingCodes}
						form={formikProps}
						getOptionLabel={option => option}
						getOptionValue={option => option}
						helperText={'If no billing code is selected, then all billing codes will be shown in the report.'}
					/>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						text="Create"
						loading={formikProps.isSubmitting}
					/>
				</Form>}
			/>
		</FixedWidthPage>}
	/>;
});
