import { gql } from '@apollo/client';

export const QUERY = gql`
	query QueryAssetsList($includeDealer: Boolean!, $includeClient: Boolean!, $includeNetwork: Boolean!) {
		assets {
			id
			name
			assetType {
				name
			}
			dealer @include(if: $includeDealer) {
				name
			}
			client @include(if: $includeClient) {
				name
			}
			devices {
				id
				name
				deviceType
				serialNumber
				activationState
				... on ATrackDevice {
					imei
					atrackModelType: modelType
				}
				... on CamperVanDevice {
					imei
				}
				... on DigitalMatterDevice {
					imei
					phoneNumber
					serialNumber
					digitalMatterModelType: modelType
				}
				... on EroadDevice {
					vehiclePlate
				}
				... on HyteraRadioDevice {
					radioId
					network @include(if: $includeNetwork) {
						id
					}
				}
				... on MobilePhoneDevice {
					chatterPttUserId
					network @include(if: $includeNetwork) {
						id
					}
				}
				... on TaitRadioDevice {
					radioId
					network @include(if: $includeNetwork) {
						id
					}
				}
				... on TeltonikaDevice {
					imei
					teltonikaModelType: modelType
				}
			}
		}
	}
`;
