import React from 'react';
import { observer } from 'mobx-react';
import { Formik, FormikHelpers, FormikProps, Form, Field, FormikErrors } from 'formik';

import SaveIcon from '@material-ui/icons/Save';

import { Button, FormikTextField, FormikSelect } from 'src/components';
import { runFormValidation } from 'src/util';

import {
	AuthenticationService,
	Client as C,
	HistoryService,
	UserGroupService,
	Service,
	ToasterService,
	useInjection,
} from 'src/services';

interface ManageUserGroupFormValues {
	name: string;
	clientId: string | null;
}

const validateForm = (values: ManageUserGroupFormValues, errors: FormikErrors<ManageUserGroupFormValues>) => {
	if (!values.name)
		errors.name = 'Group name is required.';

	if (!values.clientId)
		errors.clientId = 'Client is required.';
};

interface Props {
	userGroup?: C.IUserGroupDto | null;
	clients?: C.IClientDto[];
	onSubmitSuccess?: Function;
}

export const ManageUserGroupComponent = observer((props: Props) => {
	const _authService = useInjection<AuthenticationService>(Service.Authentication);
	const _historyService = useInjection<HistoryService>(Service.History);
	const _userGroupService = useInjection<UserGroupService>(Service.UserGroup);
	const _toasterService = useInjection<ToasterService>(Service.Toaster);

	const defaultClientId = _authService.currentAuth.user.identity.clientId || null;

	const initialFormValues: ManageUserGroupFormValues = {
		name: props.userGroup?.name ?? '',
		clientId: props.userGroup?.client?.clientId ?? defaultClientId,
	};

	const addUserGroup = async (values: ManageUserGroupFormValues): Promise<boolean> => {
		const request: C.IAddUserGroupRequest = {
			name: values.name,
			clientId: values.clientId!!,
		};

		try {
			const userGroup = await _userGroupService.addUserGroup(request);
			_historyService.history.push(`/app/user-groups/${userGroup.userGroupId}/edit`);
			_toasterService.showSuccess(`Created group '${userGroup.name}'.`);
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err);
			return false;
		}
	};

	const updateUserGroup = async (values: ManageUserGroupFormValues): Promise<boolean> => {
		const request: C.IUpdateUserGroupRequest = {
			name: values.name,
		};

		try {
			await _userGroupService.updateUserGroup(props.userGroup!.userGroupId, request);
			_toasterService.showSuccess('Changes saved successfully.');
			return true;
		} catch (err) {
			_toasterService.handleWithToast(err);
			return false;
		}
	};

	const onSubmit = async (values: ManageUserGroupFormValues, { setSubmitting }: FormikHelpers<ManageUserGroupFormValues>) => {
		let success;
		if (props.userGroup)
			success = await updateUserGroup(values);
		else
			success = await addUserGroup(values);

		if (!success || (success && props.userGroup))
			setSubmitting(false);

		if (success && props.onSubmitSuccess)
			props.onSubmitSuccess();
	};

	const addingNewUserGroup = !props.userGroup;
	const canEdit = !props.userGroup || props.userGroup.type === C.UserGroupType.UserCreated;

	return <Formik
		initialValues={initialFormValues}
		validate={values => runFormValidation(values, validateForm)}
		validateOnChange={false}
		onSubmit={onSubmit}
		render={(formikProps: FormikProps<ManageUserGroupFormValues>) => <Form className="formik-form formik-form--label-margins">
			<div className="content-box form-card">
				<Field
					name="name"
					label="Group Name"
					component={FormikTextField}
					variant="outlined"
					disabled={!canEdit}
				/>

				{_authService.currentAuth.user.identity.type !== C.IdentityType.Client && addingNewUserGroup && <FormikSelect
					name="clientId"
					label="Client"
					placeholder="Select a client..."
					options={props.clients!!}
					form={formikProps}
					disabled={!canEdit}
					getOptionLabel={option => option.name}
					getOptionValue={option => option.clientId}
				/>}
			</div>

			<Button
				className="form-submit-button"
				type="submit"
				variant="contained"
				color="primary"
				startIcon={addingNewUserGroup ? null : <SaveIcon />}
				text={addingNewUserGroup ? 'Add Group' : 'Save Changes'}
				loading={formikProps.isSubmitting}
				disabled={formikProps.isSubmitting || !canEdit}
			/>
		</Form>}
	/>;
});
