import React from 'react';
import moment from 'moment-timezone';
import { observer } from 'mobx-react';
import { match } from 'react-router-dom';
import { getPrettyName } from 'src/util';
import { shortDateFormat, shortDateShortTimeFormat } from 'src/util/dateTimeFormats';

import { AssetServiceReminderStateBubble, ErrorMessagePage, FixedWidthPage, LoadingMessagePage, MaterialTable } from 'src/components';

import { useQueryAssetByIdForAssetServiceRemindersHistory } from 'src/graphql/__generated__/queries/queryAssetByIdForAssetServiceRemindersHistory';

import {
	AuthenticationService,
	DistanceService,
	DistanceUnit,
	useInjection,
	Service,
} from 'src/services';

export interface Props {
	match: match<{ id: string }>;
}

export const AssetServiceRemindersHistoryList: React.FunctionComponent<Props> = observer(props => {
	const authenticationService = useInjection<AuthenticationService>(Service.Authentication);
	const distanceService = useInjection<DistanceService>(Service.DistanceService);

	const identityDistanceUnit = authenticationService.currentAuth.user.usesMetric ? DistanceUnit.Kilometres : DistanceUnit.Miles;
	const metricSuffix = authenticationService.currentAuth.user.usesMetric ? 'km' : 'mi';

	const assetServiceReminderHistoryQuery = useQueryAssetByIdForAssetServiceRemindersHistory({
		variables: {
			id: props.match.params.id,
		}
	});

	if (assetServiceReminderHistoryQuery.loading)
		return <LoadingMessagePage />;

	if (!assetServiceReminderHistoryQuery.data?.assetById?.serviceReminderHistory)
		return <ErrorMessagePage />;

	return <FixedWidthPage
		headingText="Asset Service Reminder History"
		subheadingText={assetServiceReminderHistoryQuery.data.assetById.name}
		noContentBackground
	>
		<MaterialTable
			tableName="asset-service-reminders-history-list"
			options={{
				showTitle: false,
				pageSize: 25,
				selection: false,
				toolbar: false,
				grouping: false,
				draggable: false,
			}}
			columns={[
				{
					title: 'Time',
					render: rowData => {
						return moment(rowData.performedAt).tz(authenticationService.currentAuth.user.timeZone).format(shortDateShortTimeFormat);
					}
				},
				{
					title: 'Reminder Type',
					field: 'assetServiceReminderType.name'
				},
				{
					title: 'User',
					field: 'identity.user.name',
					width: 'fit-content',
					hidden: !authenticationService.currentAuth.permissions.general.manageUsers,
				},
				{
					title: 'Action',
					field: 'actionType',
					render: rowData => {
						return getPrettyName(rowData.actionType);
					}
				},
				{
					title: 'State',
					filtering: false,
					render: rowData => {
						return rowData.stateAfterAction ? <AssetServiceReminderStateBubble
							state={rowData.stateAfterAction}
						/> : undefined;
					},
				},
				{
					title: 'Date',
					filtering: false,
					render: rowData => {
						return rowData.nextDateTriggerTimestamp ? moment(rowData.nextDateTriggerTimestamp).tz(authenticationService.currentAuth.user.timeZone).format(shortDateFormat) : undefined;
					},
				},
				{
					title: 'Odometer',
					filtering: false,
					render: rowData => {
						return rowData.nextOdometerTrigger ? `${distanceService.getDistance(rowData.nextOdometerTrigger, identityDistanceUnit)} ${metricSuffix}` : undefined;
					},
				},
				{
					title: 'Engine Time',
					filtering: false,
					render: rowData => {
						return rowData.nextEngineTimeTrigger ? `${Math.round(rowData.nextEngineTimeTrigger / 60)} hrs` : undefined;
					},
				},
				{
					title: 'Notes',
					field: 'notes'
				},
			]}
			data={assetServiceReminderHistoryQuery.data.assetById.serviceReminderHistory.sort((a, b) => moment(b.performedAt).diff(moment(a.performedAt)))}
		/>
	</FixedWidthPage>;
});
