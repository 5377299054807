import mapboxgl, { ImageSourceRaw, MapMouseEvent, EventData } from 'mapbox-gl';
import { RulerControl } from 'mapbox-gl-controls';
import bind from 'bind-decorator';

import { linzApiKey, koordinatesApiKey } from 'src/config';
import { MapViewMode } from './mapViewMode';
import { pixelSize } from 'src/util/latLngConverter';

import {
	Client as C,
	Container,
	ToasterService,
	Service,
	AuthenticationService,
} from 'src/services';

import 'mapbox-gl-controls/lib/controls.css';
import './geofences.scss';

export interface IMapCenterAndZoom {
	center: mapboxgl.LngLat;
	zoom: number;
}

export enum MapboxMapStyle {
	Streets = 'mapbox://styles/mapbox/streets-v12',
	Satellite = 'mapbox://styles/mapbox/satellite-streets-v12',
	Topographic = 'mapbox://styles/mapbox/outdoors-v12',
}

interface IMapboxManagerOptions {
	metric: boolean;
	enableRotation?: boolean;
	hasTopRightMenu?: boolean;
	onClick?: (event: MapMouseEvent & EventData) => void;
	onViewChange?: (event: EventData) => void;
	onAddCustomMapMarker?: (lngLat: mapboxgl.LngLat) => void;
}

export const mapboxMaxStyleFromUserSetting = (mapStyle: C.MapStyle) => {
	if (mapStyle === C.MapStyle.Satellite)
		return MapboxMapStyle.Satellite;
	else if (mapStyle === C.MapStyle.Topographic)
		return MapboxMapStyle.Topographic;

	return MapboxMapStyle.Streets;
};

/**
 * Helps build a collection of trails to be displayed on the map.
 * When we are in floor plan view, we only want to show trails that cover
 * the specific floor plan we are showing.
 */
class TrailBuilder {
	private _trailParts: number[][][] = [];
	private _currentTrailPart: number[][] = [];

	public addLocation(location: number[]) {
		this._currentTrailPart.push(location);
	}

	public cutTrail() {
		if (this._currentTrailPart.length === 0)
			return;

		if (this._currentTrailPart.length > 1)
			this._trailParts.push(this._currentTrailPart);

		this._currentTrailPart = [];
	}

	public getTrails() {
		if (this._currentTrailPart.length > 1) {
			this._trailParts.push(this._currentTrailPart);
			this._currentTrailPart = [];
		}

		return this._trailParts;
	}
}

export class MapboxManager {
	private _authService = Container.get<AuthenticationService>(Service.Authentication);
	private _toasterService = Container.get<ToasterService>(Service.Toaster);

	map: mapboxgl.Map;
	private _options: IMapboxManagerOptions;
	private _rulerControl?: RulerControl;

	private _contextMenu: mapboxgl.Popup | undefined;

	private _mapViewMode: MapViewMode = MapViewMode.World;
	private _currentFloorPlan: C.IFloorPlanDto | undefined;

	private _trailEvents: C.IAssetEventDto[] | undefined;
	private _currentTrailIds: string[] | undefined;

	private _tilesetKeys: C.ITilesetKeysDto | undefined;

	constructor(element: HTMLDivElement, options: IMapboxManagerOptions) {
		options = {
			enableRotation: false,
			...options,
		};

		this._options = options;

		if (options.hasTopRightMenu)
			element.classList.add('top-right-menu');

		this.map = new mapboxgl.Map({
			container: element,
			logoPosition: 'bottom-right',
			pitchWithRotate: false,
			projection: {
				name: 'mercator',
			},
			touchPitch: false,
			dragRotate: options.enableRotation,
			bearingSnap: 0,
			transformRequest: (url, resourceType) => {
				const tilesetKey = this._tilesetKeys?.keys?.find(x => url.indexOf(x.tilesetId) > -1);

				if (tilesetKey) {
					return {
						url: url + `?key=${encodeURI(tilesetKey.key)}`,
					};
				} else {
					return {
						url: url,
					};
				}
			},
		});

		if (!options.enableRotation)
			this.map.touchZoomRotate.disableRotation();

		this.map.addControl(new mapboxgl.NavigationControl({ showCompass: options.enableRotation }));
		this.map.addControl(new mapboxgl.ScaleControl({ unit: options.metric ? 'metric' : 'imperial' }), 'bottom-right');

		if (options.onClick)
			this.map.on('click', options.onClick);

		if (options.onViewChange)
			this.map.on('moveend', options.onViewChange);

		this.map.on('contextmenu', this.openContextMenu);
	}

	updateTilesetKeys(tilesetKeys: C.ITilesetKeysDto) {
		this._tilesetKeys = tilesetKeys;
	}

	async world(style: MapboxMapStyle) {
		this.closeContextMenu();

		this.map.setStyle(style, {
			diff: false,
		});

		// We could cast this to mapboxgl.MapStyleDataEvent but the types are missing the 'style' property.
		const mapStyleDataEvent = await new Promise(x => this.map.once('styledata', x)) as any;

		if (style === MapboxMapStyle.Satellite && mapStyleDataEvent?.style?.order)
			this.addLinzSatelliteLayer(mapStyleDataEvent.style.order);
		else if (style === MapboxMapStyle.Topographic && mapStyleDataEvent?.style?.order)
			this.addLinzTopographicLayer(mapStyleDataEvent.style.order);

		this._mapViewMode = MapViewMode.World;
		this._currentFloorPlan = undefined;

		this.setTrail(this._trailEvents);

		this.map.addSource('drawing-dots', {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: [],
			},
		});

		this.map.addSource('drawing-dash', {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: [],
			},
		});

		this.map.addSource('drawing-fill', {
			type: 'geojson',
			data: {
				type: 'FeatureCollection',
				features: [],
			},
		});

		this.map.addLayer({
			id: 'drawing-dash',
			source: 'drawing-dash',
			type: 'line',
			paint: {
				'line-color': [
					'case',
					['any', ['has', 'overlapping'], ['has', 'outsideOfFloorPlan']],
					'#ff0000',
					'#0000ff',
				],
				'line-width': 2,
				'line-dasharray': [1, 1],
			},
		});

		this.map.addLayer({
			id: 'drawing-dots',
			source: 'drawing-dots',
			type: 'circle',
			paint: {
				'circle-color': '#0000FF',
				'circle-radius': 5,
			},
		});

		this.map.addLayer({
			id: 'drawing-fill',
			source: 'drawing-fill',
			type: 'fill',
			paint: {
				'fill-color': '#0000FF',
				'fill-opacity': 0.1
			},
		});

		this.map.getContainer().classList.remove('floor-plan');

		this.addRulerControl();
	}

	private addLinzSatelliteLayer(layerIds: string[]) {
		let beforeLayer: string | undefined = undefined;

		// Mapbox makes us pass in the layer ID that we want to add our layer before,
		// but we want to add a layer after the existing satellite layer.
		// Find the layer that is currently after the satellite layer, so that
		// we can add the LINZ layer before it.
		let seenSatellite = false;
		for (const layerId of layerIds) {
			if (seenSatellite) {
				beforeLayer = layerId;
				break;
			}

			if (layerId === 'satellite')
				seenSatellite = true;
		}

		if (!beforeLayer)
			return;

		this.map.addSource('linz-satellite', {
			type: 'raster',
			tiles: [ `https://basemaps.linz.govt.nz/v1/tiles/aerial/EPSG:3857/{z}/{x}/{y}.webp?api=${linzApiKey}` ],
			tileSize: 256,
			attribution: '<a href="https://www.linz.govt.nz/data/licensing-and-using-data/attributing-aerial-imagery-data">Sourced from LINZ. CC BY 4.0</a>',
			bounds: [ 166.392970, -47.335115, 178.578916, -34.358869 ],
			maxzoom: 22, // This determines at what zoom level the app will stop requesting new map data as the highest resolution images have already been fetched.
		});

		this.map.addLayer({
			id: 'linz-satellite',
			source: 'linz-satellite',
			type: 'raster',
			minzoom: 11,
		}, beforeLayer);
	}

	private addLinzTopographicLayer(layerIds: string[]) {
		this.map.addSource('linz-topographic', {
			type: 'raster',
			tiles: [ `https://tiles-cdn.koordinates.com/services;key=${koordinatesApiKey}/tiles/v4/layer=50767/EPSG:3857/{z}/{x}/{y}.png` ],
			tileSize: 256,
			attribution: '<a href="https://www.linz.govt.nz/products-services/data/licensing-and-using-data/attributing-linz-data">Sourced from LINZ. CC BY 4.0</a>',
			bounds: [ 166.392970, -47.335115, 178.578916, -34.358869 ],
			maxzoom: 16
		});

		this.map.addLayer({
			id: 'linz-topographic',
			source: 'linz-topographic',
			type: 'raster',
			minzoom: 6,
		});
	}

	addFloorPlanToWorld(floorPlan: C.IFloorPlanDto) {
		const tl = floorPlan.topLeft;
		const tr = floorPlan.topRight;
		const br = floorPlan.bottomRight;
		const bl = floorPlan.bottomLeft;

		if (!this.map.getSource('floor-plan')) {
			const source: ImageSourceRaw = {
				type: 'image',
				url: floorPlan.imageUrl,
				coordinates: [
					[tl.longitude, tl.latitude],
					[tr.longitude, tr.latitude],
					[br.longitude, br.latitude],
					[bl.longitude, bl.latitude],
				],
			};

			this.map.addSource('floor-plan', source);
		}

		if (!this.map.getLayer('floor-plan')) {
			this.map.addLayer({
				id: 'floor-plan',
				source: 'floor-plan',
				type: 'raster',
			}, this._currentTrailIds && this._currentTrailIds[0]);
		}

		this._currentFloorPlan = floorPlan;
	}

	removeFloorPlan() {
		this._currentFloorPlan = undefined;

		if (this.map.getLayer('floor-plan'))
			this.map.removeLayer('floor-plan');

		if (this.map.getSource('floor-plan'))
			this.map.removeSource('floor-plan');
	}

	async viewFloorPlan(floorPlan: C.IFloorPlanDto, options?: mapboxgl.FitBoundsOptions) {
		this.closeContextMenu();
		this.removeRulerControl();

		const source: ImageSourceRaw = {
			type: 'image',
			url: floorPlan.imageUrl,
			coordinates: [
				[0, 0],
				[floorPlan.imageWidth * pixelSize, 0],
				[floorPlan.imageWidth * pixelSize, floorPlan.imageHeight * -pixelSize],
				[0, floorPlan.imageHeight * -pixelSize],
			],
		};

		this.map.setStyle({
			version: 8,
			glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
			sources: {
				'floor-plan': source,
			},
			layers: [
				{
					id: 'background',
					type: 'background',
					paint: {
						'background-color': '#f2f2f2',
					},
				}, {
					id: 'floor-plan',
					source: 'floor-plan',
					type: 'raster',
				}
			],
		}, {
			diff: false,
		});

		await new Promise(r => this.map.once('styledata', r));

		this._mapViewMode = MapViewMode.FloorPlan;
		this._currentFloorPlan = floorPlan;

		this.map.fitBounds([
			[floorPlan.imageWidth * pixelSize, 0],
			[0, floorPlan.imageHeight * -pixelSize],
		], {
			animate: false,
			...options,
		});

		this.setTrail(this._trailEvents);

		this.map.getContainer().classList.add('floor-plan');
	}

	goToAssetLocation(assetLocation: C.IAssetLocationDto) {
		this.closeContextMenu();

		if (this._mapViewMode === MapViewMode.World) {
			// Should only zoom in towards an asset, or zoom too an asset if its not on the screen.
			const mapContainer = this.map.getContainer();
			const height = mapContainer.offsetHeight;
			const width = mapContainer.offsetWidth;

			const assetOnScreenLocation = this.map.project([assetLocation.location.longitude, assetLocation.location.latitude]);
			// If to check if the asset is on screen.
			if ((assetOnScreenLocation.x < width && assetOnScreenLocation.x >= 0) && (assetOnScreenLocation.y < height && assetOnScreenLocation.y >= 0)) {
				// Check if were too zoomed out, if we are go to asset.
				const currentZoomLevel = this.map.getZoom();
				if (currentZoomLevel >= 17)
					return;
			}

			this.easeToLocation(assetLocation.location.longitude, assetLocation.location.latitude, 17);
			return;
		}

		if (this._mapViewMode !== MapViewMode.FloorPlan || !this._currentFloorPlan ||
			!assetLocation.floorPlanLocation || this._currentFloorPlan.floorPlanId !== assetLocation.floorPlanLocation.floorPlanId)
			return;

		this.easeToLocation(assetLocation.floorPlanLocation.location.x * pixelSize, assetLocation.floorPlanLocation.location.y * -pixelSize, this.map.getZoom());
	}

	easeToLocation(longitude: number, latitude: number, zoom: number, duration?: number) {
		this.map.easeTo({
			center: [
				longitude,
				latitude,
			],
			zoom: zoom,
			duration: duration,
			animate: duration !== undefined,
		});
	}

	jumpToLocation(longitude: number, latitude: number, zoom?: number) {
		this.map.jumpTo({
			center: [
				longitude,
				latitude,
			],
			zoom: zoom,
		});
	}

	fitBounds(bounds: mapboxgl.LngLatBoundsLike, options?: mapboxgl.FitBoundsOptions) {
		this.map.fitBounds(bounds, {
			animate: false,
			...options,
		});
	}

	fitBoundsDefault() {
		const bounds = new mapboxgl.LngLatBounds([-180, -90, 180, 90]);
		this.fitBounds(bounds);
	}

	setTrail(events: C.IAssetEventDto[] | undefined) {
		if (this._currentTrailIds) {
			for (const id of this._currentTrailIds) {
				if (this.map.getLayer(id))
					this.map.removeLayer(id);
			}
		}

		this._trailEvents = events;
		this._currentTrailIds = undefined;
		if (!events)
			return;

		const trailsBuilder = new TrailBuilder();
		for (let i = events.length - 1; i >= 0; i--) {
			const assetLocation = events[i].assetLocation;
			if (!assetLocation)
				continue;

			if (this._mapViewMode === MapViewMode.FloorPlan && this._currentFloorPlan) {
				if (!assetLocation.floorPlanLocation || assetLocation.floorPlanLocation.floorPlanId !== this._currentFloorPlan.floorPlanId) {
					trailsBuilder.cutTrail();
					continue;
				}

				trailsBuilder.addLocation([
					assetLocation.floorPlanLocation.location.x * pixelSize,
					assetLocation.floorPlanLocation.location.y * -pixelSize,
				]);
			} else {
				trailsBuilder.addLocation([assetLocation.location.longitude, assetLocation.location.latitude]);
			}
		}

		const trails = trailsBuilder.getTrails();
		if (trails.length === 0)
			return;

		this._currentTrailIds = [];
		for (const trail of trails) {
			const trailId = `trail_${Math.random()}`;
			this._currentTrailIds.push(trailId);

			this.map.addLayer({
				'id': trailId,
				'type': 'line',
				'source': {
					'type': 'geojson',
					'data': {
						'type': 'Feature',
						'properties': {},
						'geometry': {
							'type': 'LineString',
							'coordinates': trail,
						},
					},
				},
				'layout': {
					'line-join': 'round',
					'line-cap': 'round',
				},
				'paint': {
					'line-color': '#3388ff',
					'line-width': 3,
					'line-opacity': 0.6,
				},
			});
		}
	}

	getMapCenterAndZoom(): IMapCenterAndZoom {
		return {
			center: this.map.getCenter(),
			zoom: this.map.getZoom(),
		};
	}

	@bind
	private openContextMenu(event: MapMouseEvent & EventData) {
		this.closeContextMenu();

		const popupContent = document.createElement('div');
		popupContent.classList.add('options');

		if (this._mapViewMode === MapViewMode.World) {
			const roundedLat = Math.round(event.lngLat.lat * 1000000) / 1000000;
			const roundedLng = Math.round(event.lngLat.lng * 1000000) / 1000000;
			const latLng = roundedLat + ', ' + roundedLng;

			const coordinatesButton = document.createElement('button');
			coordinatesButton.innerText = latLng;
			coordinatesButton.addEventListener('click', () => this.copyCoordinates(latLng), { capture: true });
			popupContent.appendChild(coordinatesButton);
		}

		if (this._options.onAddCustomMapMarker && this._authService.currentAuth.user.identity.type === C.IdentityType.Client) {
			const addMarkerButton = document.createElement('button');
			addMarkerButton.innerText = 'Add Map Marker';
			addMarkerButton.addEventListener('click', () => this.addCustomMarker(event.lngLat), { capture: true });

			if (this._mapViewMode === MapViewMode.FloorPlan || this.map.getCanvasContainer().classList.contains('custom-map-marker-positioning'))
				addMarkerButton.disabled = true;

			popupContent.appendChild(addMarkerButton);
		}

		if (popupContent.childElementCount === 0)
			return;

		this._contextMenu = new mapboxgl.Popup({
				anchor: 'top-left',
				className: 'map-context-menu',
				closeButton: false,
				closeOnClick: true,
				closeOnMove: true,
				maxWidth: 'none',
			})
			.setLngLat(event.lngLat)
			.setDOMContent(popupContent)
			.addTo(this.map);
	}

	@bind
	private closeContextMenu() {
		if (!this._contextMenu)
			return;

		this._contextMenu.remove();
		this._contextMenu = undefined;
	}

	@bind
	copyCoordinates(coordinates: string) {
		this.closeContextMenu();

		navigator.clipboard.writeText(coordinates);
		this._toasterService.showSuccess(`Copied to clipboard.`);
	}

	@bind
	addCustomMarker(lngLat: mapboxgl.LngLat) {
		this.closeContextMenu();

		if (this._options.onAddCustomMapMarker)
			this._options.onAddCustomMapMarker(lngLat);
	}

	@bind
	addRulerControl() {
		if (this._rulerControl)
			return;

		const usesMetric = this._options.metric;

		this._rulerControl = new RulerControl({
			units: usesMetric ? 'kilometers' : 'miles',
			labelFormat: n => n.toFixed(2) + (usesMetric ? ' km' : ' mi'),
		});

		this.map.addControl(this._rulerControl, 'top-right');
	}

	@bind
	removeRulerControl() {
		if (!this._rulerControl)
			return;

		this.map.removeControl(this._rulerControl);
		this._rulerControl = undefined;
	}
}
